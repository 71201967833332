/* eslint-disable */
import { reportConstants } from '../constants';
import { reportService } from '../services';
import { alertActions } from '.';

/*
 * report related actions
 */
export const reportActions = {
  getAuditLogs,
  resetAuditLogs,
  getImpactLogs,
  resetImpactLogs,
  getTimeFilters
};

function getAuditLogs(params = {}) {
  return dispatch => {
      if (params.timeFilterMode === undefined) {
        params['timeFilterMode'] = 98;
      } 
      dispatch(request());

      reportService.getAuditLogs(params)
          .then(
              report => dispatch(success(report)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
              }
          );
  };

  function request() { return { type: reportConstants.AUDIT_LOG_REQUEST } }
  function success(report) { return { type: reportConstants.AUDIT_LOG_SUCCESS, report } }
  function failure(error) { return { type: reportConstants.AUDIT_LOG_FAILURE, error } }
}

function resetAuditLogs() {
  return dispatch => {
    dispatch({
      type: reportConstants.AUDIT_LOG_RESET
    });
  };
}

function getImpactLogs(params = {}) {
  return dispatch => {
      if (params.timeFilterMode === undefined) {
        params['timeFilterMode'] = 98;
      } 
      dispatch(request());

      reportService.getImpactLogs(params)
          .then(
              report => {
                dispatch(success(report))
              },
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
              }
          );
  };

  function request() { return { type: reportConstants.IMPACT_LOG_REQUEST } }
  function success(report) { return { type: reportConstants.IMPACT_LOG_SUCCESS, report } }
  function failure(error) { return { type: reportConstants.IMPACT_LOG_FAILURE, error } }
}

function resetImpactLogs() {
  return dispatch => {
    dispatch({
      type: reportConstants.IMPACT_LOG_RESET
    });
  };
}

function getTimeFilters(params = {}) {
  return dispatch => {
      dispatch(request());

      reportService.getTimeFilters(params)
          .then(
              filters => dispatch(success(filters)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
              }
          );
  };

  function request() { return { type: reportConstants.TIME_FILTERS_REQUEST } }
  function success(filters) { return { type: reportConstants.TIME_FILTERS_SUCCESS, filters } }
  function failure(error) { return { type: reportConstants.TIME_FILTERS_FAILURE, error } }
}