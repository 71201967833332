/* eslint-disable */

import React from "react";
import { Field, reduxForm, SubmissionError, reset } from 'redux-form';
import { connect } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import DateTimePicker from 'react-datetime-picker';

import 'assets/css/react-draft-wysiwyg.css';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material ui icons
import Create from "@material-ui/icons/Create";
import Cancel from "@material-ui/icons/Cancel";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import Message from "@material-ui/icons/Message";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

import CustomTextField from "components/redux-form/CustomTextField/CustomTextField.jsx";

// custom components
import CustomisableSelect from "./controls/CustomisableSelect.jsx";

// validators
import { stringOfLengthBetween } from "helpers/validation";

// style for this view
import newTemplateGroupFormsStyle from "assets/jss/spot-admin/views/newTemplateGroupStyle.jsx";

import { messageService } from "services";
import { messageActions } from "actions";

const typeCategories = [
  { label: 'User Views Once Only', value: 'GLOBAL_ONCE'},
  { label: 'User Views Once Every Session', value: 'GLOBAL_SESSION'}
];

const timeRangeCategories = [
  { label: 'Always', value: 'ALWAYS'},
  { label: 'From Date', value: 'FROM_DATE'},
  { label: 'To Date', value: 'TO_DATE'},
  { label: 'From/To Date', value: 'FROM_TO_DATE'}
];

const validate = (values) => {
  const errors = {};

  if (!values.heading) {
    errors.heading = "Heading is a required field";
  } else {
    if (!stringOfLengthBetween(values.heading, 4, 100)) {
      errors.heading = "Heading must be between 4 and 100 characters";
    }
  }

  return errors;
}


class NewMessagePage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      alert: null,
      snackBarOpen: false,
      snackBarStatus: "success",
      snackBarMessage: "",
      type: 'GLOBAL_SESSION',
      isActive: false,
      showInPopup: false
    };

    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.continue = this.continue.bind(this);
    this.finished = this.finished.bind(this);
    this.removeAlert = this.removeAlert.bind(this);
    this.confirmContinue = this.confirmContinue.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleIsActiveChange = this.handleIsActiveChange.bind(this);
    this.handleShowInPopupChange = this.handleShowInPopupChange.bind(this);
  }

  componentWillUnmount() {
    if (this.notificationTimer) {
      clearTimeout(this.notificationTimer);
    }
  }

  showNotification(status, message) {
    if (!this.state.snackBarOpen) {
      this.setState({ 
        snackBarOpen: true,
        snackBarStatus: status,
        snackBarMessage: message 
      });
      this.notificationTimer = setTimeout(
        function() {
          this.setState({
            snackBarOpen: false
          });
          this.notificationTimer = null;
        }.bind(this),
        3000
      );
    }
  }

  confirmContinue() {
    const { classes } = this.props;
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success!"
          onConfirm={() => this.finished()}
          confirmBtnCssClass={
            classes.button + " " + classes.success
          }
          confirmBtnText="Okay"
        >
        Your message has been saved!
        </SweetAlert>
      )
    });
  }

  continue() {
    const { dispatch } = this.props;

    dispatch(reset('newMessage'));
    this.setState({
      alert: null
    });
  }

  finished() {
    const { history  } = this.props;

    this.setState({
      alert: null
    });
    history.goBack();
  }

  removeAlert() {
    this.setState({
      alert: null
    });
  }


  handleTypeChange(newValue) {
    const { type } = this.state;
      if (type !== newValue) {
        this.setState({
          type: newValue
        });
      }
  }

  handleIsActiveChange(newValue) {
    const { isActive } = this.state;
    if (isActive !== newValue) {
      this.setState({
        isActive: newValue
      });
    }
  }

  handleShowInPopupChange(newValue) {
    const { showInPopup } = this.state;
    if (showInPopup !== newValue) {
      this.setState({
        showInPopup: newValue
      });
    }
  }

  submit( values ) {

    const { dispatch } = this.props;
    const { type, isActive, showInPopup } = this.state;

    let user = JSON.parse(localStorage.getItem('user'));
    let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

    let message = {
      heading: values.heading,
      resourcetype: 'jurisdiction',
      resource: jurisdiction.id,
      fromDate: (values.dateTimeRange || [null, null])[0],
      toDate: (values.dateTimeRange || [null, null])[1],
      publishedDate: new Date(),
      content: values.content,
      type: values.type,
      closeText: "ok",
      isActive: values.isActive,
      showInPopup: values.showInPopup
    };

    return new Promise((resolve, reject) => {
      messageService.addNewMessage(message)
      .then(newMessage => {
        dispatch(messageActions.registerMessage(newMessage));
        resolve(newMessage);
        this.showNotification("success", "Message has been successfully created!");
        this.confirmContinue();
      })
      .catch(error => {
        console.error("ERROR creating message ", error);
        this.showNotification("danger", "There was a problem creating the message!");
        reject(new SubmissionError({ _error: "There was a problem creating the message."} ) );
      });
    });

  }

  cancel(pristine) {
    const { classes, history } = this.props;

    if (!pristine) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block" }}
            title="Warning!"
            onConfirm={() => this.finished()}
            onCancel={() => this.removeAlert()}
            confirmBtnCssClass={
              classes.button + " " + classes.success
            }
            cancelBtnCssClass={
              classes.button + " " + classes.danger
            }
            confirmBtnText="Yes"
            cancelBtnText="No"
            showCancel
          >
          Cancelling will discard any changes made.  Are you sure?
          </SweetAlert>
        )
      });  
    } else {
      history.goBack();
    }
  }

  render() {
    const { alert, snackBarOpen, snackBarMessage, snackBarStatus } = this.state;

    return (
    <div>
      {alert}
      <Snackbar
        place="tr"
        color={snackBarStatus}
        icon={Message}
        message={snackBarMessage}
        open={snackBarOpen}
        closeNotification={() => this.setState({ snackBarOpen: false })}
        close
      />
      <NewMessageForm onTypeChange={this.handleTypeChange} onIsActiveChange={this.handleIsActiveChange} onShowInPopupChange={this.handleShowInPopupChange} onSubmit={this.submit} onCancel={this.cancel}/>
    </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
} 

export default connect(mapStateToProps)(withStyles(newTemplateGroupFormsStyle)(NewMessagePage));


class NewMessageForm extends React.Component {
  state = {
    editorState: EditorState.createEmpty(),
    type: 'GLOBAL_SESSION',
    timeRangeType: 'ALWAYS',
    isActive: false,
    showInPopup: false,
    dateTimeRange: [new Date(), new Date()],
    initialValuesLoaded: false
  }

  constructor(props) {
    super(props);

    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.handleActiveToggle = this.handleActiveToggle.bind(this);
    this.handleShowInPopupToggle = this.handleShowInPopupToggle.bind(this);
    this.handleTimeRangeTypeChange = this.handleTimeRangeTypeChange.bind(this);
    this.handleDateTimeRangeChange = this.handleDateTimeRangeChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { initialValuesLoaded } = this.state;
    const { initialize } = this.props;

    if (!initialValuesLoaded) {
      initialize({ heading: "", type: 'GLOBAL_SESSION', content: "", timeRangeType: 'ALWAYS', isActive: false, showInPopup: false });
      this.setState({initialValuesLoaded: true});
    }
  }

  handleDateTimeRangeChange(dateTimeRange) {
    const { timeRangeType } = this.state
    const { dispatch, change } = this.props

    let newDateRange = [null, null]

    switch (timeRangeType) {
      case 'FROM_DATE':
        newDateRange[0] = dateTimeRange
        newDateRange[1] = null
        break;
      case 'TO_DATE':
        newDateRange[0] = null,
        newDateRange[1] = dateTimeRange
        break;
      case 'FROM_TO_DATE':
        let finalFrom = dateTimeRange[0];
        let finalTo = dateTimeRange[1];
        if (finalFrom.getTime() >= finalTo.getTime()) {
          finalTo.setTime(finalFrom.getTime())
          finalTo.setHours(finalTo.getHours() + 1)
        }
        newDateRange = [finalFrom, finalTo]
        break;
      default:
        newDateRange = [null, null]
        break;
    }

    this.setState({
      dateTimeRange: newDateRange
    })

    dispatch(change("dateTimeRange", newDateRange))
  }

  onEditorStateChange(editorState) {

    const { dispatch, change } = this.props;

    this.setState({
      editorState,
    });

    let htmlVersion = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    dispatch(change("content", htmlVersion))
  };

  handleTypeChange(newSelection) {
    const { type } = this.state;
    const { onTypeChange, dispatch, change } = this.props;

    if (type !== newSelection) {
      this.setState({
        type: newSelection
      });
    }

    dispatch(change("type", newSelection));
    onTypeChange(newSelection);
  }

  handleTimeRangeTypeChange(newSelection) {
    const { timeRangeType, dateTimeRange } = this.state;
    const { dispatch, change } = this.props;

    let defaultFromDate = new Date()
    defaultFromDate.setHours(0, 0, 0, 0)

    let defaultToDate = new Date(defaultFromDate)
    defaultToDate.setDate(defaultFromDate.getDate() + 1)

    let newDateRange = [null, null]

    switch (newSelection) {
      case 'FROM_DATE':
        newDateRange[0] = defaultFromDate
        newDateRange[1] = null
        break;
      case 'TO_DATE':
        newDateRange[0] = null;
        newDateRange[1] = defaultToDate
        break;
      case 'FROM_TO_DATE':
        newDateRange[0] = defaultFromDate
        newDateRange[1] = defaultToDate
        break;
      default:
        break;
    }
  
    this.setState({
      timeRangeType: newSelection,
      dateTimeRange: newDateRange
    });

    dispatch(change("timeRangeType", newSelection))
    dispatch(change("dateTimeRange", newDateRange))
  }

  handleActiveToggle() {
    const { isActive } = this.state;
    const { onIsActiveChange, dispatch, change } = this.props;

      this.setState({
        isActive: !isActive
      });

      dispatch(change("isActive", !isActive))
      onIsActiveChange(!isActive);
  }

  handleShowInPopupToggle() {
    const { showInPopup } = this.state;
    const { onShowInPopupChange, dispatch, change } = this.props;

      this.setState({
        showInPopup: !showInPopup
      });

      dispatch(change("showInPopup", !showInPopup))
      onShowInPopupChange(!showInPopup);
  }

  render() {
    const { classes, handleSubmit, pristine, submitting,
      submitFailed, submitSucceeded, reset, onCancel, onTypeChange } = this.props;

    const { editorState, type, timeRangeType, dateTimeRange } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={9}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="rose">
                <Message />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Add a new Message</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit} autoComplete="off">
              <GridContainer>
                <GridItem xs={12}>
                  <Field 
                    name="heading" 
                    component={CustomTextField} 
                    label="HEADING *"
                    props={{ fullWidth: true }} 
                  />
                </GridItem>
                <GridItem xs={12}>
                <Field
                  name="type"
                  component={CustomisableSelect}
                  props={{
                    controlId: "type", 
                    label: "Type *",
                    items: typeCategories,
                    value: `${type}`,
                    handleChange: this.handleTypeChange,
                    noPlaceholder: true
                  }}
                  value={`${type}`}
                />
                </GridItem>
                <GridItem xs={12}>
                <span style={{paddingBottom: '20px'}}>CONTENT *</span>
                <Field
                  name="content"
                  component={Editor}
                  props={{
                    editorStyle: { height: "275px",
                                 border: "1px solid #F1F1F1",
                                 padding: "5px",
                                 borderRadius: "2px"},
                    editorState: editorState,
                    onEditorStateChange: this.onEditorStateChange
                  }}
                  value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}   
                />
                </GridItem>
                <GridItem xs={12}>
                <Field
                  name="timeRangeType"
                  component={CustomisableSelect}
                  props={{
                    controlId: "type", 
                    label: "Time Range Type *",
                    items: timeRangeCategories,
                    value: `${timeRangeType}`,
                    handleChange: this.handleTimeRangeTypeChange,
                    noPlaceholder: true
                  }}
                  value={`${timeRangeType}`}
                />
                </GridItem>
                {(timeRangeType == 'FROM_DATE' || timeRangeType == 'TO_DATE') && <GridItem xs={12} style={{marginTop: '10px'}}>
                  <Field
                    name="dateTime"
                    component={DateTimePicker}
                    props={{
                      onChange: this.handleDateTimeRangeChange,
                      value: timeRangeType == 'FROM_DATE' ? this.state.dateTimeRange[0] : this.state.dateTimeRange[1],
                      clearIcon: null
                    }}
                    value={timeRangeType == 'FROM_DATE' ? this.state.dateTimeRange[0] : this.state.dateTimeRange[1]}
                  />
                </GridItem>}
                {timeRangeType === 'FROM_TO_DATE' && <GridItem xs={12} style={{marginTop: '10px'}}>
                  <Field
                    name="dateTimeRange"

                    component={DateTimeRangePicker}
                    props={{
                      onChange: this.handleDateTimeRangeChange,
                      value: this.state.dateTimeRange,
                      clearIcon: null,
                      disableClock: true

                    }}
                    value={this.state.dateTimeRange}
                  />
                </GridItem>}
                <GridItem xs={12}>
                <div className={classes.checkboxAndRadio}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={this.handleActiveToggle}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked
                        }}
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label="Make active"
                  />
                </div>
                </GridItem>
                <GridItem xs={12}>
                <div className={classes.checkboxAndRadio}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={this.handleShowInPopupToggle}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked
                        }}
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label="Show in popup?"
                  />
                </div>
                </GridItem>
              <GridItem xs={12}>
                <div className={classes.formCategory}>
                  <small>*</small> Required fields
              </div>
              </GridItem>
              </GridContainer>
                <Button
                  color="success"
                  type="submit"
                  className={classes.registerButton}
                  disabled={submitting}
                >
                  <Create className={classes.icon}/>
                  Create
              </Button>
              <Button
                  color="danger"
                  className={classes.registerButton}
                  style={{ marginRight: '10px'}}
                  disabled={submitting}
                  onClick={() => onCancel(pristine)}
                ><Cancel className={classes.icon} />
                  Cancel
              </Button>
              {/* <Button
                  color="primary"
                  className={classes.registerButton}
                  style={{ marginRight: '25px'}}
                  disabled={pristine || submitting}
                  onClick={reset}
                ><Clear className={classes.icon}/>
                  Clear
              </Button> */}
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

NewMessageForm = reduxForm({
  form: 'newMessage',
  validate
})(withStyles(newTemplateGroupFormsStyle)(NewMessageForm));