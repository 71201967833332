/* eslint-disable */

import config from '../config';
import { authHeader } from '../helpers';

export const areaService = {
    outline
};

const fetch = window.fetch;

function outline(processingStates) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let processingStatesAsString = "all";

    if (typeof processingStates === "string") {
      processingStatesAsString = processingStates;
    } else {
      if ((processingStates) && (processingStates.constructor === Array)) {
        processingStatesAsString = processingStates.join(',');
      }
    }

    return fetch(`${config.apiUrl}/1.3/areas/outline/${processingStatesAsString}`, requestOptions).then(handleOutlineResponse);
}

function handleOutlineResponse(response) {
    return response.json().then(data => {
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.error) || response.statusText;
            return Promise.reject(error);
        }

        return data.areas;
    });
}