export const messageConstants = {
  MESSAGES_REQUEST: 'MESSAGES_REQUEST',
  MESSAGES_SUCCESS: 'MESSAGES_SUCCESS',
  MESSAGES_FAILURE: 'MESSAGES_FAILURE',

  MESSAGE_REQUEST: 'MESSAGE_REQUEST',
  MESSAGE_SUCCESS: 'MESSAGE_SUCCESS',
  MESSAGE_FAILURE: 'MESSAGE_FAILURE',

  ADD_NEW_MESSAGE_REQUEST: 'ADD_NEW_MESSAGE_REQUEST',
  ADD_NEW_MESSAGE_SUCCESS: 'ADD_NEW_MESSAGE_SUCCESS',
  ADD_NEW_MESSAGE_FAILURE: 'ADD_NEW_MESSAGE_FAILURE',

  UPDATE_MESSAGE_REQUEST: 'UPDATE_MESSAGE_REQUEST',
  UPDATE_MESSAGE_SUCCESS: 'UPDATE_MESSAGE_SUCCESS',
  UPDATE_MESSAGE_FAILURE: 'UPDATE_MESSAGE_FAILURE',

  DELETE_MESSAGE_REQUEST: 'DELETE_MESSAGE_REQUEST',
  DELETE_MESSAGE_SUCCESS: 'DELETE_MESSAGE_SUCCESS',
  DELETE_MESSAGE_FAILURE: 'DELETE_MESSAGE_FAILURE',

  REGISTER_MESSAGE: 'REGISTER_MESSAGE'
};