export const reportConstants = {
  AUDIT_LOG_REQUEST: 'AUDIT_LOG_REQUEST',
  AUDIT_LOG_SUCCESS: 'AUDIT_LOG_SUCCESS',
  AUDIT_LOG_FAILURE: 'AUDIT_LOG_FAILURE',
  AUDIT_LOG_RESET: 'AUDIT_LOG_RESET',


  IMPACT_LOG_REQUEST: 'IMPACT_LOG_REQUEST',
  IMPACT_LOG_SUCCESS: 'IMPACT_LOG_SUCCESS',
  IMPACT_LOG_FAILURE: 'IMPACT_LOG_FAILURE',
  IMPACT_LOG_RESET: 'IMPACT_LOG_RESET',

  TIME_FILTERS_REQUEST: 'TIME_FILTERS_REQUEST',
  TIME_FILTERS_SUCCESS: 'TIME_FILTERS_SUCCESS',
  TIME_FILTERS_FAILURE: 'TIME_FILTERS_FAILURE'
};
