/* eslint-disable */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as moment from "moment-timezone";

// react component for creating dynamic tables
import ReactTable from "react-table";
import TagsInput from "react-tagsinput";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Description from "@material-ui/icons/Description";
import Done from "@material-ui/icons/Done";
import Clear from "@material-ui/icons/Clear";
import Add from "@material-ui/icons/Add";
import List from "@material-ui/icons/List";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import RoleSelector from "views/Components/RoleSelector.jsx";
import TimeFilterSelector from "components/TimeFilterSelector/TimeFilterSelector.jsx";

// colors
import colorRed from '@material-ui/core/colors/red';
import colorGreen from '@material-ui/core/colors/green';

import { reportActions, alertActions } from "actions";
import { convertFromUTCToTimezone } from "../../utils/TimeStructures";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { findIndex, intersection } from "lodash";


const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  headlineAction: {
    marginRight: "10px"
  },
  headline: {
    contentAlign: "right"
  },
  ok: {
    color: colorGreen[800]
  }, 
  notOk: {
    color: colorRed[800]
  }

};

class AuditLogTables extends React.Component {
  
  constructor(props) {
    super(props);

    let user = JSON.parse(localStorage.getItem('user'));

    this.state = {
      snackBarOpen: false,
      snackBarStatus: "success",
      snackBarMessage: "",
      loggedInUser: user,
      timeFilterSettings: {
        filterMode: 2
      }
    };
    
    this.showNotification = this.showNotification.bind(this);
    this.handleGenerate = this.handleGenerate.bind(this);
    this.handleTimeFilterChange = this.handleTimeFilterChange.bind(this);
  
    this.renderTag = this.renderTag.bind(this);
    this.renderInput = this.renderInput.bind(this);
  }

  componentWillMount() {
    const { dispatch, timeFilters } = this.props;  
    let user = JSON.parse((localStorage.getItem('user') || {}));
    let jurisdiction = JSON.parse((localStorage.getItem('user.jurisdiction') || {}));

    dispatch(reportActions.getTimeFilters({
        includeNow: false,
        includeFuture: false
    }));

    dispatch(reportActions.resetAuditLogs());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dispatch, alert } = this.props;

    if (alert.type) {
      this.showNotification(alert.type, alert.message);
      dispatch(alertActions.clear())
    }
  }

  handleTimeFilterChange(params) {
    const { dispatch } = this.props;

    this.setState({
      timeFilterSettings: params,
    });

    dispatch(reportActions.resetAuditLogs());
  }

  handleGenerate() {
    const { dispatch } = this.props;
    const { timeFilterSettings } = this.state;

    let jurisdiction = JSON.parse((localStorage.getItem('user.jurisdiction') || {}));

    dispatch(reportActions.getAuditLogs({
      timeFilterMode: timeFilterSettings.filterMode,
      fromDate: timeFilterSettings.fromDate,
      toDate: timeFilterSettings.toDate,
      exposeAuthUser: true,
      exposeResources: true
    }));
  }

  showNotification(status, message) {
    if (!this.state.snackBarOpen) {
      this.setState({ 
        snackBarOpen: true,
        snackBarStatus: status,
        snackBarMessage: message 
      });
      this.notificationTimer = setTimeout(
        function() {
          this.setState({
            snackBarOpen: false
          });
          this.notificationTimer = null;
        }.bind(this),
        7000
      );
    }
  }

  renderTag(props, isSelf) {
    let {tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other} = props

    return (<div key={key}>
      <span {...other} style={{marginBottom: "1px", marginTop: "1px"}}>
        {getTagDisplayValue(tag)}
      </span>
    </div>);
  }

  renderInput(props) {
    return null;
  }

  convertToHumanReadableLabel(label) {
    let tempLabel = label.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1").replace(/  /g, ' ');
    return tempLabel.charAt(0).toUpperCase() + tempLabel.slice(1);
  }

  render() {
    const { classes, auditReport, auditReportLoading, timeFiltersLoading, timeFilters, alert } = this.props;
    const { snackBarStatus, snackBarOpen, snackBarMessage, loggedInUser, timeFilterSettings } = this.state;

    if (auditReportLoading) return (
      <h2>Loading...</h2>
    );

    let alertNotice = null;
    if (alert.type) {
      alertNotice = <SnackbarContent message={alert.message} color={alert.type} />
    }
    
    return (
      <GridContainer>
        {/* <GridItem xs={6}/>
        <GridItem xs={6} className={classes.headline}>
        <Tooltip title="Refresh report">
          <Button color="info" style={{ float: 'right' }} onClick={() => this.handleRefresh()}>
            <Refresh className={classes.icon} />Refresh
          </Button>
        </Tooltip>
        </GridItem> */}
        <GridItem xs={12}>
        <Snackbar
          place="tr"
          color={snackBarStatus}
          message={snackBarMessage}
          open={snackBarOpen}
          closeNotification={() => this.setState({ snackBarOpen: false })}
          close
        />
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Description />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Audit Log Reports</h4>
            </CardHeader>
            <CardBody>
              {timeFiltersLoading && <h4>Please wait...</h4>}
              {timeFilters && (<div><GridItem xs={6}>
                <TimeFilterSelector filters={timeFilters} controlId="timeFilterMode" value={timeFilterSettings.filterMode} onTimeFilterChange={this.handleTimeFilterChange} />
              </GridItem>
              <GridItem xs={6}>
                <Button color="success" onClick={() => this.handleGenerate()}>
                  <List className={classes.icon} />Generate
                </Button>
              </GridItem></div>)}
              <br/>
              {auditReport && auditReport.results && auditReport.results.length === 0 && <GridItem xs={12}><h4>No results found</h4></GridItem>} 
              {auditReport && auditReport.results && auditReport.results.length > 0 && <GridItem xs={12}><ReactTable
                data={auditReport.results}
                filterable
                defaultFilterMethod={(filter, row) => String(row[filter.id]).substring(0, filter.value.length).toLowerCase() === filter.value.toLowerCase()}
                columns={[
                  {
                    Header: "ID",
                    accessor: "id",
                    maxWidth: 70
                  },
                  {
                    Header: "Timestamp",
                    id: "timeStamp",
                    accessor: ((reportRow) => {
                      return new Date(reportRow.timestamp);
                    }),
                    Cell: ((row) => {
                      return (
                        <div>
                          {/* <Tooltip title={row.value.toLocaleString(navigator.language)}> */}
                            <span>{row.value.toLocaleString(navigator.language)}</span>
                          {/* </Tooltip> */}
                        </div>
                      );
                    }),
                    sortMethod: (a, b) => {
                      if (a > b) return 1;
                      return -1;
                    },
                    filterMethod: ((filter, row) => {
                      const date = row.timeStamp.toLocaleString(navigator.language).replace(/,/g, '')
                      return date.substring(0, filter.value.length).toLowerCase() === filter.value.toLowerCase();
                    }),
                    maxWidth: 200
                  },
                  {
                    Header: "Log Type",
                    id: "logType",
                    accessor: ((reportRow) => {
                      return this.convertToHumanReadableLabel(reportRow.logType);
                    }),
                    maxWidth: 200
                  },
                  {
                    Header: "Action",
                    id: "action",
                    accessor: ((reportRow) => {
                      return { action: reportRow.action, params: reportRow.additionalDetails }
                    }),
                    Cell: ((row) => {
                      return (
                        <div>
                          {/* <Tooltip title={
                            <React.Fragment>
                              {Object.keys(row.value.params).length === 0 ? (<pre>No parameters</pre>) : (<pre>{JSON.stringify(row.value.params || {}, null, 2)}</pre>)}
                            </React.Fragment>
                            }> */}
                            <span>{this.convertToHumanReadableLabel(row.value.action)}</span>
                          {/* </Tooltip> */}
                        </div>
                      )
                    }),
                    filterMethod: ((filter, row) => {
                      return this.convertToHumanReadableLabel(row.action.action).substring(0, filter.value.length).toLowerCase() === filter.value.toLowerCase();
                    }),
                    maxWidth: 200
                  },
                  {
                    Header: "Performed By",
                    id: "authUser",
                    accessor: "userId",
                    Cell: ((row) => {
                      if (!row.value) return null;

                      return (
                        <div>
                          {/* <Tooltip title={`UserId: ${row.value}`}> */}
                            <span>{(auditReport.authUsers && auditReport.authUsers[row.value] ? auditReport.authUsers[row.value].description : row.value)}</span>
                          {/* </Tooltip> */}
                        </div>
                      );
                    }),
                    maxWidth: 200,
                    sortMethod: ((a, b) => {
                      if (auditReport.authUsers) {
                        const aUser = (auditReport.authUsers[a] || {}).description;
                        const bUser = (auditReport.authUsers[b] || {}).description;
                        if (aUser > bUser) return 1;
                        return -1;
                      } else {
                        if (a > b) return 1;
                        return -1;
                      }
                    }),
                    filterMethod: ((filter, row) => {
                      const user = (auditReport.authUsers && auditReport.authUsers[row.authUser]) ? auditReport.authUsers[row.authUser].description : String(row.authUser);
                      return user.substring(0, filter.value.length).toLowerCase() === filter.value.toLowerCase();
                    })
                  },
                  {
                    Header: "Resource(s) Affected",
                    id: "resourcesAffected",
                    accessor: "resourcesAffected",
                    filterable: false,
                    Cell: ((row) => {
                      return (
                        <div>
                          {Object.keys(row.value).map((resourceKey, index) => {
                            return (
                              <div>
                              {/* <Tooltip key={index} title={`${resourceKey}: ${row.value[resourceKey]}`}> */}
                                <span >{resourceKey.slice(0, -2)}: {auditReport.resources && auditReport.resources[resourceKey] && auditReport.resources[resourceKey][row.value[resourceKey]] ? auditReport.resources[resourceKey][row.value[resourceKey]].description : row.value[resourceKey]}</span>
                              {/* </Tooltip> */}
                              </div>
                          )})}
                        </div>
                      )
                    }),
                    sortMethod: ((a, b) => {
                      let aJSON = JSON.stringify(a);
                      let bJSON = JSON.stringify(b);
                      if (aJSON > bJSON) return 1;
                      return -1;                      
                    }),
                    maxWidth: 300
                  },
                  { Header: "Status",
                    id: "tags",
                    sortable: false,
                    accessor: ((reportRow) => { 
                      let tags = reportRow.tags;
                      let status = "danger";
                      if ((!reportRow.tags.includes('error')) && (!reportRow.tags.includes('unsuccessful')) && (!reportRow.tags.includes('unauthorizedAttempt'))) {
                        tags = ['success'];
                        status = "success";
                      } else {
                        tags = tags.filter(e => e !== reportRow.logType)
                      }

                      return (
                      <TagsInput style={{ overflowX: 'wrap' }}
                        value={tags.map(e => this.convertToHumanReadableLabel(e)).sort()}
                        disabled={true}
                        tagProps={{ className: `react-tagsinput-tag ${status}`}}
                        inputProps={{ placeholder: ''}}
                        renderTag={(props) => this.renderTag(props, true)}
                        renderInput={(props) => this.renderInput(props)}
                      />
                      ) }),
                    maxWidth: 200,
                    filterMethod: ((filter, row) => {
                      let rowTags = row.tags.props.value;
                      let filteredRows = rowTags.filter((tag) => {
                        return tag.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1;
                      });
                      return filteredRows.length > 0 ? true : false;
                    })
                  },
                ]}
                minRows={50}
                defaultPageSize={50}
                showPaginationTop
                showPaginationBottom={true}
                className="-striped -highlight"
              /></GridItem>}
              
              {alertNotice}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  const { alert, reports } = state;

  return {
    auditReport: reports.auditReport,
    auditReportLoading: reports.auditReportloading,
    timeFilters: reports.timeFilters || null,
    timeFiltersLoading: reports.timeFiltersLoading,
    alert
  };
}

export default connect(mapStateToProps)(withStyles(styles)(AuditLogTables));

