/* eslint-disable */
import { messageConstants } from '../constants';
import { messageService } from '../services';
import { alertActions } from '.';

/*
 * message related actions
 */
export const messageActions = {
  getMessages,
  getMessage,
  addNewMessage,
  updateMessage,
  deleteMessage,
  registerMessage
}

function getMessages(jurisdictionId) {
  return dispatch => {
      dispatch(request());

      messageService.getMessages(jurisdictionId)
          .then(
              messages => dispatch(success(messages)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error("There was a problem retrieving message information."));
              }
          );
  };

  function request() { return { type: messageConstants.MESSAGES_REQUEST } }
  function success(messages) { return { type: messageConstants.MESSAGES_SUCCESS, jurisdictionId, messages } }
  function failure(error) { return { type: messageConstants.MESSAGES_FAILURE, error } }
}

function getMessage(messageId) {
  return dispatch => {
      dispatch(request());

      messageService.getMessage(messageId)
          .then(
              message => dispatch(success(message)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error("There was a problem retrieving message information."));;
              }
          );
  };

  function request() { return { type: messageConstants.MESSAGE_REQUEST } }
  function success(message) { return { type: messageConstants.MESSAGE_SUCCESS, message } }
  function failure(error) { return { type: messageConstants.MESSAGE_FAILURE, error } }
}

function addNewMessage(message) {
  return dispatch => {
    dispatch(request(message));

    messageService.addNewMessage(message)
      .then(
        message => dispatch(success(message)),
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error("There was a problem creating a new message."));
        }
      );
  };

  function request(message) { return { type: messageConstants.ADD_NEW_MESSAGE_REQUEST, message } }
  function success(message) { return { type: messageConstants.ADD_NEW_MESSAGE_SUCCESS, message } }
  function failure(error) { return { type: messageConstants.ADD_NEW_MESSAGE_FAILURE, error } }
}

function updateMessage(message, actionVerb) {
  return dispatch => {
    dispatch(request(message));

    messageService.updateMessage(message)
      .then(
        message => {
          dispatch(success(message));
          dispatch(alertActions.success(`Message has been successfully ${actionVerb ? actionVerb : 'updated'}.`));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error("There was a problem updating the message."));
        }
      );
  };

  function request(message) { return { type: messageConstants.UPDATE_MESSAGE_REQUEST, message } }
  function success(message) { return { type: messageConstants.UPDATE_MESSAGE_SUCCESS, message } }
  function failure(error) { return { type: messageConstants.UPDATE_MESSAGE_FAILURE, error } }
}

function deleteMessage(messageId) {
  return dispatch => {
    dispatch(request(messageId));

    messageService.deleteMessage(messageId)
      .then(
        status => {
          dispatch(success(status))
          dispatch(alertActions.success("Message has been successfully deleted."))
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error("There was a problem deleting the message."));
        }
      );
  };

  function request(messageId) { return { type: messageConstants.DELETE_MESSAGE_REQUEST, messageId } }
  function success(status) { return { type: messageConstants.DELETE_MESSAGE_SUCCESS, messageId, status } }
  function failure(error) { return { type: messageConstants.DELETE_MESSAGE_FAILURE, error } }
}

function registerMessage(message) {
  return dispatch => {
    dispatch({
      type: messageConstants.REGISTER_MESSAGE,
      message
    });
  };
}

