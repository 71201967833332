export function authHeader() {

  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem('user'));

  if (user && user.token) {
    return {
      'X-Api-Token': user.token
    };
  } else {
    return {};
  }
}