import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// custom components
import Modal from '@material-ui/core/Modal';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  progress: {
    margin: theme.spacing.unit * 2
  }
});


class ProgressModal extends React.Component {
 
  render() {
    const { classes, title, open } = this.props;

    return (
      <Modal open={open}>
      <GridContainer style={getModalStyle()} className={classes.paper}>
        {title && <GridItem xs={12} style={{ textAlign: 'center' }}>
          <h3>{title}</h3>
        </GridItem>}
        <GridItem xs={12} style={{textAlign: 'center'}}><CircularProgress className={classes.progress} /></GridItem>
      </GridContainer>
      </Modal>
    );
  }
}


ProgressModal.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
};

export default withStyles(styles)(ProgressModal);