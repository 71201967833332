/* eslint-disable */

import React from "react";
import { Field, reduxForm, SubmissionError, reset } from 'redux-form';
import { connect } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
const uuidv4 = require('uuid/v4');
import { values, cloneDeep, isObject } from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material ui icons
import Style from "@material-ui/icons/Style";
import Create from "@material-ui/icons/Create";
import Cancel from "@material-ui/icons/Cancel";
import Clear from "@material-ui/icons/Clear";
import Revert from "@material-ui/icons/GetApp";
import Done from "@material-ui/icons/Done";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomisableSelect from "./controls/CustomisableSelect.jsx";
import CustomTextField from "components/redux-form/CustomTextField/CustomTextField.jsx";
import ColorSelector from "components/ColorSelector.jsx";
import TariffType from "components/TariffType/TariffType.jsx";

// validators
import { stringOfLengthBetween } from "helpers/validation";

// style for this view
import newTemplateGroupFormsStyle from "assets/jss/spot-admin/views/newTemplateGroupStyle.jsx";

import { templateService } from "services";
import { templateActions } from "actions";
import { FormLabel } from "@material-ui/core";

const DEFAULT_SIGNCONFIG = 'UNDEFINED';
const DEFAULT_COLOR = "#03A9F4";
const DEFAULT_PRIORITY = 20;

const validate = (values) => {
  const errors = {};

  if (!values.description) {
    errors.description = "Description is a required field";
  } else {
    if (!stringOfLengthBetween(values.description, 1, 200)) {
      errors.description = "Description must has a maximum of 200 characters";
    }
  }

  if (!values.priority) {
    errors.priority = "Priorities is a required field";
  } else {
    if (!verifyNumber(values.priority)) {
      errors.priority = "Priorities must be a number";
    } else {
      const nPriority = Number(values.priority);
      if ((nPriority < 1) || (nPriority > 99)) {
        errors.priority = "Priorities must be a number between 1 - 99";
      }
    }
  }

  return errors;
}

const verifyNumber = (value) => {
  var numberRex = new RegExp("^[0-9]+$");
  if (numberRex.test(value)) {
    return true;
  }
  return false;
}

class EditTemplateVersionConfigurationPage extends React.Component {

  constructor(props) {
    const { match, templates } = props;

    super(props);

    const editTemplateId = match.params.id;
    const editConfigId = match.params.configId;

    const jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));
    
    this.state = {
      alert: null,
      editTemplateId,
      editConfigId,
      jurisdiction,
      snackBarOpen: false,
      snackBarStatus: "success",
      snackBarMessage: "",
      baseCurrency: jurisdiction["BASE_CURRENCY"] || "USD",
      initialValuesDefined: false
    };

    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.finished = this.finished.bind(this);
    this.removeAlert = this.removeAlert.bind(this);
    this.confirmContinue = this.confirmContinue.bind(this);
    this.showNotification = this.showNotification.bind(this);

    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleSignConfigChange = this.handleSignConfigChange.bind(this);
    this.handleSignConfigAttributeChange = this.handleSignConfigAttributeChange.bind(this);
  }

  componentDidMount() {
    const { dispatch, templates, signConfigs } = this.props;
    const { jurisdiction } = this.state;

    if (Object.keys(templates).length == 0) {
      dispatch(templateActions.getTemplates(jurisdiction.id));
    }

    if (Object.keys(signConfigs).length == 0) {
      dispatch(templateActions.getSignConfigs(jurisdiction.id));
    }
  }

  componentWillUnmount() {
    if (this.notificationTimer) {
      clearTimeout(this.notificationTimer);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { signConfigs, templates, match } = nextProps;
    const { initialValuesDefined } = prevState;

    if ((!initialValuesDefined) && (Object.keys(signConfigs).length != 0) && (Object.keys(templates).length != 0)) {
      const editTemplateId = match.params.id;
      const editConfigId = match.params.configId;
      const template = templates[editTemplateId];

      return {
        formInitialValues: {
          signConfig: template.signConfig[editConfigId].signConfig,
          color: template.signConfig[editConfigId].color,
          description: template.signConfig[editConfigId].description,
          priority: template.signConfig[editConfigId].priority,
          signConfigAttributes: template.attributes[editConfigId]
        },
        initialValuesDefined: true,
        signConfigSelected: template.signConfig[editConfigId].signConfig,
        signConfigAttributes: template.attributes[editConfigId],
        color: template.signConfig[editConfigId].color
      };
    } else {
      return {};
    }
    
    return {};
  }

  handleColorChange(newColor) {
    const { color } = this.state;
    
    if (color != newColor) {
      this.setState({ color: newColor });
    }
  }

  handleSignConfigChange(newSignConfigId) {
    const { signConfigSelected } = this.state;

    if ( signConfigSelected !== newSignConfigId) {
      this.setState({
        signConfigSelected: newSignConfigId,
      });
    }   
  }

  handleSignConfigAttributeChange(newValue) {
    const { signConfigAttributes } = this.state;

    if (JSON.stringify(signConfigAttributes) !== JSON.stringify(newValue)) {
      this.setState({
        signConfigAttributes: newValue
      });
    }
  }

  showNotification(status, message) {
    if (!this.state.snackBarOpen) {
      this.setState({ 
        snackBarOpen: true,
        snackBarStatus: status,
        snackBarMessage: message 
      });
      this.notificationTimer = setTimeout(
        function() {
          this.setState({
            snackBarOpen: false
          });
          this.notificationTimer = null;
        }.bind(this),
        3000
      );
    }
  }

  confirmContinue() {
    const { classes } = this.props;
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success!"
          onConfirm={() => this.finished()}
          confirmBtnCssClass={
            classes.button + " " + classes.success
          }
          confirmBtnText="Okay"
        >
       The configuration has been updated successfully!
        </SweetAlert>
      )
    });
  }

  finished() {
    const { history  } = this.props;

    this.setState({
      alert: null
    });
    history.goBack();
  }

  removeAlert() {
    this.setState({
      alert: null
    });
  }

  submit( values ) {

    const { dispatch, templates } = this.props;
    const { editTemplateId, editConfigId, jurisdiction, signConfigSelected, signConfigAttributes, color } = this.state;

    let user = JSON.parse(localStorage.getItem('user'));

    let template = templates[editTemplateId];


    let newConfiguration = {};
    let newAttributes = {};

    newConfiguration[editConfigId] = {
      signConfig: signConfigSelected,
      color,
      description: values.description,
      priority: values.priority
    };

    newAttributes[editConfigId] = signConfigAttributes || {};
    newAttributes[editConfigId]["INFERRED"] = {
      type: 'Boolean',
      value: true
    };

    template.signConfig = {...template.signConfig, ...newConfiguration };
    template.attributes = {...template.attributes, ...newAttributes };

    return new Promise((resolve, reject) => {
      templateService.updateTemplate(template)
      .then(updatedTemplate => {
        dispatch(templateActions.registerTemplate(updatedTemplate));
        resolve(updatedTemplate);
        this.showNotification("success", "Configuration has been successfully saved!");
        this.confirmContinue();
      })
      .catch(error => {
        console.error("ERROR creating configuration ", error);
        this.showNotification("danger", "There was a problem creating the template configuration!");
        reject(new SubmissionError({ _error: "There was a problem creating the template configuration."} ) );
      });
    });
  }

  cancel(pristine) {
    const { classes, history } = this.props;

    if (!pristine) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block" }}
            title="Warning!"
            onConfirm={() => this.finished()}
            onCancel={() => this.removeAlert()}
            confirmBtnCssClass={
              classes.button + " " + classes.success
            }
            cancelBtnCssClass={
              classes.button + " " + classes.danger
            }
            confirmBtnText="Yes"
            cancelBtnText="No"
            showCancel
          >
          Cancelling will discard any changes made.  Are you sure?
          </SweetAlert>
        )
      });  
    } else {
      history.goBack();
    }
  }

  render() {
    const { alert, snackBarOpen, snackBarMessage, snackBarStatus, formInitialValues, baseCurrency } = this.state;
    const { signConfigs, readOnlyMode } = this.props;

    return (
    <div>
      {alert}
      <Snackbar
        place="tr"
        color={snackBarStatus}
        icon={Style}
        message={snackBarMessage}
        open={snackBarOpen}
        closeNotification={() => this.setState({ snackBarOpen: false })}
        close
      />
      <EditTemplateVersionConfigurationForm
        readOnlyMode={readOnlyMode}
        baseCurrency={baseCurrency}
        formInitialValues={formInitialValues}
        signConfigs={signConfigs}
        onSignConfigChange={this.handleSignConfigChange}
        onSignConfigAttributeChange={this.handleSignConfigAttributeChange}
        onColorChange={this.handleColorChange}
        onSubmit={this.submit} 
        onCancel={this.cancel}/>
    </div>
    );
  }
}

function mapStateToProps(state) {
  const { templates } = state;

  if (!templates) {
    return {
      templates: {},
      signConfigs: {}
    };  
  }

  return {
    templates: templates.templates || {},
    signConfigs: templates.signConfigs || {},
    loading: templates.loading || false,
    saving: templates.saving || false,
  };
} 

export default connect(mapStateToProps)(withStyles(newTemplateGroupFormsStyle)(EditTemplateVersionConfigurationPage));


class EditTemplateVersionConfigurationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValuesLoaded: false
    };

    this.handlePriorityChange = this.handlePriorityChange.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleSignConfigChange = this.handleSignConfigChange.bind(this);
    this.handleSignConfigAttributeChange = this.handleSignConfigAttributeChange.bind(this);
    this.handleRevert = this.handleRevert.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { initialValuesLoaded, reset } = this.state;
    const { initialize, formInitialValues } = this.props;
 
    if ((!initialValuesLoaded) && (formInitialValues)) {
      initialize({ description: formInitialValues.description, signConfig: formInitialValues.signConfig, signConfigAttributes: formInitialValues.signConfigAttributes || {}, configurationColor: formInitialValues.color || DEFAULT_COLOR, priority: formInitialValues.priority || DEFAULT_PRIORITY });
      this.setState({ initialValuesLoaded: true, signConfigSelected: formInitialValues.signConfig, signConfigAttributes: formInitialValues.signConfigAttributes || {}, configurationColor: formInitialValues.color || DEFAULT_COLOR, priority: formInitialValues.priority || DEFAULT_PRIORITY });
    }

    if (reset) {
      this.setState({ reset: false });
    }
  }

  handleRevert() {
    const { formInitialValues, reset, onSignConfigChange, onSignConfigAttributeChange, onColorChange } = this.props;

    onSignConfigChange(formInitialValues.signConfig);
    onSignConfigAttributeChange(formInitialValues.signConfigAttributes);
    onColorChange(formInitialValues.color);

    this.setState({ 
      signConfigSelected: formInitialValues.signConfig,
      signConfigAttributes: formInitialValues.signConfigAttributes || {},
      configurationColor: formInitialValues.color || DEFAULT_COLOR,
      priority: formInitialValues.priority || DEFAULT_PRIORITY,
      reset: true
     });
  
    reset();
  }

  handlePriorityChange(e) {
    const { dispatch, change } = this.props;

    if (verifyNumber(e.target.value)) {
      dispatch(change("priority", Number(e.target.value)));
      this.setState({
        priority: e.target.value
      });
    } else {
      if (e.target.value.length == 0) {
        dispatch(change("priority", 0));        
      }
    }    
  }

  handleColorChange(color) {
    const { dispatch, change, onColorChange } = this.props;

    this.setState({
      configurationColor: color.hex
    });

    dispatch(change("configurationColor", color.hex));
    if (onColorChange) onColorChange(color.hex);
  }

  handleSignConfigChange(newSelection) {
    const { signConfigSelected, baseCurrency } = this.state;
    const { onSignConfigChange, onSignConfigAttributeChange, dispatch, change, signConfigs } = this.props;

    const newSignConfig = signConfigs[newSelection];

    let newSignConfigAttributes = {};

    function generateAttributeStructure(fieldId, attribute, newStructure) {
      if (attribute.fields) attribute.type = 'Structure';

      switch (attribute.type) {
        case 'Number':
          newStructure[fieldId] = {
            type: 'Number',
            value: attribute.default || attribute.minimum || 0
          };
          break;
        case 'TariffType':
          newStructure[fieldId] = {
            type: 'TariffType',
            subtype: 'PRO_RATED',
            chargeInterval: 1,
            currency: baseCurrency,
            displayCharge: 1,
            displayChargeUnitSize: 60
          }
          break;
        case 'Array':
          if (fieldId === 'RESTRICTED_PERIODS') {
            newStructure[fieldId] = {
              type: 'Structure',
              fields: {}
            };
            let periodStructure = cloneDeep(newSignConfig.structures[attribute.structure]);
            delete periodStructure['DAY_RANGE'];
            delete periodStructure['MONTH_RANGE'];
            delete periodStructure['TIME_RANGE'];

            generateAttributeStructure(fieldId, newSignConfig.structures[attribute.structure], newStructure[fieldId].fields);
          }
          break;
        case 'Structure':
          Object.keys(attribute.fields).forEach(subFieldId => {
            generateAttributeStructure(subFieldId, attribute.fields[subFieldId], newStructure);
          });
        
        default:
          break;
      }
    }

    Object.keys(newSignConfig.attributes).forEach(key => {
      generateAttributeStructure(key, newSignConfig.attributes[key], newSignConfigAttributes);
    });

    if (signConfigSelected != newSelection) {
      this.setState({
        signConfigSelected: newSelection,
        signConfigAttributes: newSignConfigAttributes,
        reset: true
      });

      dispatch(change("signConfig", newSelection));
      dispatch(change("signConfigAttributes", newSignConfigAttributes));
      if (onSignConfigChange) onSignConfigChange(newSelection);
      if (onSignConfigAttributeChange) onSignConfigAttributeChange(newSignConfigAttributes);
    }
  }

  handleSignConfigAttributeChange(attribute, newValue) {
    const { signConfigAttributes } = this.state;
    const { onSignConfigAttributeChange, dispatch, change } = this.props;

    let fieldHierarchy = attribute.split('-');
    fieldHierarchy.splice(0, 1);

    let newAttributes = cloneDeep(signConfigAttributes);

    let marker = newAttributes;
    for (let i = 0; i < fieldHierarchy.length - 1; i++) {
      if (!marker[fieldHierarchy[i]]) marker[fieldHierarchy[i]] = { fields: {} }; 
  
      if ((marker[fieldHierarchy[i]]) && (marker[fieldHierarchy[i]].fields)) {
        marker = marker[fieldHierarchy[i]].fields;
      } else {
        marker = marker[fieldHierarchy[i]];
      }
    }
    marker[fieldHierarchy[fieldHierarchy.length - 1]] = newValue;

    this.setState({
      signConfigAttributes: newAttributes
    });

    dispatch(change("signConfigAttributes", newAttributes));
    onSignConfigAttributeChange(newAttributes);

  }

  renderSignConfigAttribute(fieldId, field, selectedAttribute, parentString) {
    const { classes, baseCurrency, readOnlyMode } = this.props;
    const { reset } = this.state;

    let setAttribute = cloneDeep(selectedAttribute) || {};
    if (!field.type) return null;
    
    switch (field.type) {
      case 'Array':
        return null;
      case 'Structure':
        if (!setAttribute.fields) setAttribute.fields = {};
        return Object.keys(field.fields).map( (subFieldId, key) => {
          return this.renderSignConfigAttribute(subFieldId, field.fields[subFieldId], setAttribute.fields[subFieldId], `${parentString}${fieldId}-`);
        });
      case 'Number':
        return (
            <GridContainer key={`${parentString}${fieldId}`} style={{paddingLeft: '10px', paddingRight: '10px'}}>
              <GridItem xs={12} sm={3}>
                <FormLabel className={classes.labelHorizontal}>
                  {isObject(field.description) ? field.description['en-US'].toUpperCase() : field.description.toUpperCase()}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id={`input${parentString}${fieldId}`}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    onChange: event => this.handleSignConfigAttributeChange(`input${parentString}${fieldId}`, { type: 'Number', value: Number(event.target.value) }),
                    type: "number",
                    value: `${(selectedAttribute || {}).value ? (selectedAttribute || {}).value : 0}`,
                    disabled: readOnlyMode
                  }}
                />
              </GridItem>
            </GridContainer>);        
        case 'TariffType':
            return (
              <GridContainer key={`${parentString}${fieldId}`} style={{paddingLeft: '10px', paddingRight: '10px'}}>
                <GridItem xs={12} sm={3}>
                  <FormLabel className={classes.labelHorizontal}>
                    TARIFF
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9   }>
                  <TariffType controlId={`input${parentString}${fieldId}`} baseCurrency={baseCurrency} onTariffTypeChange={this.handleSignConfigAttributeChange} value={(selectedAttribute || {})} reset={reset} disabled={readOnlyMode}/>
                </GridItem>
              </GridContainer>);
        case 'String':
          return (
            <GridContainer key={`${parentString}${fieldId}`} style={{paddingLeft: '10px', paddingRight: '10px'}}>
              <GridItem xs={12} sm={3}>
                <FormLabel className={classes.labelHorizontal}>
                  {isObject(field.description) ? field.description['en-US'].toUpperCase() : field.description.toUpperCase()}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id={`input${parentString}${fieldId}`}
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    onChange: event => this.handleSignConfigAttributeChange(`input${parentString}${fieldId}`, { type: 'String', value: event.target.value }),
                    type: "text",
                    value: `${(selectedAttribute || {}).value ? (selectedAttribute || {}).value : ""}`,
                    disabled: readOnlyMode
                  }}
                />
              </GridItem>
          </GridContainer>);  
        case 'Alert':


      default:
        
        return null;
    }
  }

  renderSignConfigFields(signConfig, selectedAttributes) {
    if (!signConfig) return null;

    let displayables = cloneDeep({ attributes: signConfig.attributes, structures: signConfig.structures });

    if (Object.keys(displayables.attributes).length == 0) return null

    // handle special case of time restrictions
    if (displayables.structures['PARK_RESTRICTION']) {
      let parkRestriction = displayables.structures['PARK_RESTRICTION'];
      
      delete parkRestriction.fields['DAY_RANGE'];
      delete parkRestriction.fields['MONTH_RANGE'];
      delete parkRestriction.fields['TIME_RANGE'];
    }

    if (displayables.attributes['RESTRICTED_PERIODS']) {
      let restrictedPeriods = displayables.attributes['RESTRICTED_PERIODS'];
      if (restrictedPeriods.structure) {
        restrictedPeriods.fields = displayables.structures[restrictedPeriods.structure].fields;
        restrictedPeriods.type = "Structure";
        delete displayables.structures[restrictedPeriods.structure];
        delete restrictedPeriods.structure;
      }
    }

    // remove inferred as option as it will always be applied
    if (displayables.attributes['INFERRED']) delete displayables.attributes['INFERRED'];

    let majorFieldIds = Object.keys(displayables.attributes);
    if ((displayables.attributes['RESTRICTED_PERIODS']) && (Object.keys(displayables.attributes['RESTRICTED_PERIODS'].fields).length == 0)) {
      majorFieldIds = majorFieldIds.filter(e => e != "RESTRICTED_PERIODS");
    }

    // if after all filters nothing to display then just return null
    if (majorFieldIds.length === 0) return null;
    
    return (
      <GridContainer hidden={majorFieldIds.length == 0} style={{ paddingLeft: '20px', paddingRight: '20px', backgroundColor: '#FFFAC9' }}>
        <p style={{ paddingTop: '10px', paddingLeft: '10px' }}>{signConfig.category.replace(/[_]/g, ' ')} SPECIFIC ATTRIBUTES</p>
        {majorFieldIds.map( (fieldId, key) => {
          let field = displayables.attributes[fieldId];
          return this.renderSignConfigAttribute(fieldId, field, selectedAttributes[fieldId], "-");
        })}
        <p>&nbsp;</p>
      </GridContainer>
    ) 
  }

  render() {
    const { classes, handleSubmit, pristine, submitting, readOnlyMode,
      submitFailed, submitSucceeded, reset, onCancel, formInitialValues, signConfigs } = this.props;
    const { configurationColor, signConfigSelected, signConfigAttributes } = this.state;
    
    if (!formInitialValues) return null;

    const signConfigIds = Object.keys(signConfigs);

    if (signConfigIds.length == 0) return null;

    let selectableSignConfigs = signConfigIds.map(signConfigId => {
      let signConfig = signConfigs[signConfigId];
      return { label: signConfig.category.replace(/[_]/g, ' '), value: signConfig.id };
    });

    selectableSignConfigs = selectableSignConfigs.sort( (a, b) => {
      return (b.label > a.label ? -1 : 1);
    });

    let selectedSignConfig = signConfigs[signConfigSelected];
    if (selectableSignConfigs.length == 0) return null;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={9}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="rose">
                <Style />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{`${readOnlyMode ? 'View' : 'Edit'} existing Template Configuration`}</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit} autoComplete="off">
              <GridContainer>
                <GridItem xs={12}>
                  <Field 
                    name="description" 
                    component={CustomTextField}
                    label="DESCRIPTION *"
                    props={{ fullWidth: true }}
                    disabled={readOnlyMode}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="priority"
                    component={CustomTextField}
                    label="PRIORITY *"
                    props={{ 
                      fullWidth: true,
                      inputProps: {
                        onChange: this.handlePriorityChange,    
                      }
                     }}
                     disabled={readOnlyMode}
                  />
                </GridItem>  
                <GridItem xs={12}>
                  <h6 style={{fontWeight: '400'}}>CONFIGURATION MAP COLOR*:</h6>
                  <ColorSelector color={configurationColor} onColorChange={this.handleColorChange} />
                </GridItem>
                <GridItem xs={12}>
                <Field
                  name="signConfig"
                  component={CustomisableSelect}
                  props={{
                    controlId: "signConfig", 
                    label: "Configuration Category *",
                    items: selectableSignConfigs,
                    value: signConfigSelected,
                    handleChange: this.handleSignConfigChange,
                    disabled: readOnlyMode
                  }}
                  value={signConfigSelected}
                />
                </GridItem>
              {this.renderSignConfigFields(selectedSignConfig, signConfigAttributes)}
              {readOnlyMode || <GridItem xs={12}>
                <div className={classes.formCategory}>
                  <small>*</small> Required fields
                </div>
              </GridItem>}
              </GridContainer>
                {readOnlyMode || <Button
                  color="success"
                  type="submit"
                  className={classes.registerButton}
                  disabled={submitting}
                >
                  <Create className={classes.icon}/>
                  Update
              </Button>}
              {readOnlyMode || <Button
                  color="danger"
                  className={classes.registerButton}
                  style={{ marginRight: '10px'}}
                  disabled={submitting}
                  onClick={() => onCancel(pristine)}
                ><Cancel className={classes.icon} />
                  Cancel
              </Button>}
              {readOnlyMode || <Button
                  color="primary"
                  className={classes.registerButton}
                  style={{ marginRight: '25px'}}
                  disabled={pristine || submitting}
                  onClick={() => this.handleRevert()}
                ><Revert className={classes.icon}/>
                  Revert
              </Button>}
              {!readOnlyMode || <Button
                  color="success"
                  className={classes.registerButton}
                  disabled={submitting}
                  onClick={() => onCancel(pristine)}
                ><Done className={classes.icon} />
                  Ok
              </Button>}
              
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

EditTemplateVersionConfigurationForm = reduxForm({
  form: 'editTemplateVersionConfiguration',
  validate
})(withStyles(newTemplateGroupFormsStyle)(EditTemplateVersionConfigurationForm));