import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { areas } from './area.reducer';
import { templates } from './template.reducer';
import { messages } from './message.reducer';
import { reports } from "./report.reducer";

const rootReducer = combineReducers({
  authentication,
  users,
  alert,
  areas,
  templates,
  messages,
  reports,
  form: formReducer
});

export default rootReducer;