import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { uniq, intersection } from 'lodash';

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import dashboardRoutes from "routes/dashboard.jsx";
import nonDashboardRoutes from "routes/non-dashboard.jsx";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";

import { userActions } from 'actions';

import logo from "assets/img/spot_logo.svg";
//import { stringify } from "querystring";

class SwitchRoutes extends React.Component {

  render() {
    const user = JSON.parse(localStorage.getItem('user'));
    const jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));
    const jurisdictionId = jurisdiction.id || null;
    const jurisdictionParameter = `jurisdictionId=${jurisdictionId}`;
    let roles = user.roles || null;
    if (jurisdictionId) {
      roles = roles.filter(o => {
        if (o.role === 'superAdmin' ) return true;
        if ((o.parameters) && (o.parameters === jurisdictionParameter)) return true;
        if ((o.role === 'jurisdictionScope') && (o.parameters === jurisdictionId)) return true;
        return false;
      });
    }
  
    const roleNames = uniq(roles.map(o => o.role));

    let routes = [...nonDashboardRoutes, ...dashboardRoutes];
    routes = routes.filter(route => {
      if (route.admin) {
        if (route.roles) {
          if (intersection(route.roles, roleNames).length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    });
  
    return (
      <Switch>
      {/* {nonDashboardRoutes.map((prop, key) => {
        return  <Route path={prop.path} component={prop.component} key={key} />;
      })} */}
      {routes.map((prop, key) => {
        if (prop.redirect)
          return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
        if (prop.collapse)
          return prop.views.map((prop, key) => {
            return (
              <Route path={prop.path} component={prop.component} render={prop.render} key={key} />
            );
          });
        return <Route path={prop.path} component={prop.component} render={prop.render} key={key} />;
      })}
    </Switch>  
    )
  }
} 



var ps;

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      mobileOpen: false,
      miniActive: false 
    };

    this.handleLogout = this.handleLogout.bind(this);
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleLogout(e) {
    e.preventDefault();

    const { dispatch } = this.props;
    dispatch(userActions.logout());
  }

  getRoute() {
    let path = this.props.location.pathname;
    if (path.startsWith("/template-admin/view-template-affected/")) return false;
    if (path.startsWith("/template-admin/template-affected/")) return false;
    return true;
  }
  
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
  }
  
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if(this.state.mobileOpen){
        this.setState({mobileOpen: false})
      }
    }
  }
  
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  
  render() {
    const { classes, user, ...rest } = this.props;

    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={dashboardRoutes}
          logoText={"Spot Parking"}
          logo={logo}
          // image={image}
          handleDrawerToggle={this.handleDrawerToggle}
          handleLogout={this.handleLogout}
          open={this.state.mobileOpen}
          color="blue"
          bgColor="black"
          miniActive={this.state.miniActive}
          authenticatedUser={user}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          {this.getRoute() && (<Header
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            routes={[...dashboardRoutes, ...nonDashboardRoutes]}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />)}
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}><SwitchRoutes/></div>
            </div>
          ) : (
            <div className={classes.map}><SwitchRoutes/></div>
          )}
          {this.getRoute() ? <Footer fluid /> : null}
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func
};

function mapStateToProps(state) {
  const { authentication } = state;
  const { user } = authentication;

  return {
    user
  };
}

export default connect(mapStateToProps)(withStyles(appStyle)(Dashboard));
