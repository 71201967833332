/* eslint-disable */

import React from "react";
import { Field, reduxForm, SubmissionError, reset } from 'redux-form';
import { connect } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material ui icons
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import Create from "@material-ui/icons/Create";
import Cancel from "@material-ui/icons/Cancel";
import Revert from "@material-ui/icons/GetApp";
import Done from "@material-ui/icons/Done";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import CustomTextField from "components/redux-form/CustomTextField/CustomTextField.jsx";

// validators
import { email, stringOfLengthBetween, telephone, checkExistingEmail } from "helpers/validation";

// style for this view
import validationFormsStyle from "assets/jss/spot-admin/views/newTemplateGroupStyle.jsx";

import { userService } from "services";
import { userActions } from "actions";

import { findIndex } from "lodash";

const validate = (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = "First name is a required field";
  }

  if (!values.lastName) {
    errors.lastName = "Last name is a required field";
  }

  if (!values.phonenumber) {
    errors.phonenumber = "Phone number is a required field";
  }

  return errors;
}

const asyncValidate = (values) => {
  return new Promise((resolve, reject) => {
    if (values.oldUserEmail === values.userEmail) return resolve();

    checkExistingEmail(values.userEmail)
    .then(result => {
      if (!result.available) {
        reject({ userEmail: 'This email address has already been registered.' });
      } else {
        resolve();
      }
     

    })
    .catch(error => {
      reject({ userEmail: 'There was a problem determining whether this email already exists or not. Please try again.'});
    });
  });
}

class EditUserPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      alert: null,
      snackBarOpen: false,
      snackBarStatus: "success",
      snackBarMessage: ""
    };

    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.finished = this.finished.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
    this.removeAlert = this.removeAlert.bind(this);
    this.showNotification = this.showNotification.bind(this);
  }

  componentWillUnmount() {
    if (this.notificationTimer) {
      clearTimeout(this.notificationTimer);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { users, userListLoading, match, dispatch } = nextProps;

    let user = JSON.parse((localStorage.getItem('user') || {}));
    let jurisdiction = JSON.parse((localStorage.getItem('user.jurisdiction') || {}));

    if (users === undefined) {
      dispatch(userActions.getAll(jurisdiction.id))
      return {};
    }

    let editUserId = Number(match.params.id);
    if (prevState.id != editUserId) {
      let userIndex = findIndex(users, (o) => { return o.id === editUserId} );
      let user = users[userIndex];  
      if (!user) {
        return {};
      }

      return {
        editUserId, 
        formInitialValues: {
          firstName: user.firstName,
          lastName: user.lastName,
          oldUserEmail: user.email,
          userEmail: user.email,
          userPhone: user.phone,
          roles: user.roles
        }
      };
    }
    
    return {};
  }

  showNotification(status, message) {
    if (!this.state.snackBarOpen) {
      this.setState({ 
        snackBarOpen: true,
        snackBarStatus: status,
        snackBarMessage: message 
      });
      this.notificationTimer = setTimeout(
        function() {
          this.setState({
            snackBarOpen: false
          });
          this.notificationTimer = null;
        }.bind(this),
        3000
      );
    }
  }

  showSuccess() {
    const { classes } = this.props;

    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success!"
          onConfirm={() => this.finished()}
          confirmBtnCssClass={
            classes.button + " " + classes.success
          }
          confirmBtnText="Ok"
        >
        The user has been updated successfully
        </SweetAlert>
      )
    })
  }


  finished() {
    const { history  } = this.props;

    this.setState({
      alert: null
    });
    history.goBack();
  }

  removeAlert() {
    this.setState({
      alert: null
    });
  }

  submit(values) {

    const { dispatch } = this.props;
    const { editUserId } = this.state;

    let user = {
      id: editUserId, 
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.userPhone,
      email: values.userEmail
    };
    
    return new Promise((resolve, reject) => {
      userService.updateUser(user)
      .then(updatedUser => {
        updatedUser.roles = values.roles;
        dispatch(userActions.registerUser(updatedUser));
        this.showNotification("success", "User has been successfully created!");
        resolve(updatedUser);
        this.showSuccess()
      })
      .catch(error => {
        console.log("ERROR updating user ", error);
        this.showNotification("danger", "There was a problem updating the user!");
        reject(new SubmissionError({ _error: "There was a problem updating the user."} ) );
      });
    });

  }

  cancel(pristine) {
    const { classes, history } = this.props;

    if (!pristine) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block" }}
            title="Warning!"
            onConfirm={() => this.finished()}
            onCancel={() => this.removeAlert()}
            confirmBtnCssClass={
              classes.button + " " + classes.success
            }
            cancelBtnCssClass={
              classes.button + " " + classes.danger
            }
            confirmBtnText="Yes"
            cancelBtnText="No"
            showCancel
          >
          Cancelling will discard any changes made.  Are you sure?
          </SweetAlert>
        )
      });  
    } else {
      history.goBack();
    }
  }


  render() {
    const { alert, snackBarOpen, snackBarMessage, snackBarStatus, editUserId, formInitialValues } = this.state;
    const { readOnlyMode } = this.props;

    return (
      <div>
        {alert}
        <Snackbar
          place="tr"
          color={snackBarStatus}
          icon={AssignmentInd}
          message={snackBarMessage}
          open={snackBarOpen}
          closeNotification={() => this.setState({ snackBarOpen: false })}
          close
        />
        <EditUserForm readOnlyMode={readOnlyMode} editUserId={editUserId} formInitialValues={formInitialValues} onSubmit={this.submit} onCancel={this.cancel} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { alert, users } = state;

  if (!users) {
    return {};
  }
  
  return {
    users: users.users,
    userListLoading: users.userListLoading,
    assignableRoles: users.assignableRoles,
    requestingAssignableRoles: users.requestingAssignableRoles,
    jurisdictionScopes: users.jurisdictionScopes,
    alert: alert || null
  };
} 

export default connect(mapStateToProps)(withStyles(validationFormsStyle)(EditUserPage));


class EditUserForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      initialValuesLoaded: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { initialValuesLoaded } = this.state;
    const { initialize, formInitialValues } = this.props;

    if ((!initialValuesLoaded) && (formInitialValues)) {
      initialize(formInitialValues);
      this.setState({initialValuesLoaded: true});
    }
  }

  render() {
    const { classes, handleSubmit, pristine, submitting, readOnlyMode,
      submitFailed, submitSucceeded,  onCancel, reset, formInitialValues, initialize, editUserId } = this.props;
    const { initialValuesLoaded } = this.state;

    if (!formInitialValues) {
      return (
        <GridContainer>
          <GridItem xs={12}>
            <h4>{`The requested User for ${readOnlyMode ? 'viewing' : 'editing'} could not be found.`}</h4>
          </GridItem>
        </GridContainer>        
      )
    }


    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentInd />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{`${readOnlyMode ? 'View' : 'Edit'} existing User`}</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit} autoComplete="off">
              <GridContainer>
                <GridItem xs={9}>
                  <Field 
                    name="userEmail" 
                    component={CustomTextField} 
                    label="Email Address *"
                    props={{ fullWidth: true, inputProps: { type: "email" } }} 
                    validate={email}
                    autoComplete="email"
                    disabled={readOnlyMode}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <Field 
                    name="firstName" 
                    component={CustomTextField} 
                    label="First Name *"
                    props={{ fullWidth: true }} 
                    autoComplete="given-name"
                    disabled={readOnlyMode}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <Field 
                    name="lastName" 
                    component={CustomTextField}
                    label="Last Name *"
                    props={{ fullWidth: true }} 
                    autoComplete="family-name"
                    disabled={readOnlyMode}
                  />
                  </GridItem>
                  <GridItem xs={6}>
                  <Field 
                    name="userPhone" 
                    component={CustomTextField}
                    label="Phone Number *" 
                    props={{ fullWidth: true }} 
                    validate={telephone}
                    autoComplete="tel-national"
                    disabled={readOnlyMode}
                  />
                  </GridItem>
                  {readOnlyMode || <GridItem xs={12}>
                    <div className={classes.formCategory}>
                      <small>*</small> Required fields
                  </div>
                  </GridItem>}
              </GridContainer>
                {readOnlyMode || <Button
                  color="success"
                  type="submit"
                  className={classes.registerButton}
                  disabled={pristine || submitting}
                >
                <Create className={classes.icon} />
                Update
              </Button>}
              {readOnlyMode || <Button
                  color="danger"
                  className={classes.registerButton}
                  style={{ marginRight: '10px'}}
                  disabled={submitting}
                  onClick={() => onCancel(pristine)}
                ><Cancel className={classes.icon} />
                  Cancel
              </Button>}
              {readOnlyMode || <Button
                  color="primary"
                  className={classes.registerButton}
                  style={{ marginRight: '25px'}}
                  disabled={pristine || submitting}
                  onClick={reset}
                ><Revert className={classes.icon}/>
                  Revert
              </Button>}
              {!readOnlyMode || <Button
                  color="success"
                  className={classes.registerButton}
                  disabled={submitting}
                  onClick={() => onCancel(pristine)}
                ><Done className={classes.icon} />
                  Ok
              </Button>}

              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

EditUserForm = reduxForm({
  form: 'editUser',
  validate,
  asyncValidate,
  asyncBlurFields: ['userEmail']
})(withStyles(validationFormsStyle)(EditUserForm));