/* eslint-disable */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as moment from "moment-timezone";

// react component for creating dynamic tables
import ReactTable from "react-table";
import TagsInput from "react-tagsinput";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Description from "@material-ui/icons/Description";
import Done from "@material-ui/icons/Done";
import Clear from "@material-ui/icons/Clear";
import Add from "@material-ui/icons/Add";
import List from "@material-ui/icons/List";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import RoleSelector from "views/Components/RoleSelector.jsx";
import TimeFilterSelector from "components/TimeFilterSelector/TimeFilterSelector.jsx";

// colors
import colorRed from '@material-ui/core/colors/red';
import colorGreen from '@material-ui/core/colors/green';

import { reportActions, alertActions, templateActions } from "actions";
import { convertFromUTCToTimezone } from "../../utils/TimeStructures";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { findIndex, intersection } from "lodash";


const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  headlineAction: {
    marginRight: "10px"
  },
  headline: {
    contentAlign: "right"
  },
  ok: {
    color: colorGreen[800]
  }, 
  notOk: {
    color: colorRed[800]
  }

};

class ImpactLogTables extends React.Component {
  
  constructor(props) {
    super(props);

    let user = JSON.parse(localStorage.getItem('user'));

    let defaultFromDate = new Date();
    defaultFromDate.setHours(0, 0, 0, 0);

    this.state = {
      snackBarOpen: false,
      snackBarStatus: "success",
      snackBarMessage: "",
      loggedInUser: user,
      timeFilterSettings: {
        filterMode: 10,
        fromDate: defaultFromDate
      }
    };
    
    this.showNotification = this.showNotification.bind(this);
    this.handleGenerate = this.handleGenerate.bind(this);
    this.handleTimeFilterChange = this.handleTimeFilterChange.bind(this);
  }

  componentWillMount() {
    const { dispatch, timeFilters, templates, templateInstances, signConfigs } = this.props;  
    let user = JSON.parse((localStorage.getItem('user') || {}));
    let jurisdiction = JSON.parse((localStorage.getItem('user.jurisdiction') || {}));

    dispatch(reportActions.getTimeFilters({
      includeNow: true,
      includeFuture: true
    }));

    dispatch(reportActions.resetImpactLogs());

    if (Object.keys(templates).length === 0) {
      dispatch(templateActions.getTemplates(jurisdiction.id));
    }

    if (Object.keys(templateInstances).length === 0) {
      dispatch(templateActions.getTemplateInstances(jurisdiction.id));
    }

    if (Object.keys(signConfigs).length === 0) {
      dispatch(templateActions.getSignConfigs(jurisdiction.id));
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dispatch, alert } = this.props;

    if (alert.type) {
      this.showNotification(alert.type, alert.message);
      dispatch(alertActions.clear())
    }
  }

  handleTimeFilterChange(params) {
    const { dispatch } = this.props;

    this.setState({
      timeFilterSettings: params,
    });

    dispatch(reportActions.resetImpactLogs());
  }

  handleGenerate() {
    const { dispatch } = this.props;
    const { timeFilterSettings } = this.state;

    let jurisdiction = JSON.parse((localStorage.getItem('user.jurisdiction') || {}));

    dispatch(reportActions.getImpactLogs({
      timeFilterMode: timeFilterSettings.filterMode,
      fromDate: timeFilterSettings.fromDate,
      toDate: timeFilterSettings.toDate
    }));
  }

  showNotification(status, message) {
    if (!this.state.snackBarOpen) {
      this.setState({ 
        snackBarOpen: true,
        snackBarStatus: status,
        snackBarMessage: message 
      });
      this.notificationTimer = setTimeout(
        function() {
          this.setState({
            snackBarOpen: false
          });
          this.notificationTimer = null;
        }.bind(this),
        7000
      );
    }
  }

  render() {
    const { classes, impactReport, impactReportLoading, timeFilters, timeFiltersLoading, alert, templates, templateInstance, signConfigs } = this.props;
    const { snackBarStatus, snackBarOpen, snackBarMessage, loggedInUser, timeFilterSettings } = this.state;

    if (impactReportLoading) return (
      <h2>Loading...</h2>
    );

    let alertNotice = null;
    if (alert.type) {
      alertNotice = <SnackbarContent message={alert.message} color={alert.type} />
    }
    
    return (
      <GridContainer>
        {/* <GridItem xs={6}/>
        <GridItem xs={6} className={classes.headline}>
        <Tooltip title="Refresh report">
          <Button color="info" style={{ float: 'right' }} onClick={() => this.handleRefresh()}>
            <Refresh className={classes.icon} />Refresh
          </Button>
        </Tooltip>
        </GridItem> */}
        <GridItem xs={12}>
        <Snackbar
          place="tr"
          color={snackBarStatus}
          message={snackBarMessage}
          open={snackBarOpen}
          closeNotification={() => this.setState({ snackBarOpen: false })}
          close
        />
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Description />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Inventory Impact Reports</h4>
            </CardHeader>
            <CardBody>
              {timeFiltersLoading && <h4>Please wait...</h4>}
              {timeFilters && (<div><GridItem xs={6}>
                <TimeFilterSelector filters={timeFilters} controlId="timeFilterMode" value={timeFilterSettings.filterMode} onTimeFilterChange={this.handleTimeFilterChange} />
              </GridItem>
              <GridItem xs={6}>
                <Button color="success" onClick={() => this.handleGenerate()}>
                  <List className={classes.icon} />Generate
                </Button>
              </GridItem></div>)}
              <br/>
              {impactReport && impactReport.results && impactReport.results.length === 0 && <GridItem xs={12}><h4>No results found</h4></GridItem>} 
              {impactReport && impactReport.results && impactReport.results.length > 0 && <GridItem xs={12}><ReactTable
                data={impactReport.results}
                filterable
                defaultFilterMethod={(filter, row) => String(row[filter.id]).substring(0, filter.value.length).toLowerCase() === filter.value.toLowerCase()}
                columns={[
                  {
                    Header: "ID",
                    accessor: "id",
                    maxWidth: 70
                  },
                  {
                    Header: "From Date",
                    id: "fromDate",
                    accessor: ((reportRow) => {
                      return new Date(reportRow.fromDate);
                    }),
                    Cell: ((row) => {
                      return (
                        <div>
                          {/* <Tooltip title={row.value.toLocaleString(navigator.language)}> */}
                            <span>{row.value.toLocaleString(navigator.language)}</span>
                          {/* </Tooltip> */}
                        </div>
                      );
                    }),
                    sortMethod: (a, b) => {
                      if (a > b) return 1;
                      return -1;
                    },
                    filterMethod: ((filter, row) => {
                      const date = row.fromDate.toLocaleString(navigator.language).replace(/,/g, '')
                      return date.substring(0, filter.value.length).toLowerCase() === filter.value.toLowerCase();
                    }),
                    maxWidth: 200
                  },
                  {
                    Header: "To Date",
                    id: "toDate",
                    accessor: ((reportRow) => {
                      return new Date(reportRow.toDate);
                    }),
                    Cell: ((row) => {
                      return (
                        <div>
                          {/* <Tooltip title={row.value.toLocaleString(navigator.language)}> */}
                            <span>{row.value.toLocaleString(navigator.language)}</span>
                          {/* </Tooltip> */}
                        </div>
                      );
                    }),
                    sortMethod: (a, b) => {
                      if (a > b) return 1;
                      return -1;
                    },
                    filterMethod: ((filter, row) => {
                      const date = row.toDate.toLocaleString(navigator.language).replace(/,/g, '')
                      return date.substring(0, filter.value.length).toLowerCase() === filter.value.toLowerCase();
                    }),
                    maxWidth: 200
                  },
                  {
                    Header: "Lot Name",
                    id: "lotName",
                    accessor: "lotName",
                    maxWidth: 300,
                  },
                  {
                    Header: "Impacts",
                    id: "impacts",
                    filterable: false,
                    sortable: false,
                    accessor: ((reportRow) => {
                      return { impacts: reportRow.affected }
                    }),
                    Cell: ((row) => {
                      let impacts = {...row.value.impacts}
                      delete impacts.text;

                      return (
                        <div>
                          {/* <Tooltip title={
                            <React.Fragment>
                              {Object.keys(impacts).length === 0 ? (<pre>No parameters</pre>) : (<pre style={{fontSize: '8px'}}>{JSON.stringify(impacts || {}, null, 2)}</pre>)}
                            </React.Fragment>
                            }> */}
                            <div>
                            {row.value.impacts.text.map( (e, index) => {
                              return <span key={index}>{e.entry} {e.signConfigId && signConfigs[e.signConfigId] ? ` is set to ${signConfigs[e.signConfigId].category}` : ''}<br/></span>
                            })}

                            </div>

                          {/* </Tooltip> */}
                        </div>
                      )
                    }),
                    maxWidth: 400
                  }
                ]}
                minRows={50}
                defaultPageSize={50}
                showPaginationTop
                showPaginationBottom={true}
                className="-striped -highlight"
              /></GridItem>}
              
              {alertNotice}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  const { alert, reports, templates } = state;

  return {
    templates: templates.templates || {},
    templateInstances: templates.templateInstances || {},
    signConfigs: templates.signConfigs || {},
    impactReport: reports.impactReport,
    impactReportLoading: reports.impactReportloading,
    timeFilters: reports.timeFilters || null,
    timeFiltersLoading: reports.timeFiltersLoading,
    alert
  };
}

export default connect(mapStateToProps)(withStyles(styles)(ImpactLogTables));

