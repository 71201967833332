/*
 *import Pages from "layouts/Pages.jsx";
 *import RTL from "layouts/RTL.jsx";
 */
import Dashboard from "layouts/Dashboard.jsx";
import Login from "views/Pages/LoginPage.jsx";
import ResetPasswordPage from "views/Pages/ResetPasswordPage.jsx";

const indexRoutes = [

 /*
  * { path: "/rtl", name: "RTL", component: RTL },
  * { path: "/pages", name: "Pages", component: Pages },
  */
  { path: "/login", name: "Login", component: Login, admin: false },
  { path: "/resetPassword", name: "Reset Password", component: ResetPasswordPage, admin: false },
  { path: "/", name: "Home", component: Dashboard, admin: true }
];

export default indexRoutes;
