/* eslint-disable */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// react component for creating dynamic tables
import ReactTable from "react-table";
import TagsInput from "react-tagsinput";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Users from "@material-ui/icons/People";
import Done from "@material-ui/icons/Done";
import Clear from "@material-ui/icons/Clear";
import Add from "@material-ui/icons/Add";
import Refresh from "@material-ui/icons/Refresh";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import RoleSelector from "views/Components/RoleSelector.jsx";

// colors
import colorRed from '@material-ui/core/colors/red';
import colorGreen from '@material-ui/core/colors/green';

import { userActions, alertActions } from "actions";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { findIndex, intersection, camelCase } from "lodash";
import { read } from "fs";


const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  headlineAction: {
    marginRight: "10px"
  },
  headline: {
    contentAlign: "right"
  },
  ok: {
    color: colorGreen[800]
  }, 
  notOk: {
    color: colorRed[800]
  }

};

Number.prototype.pad = function(size) {
  var sign = Math.sign(this) === -1 ? '-' : '';
  return sign + new Array(size).concat([Math.abs(this)]).join('0').slice(-size);
}

class UserTables extends React.Component {
  
  constructor(props) {
    super(props);

    let user = JSON.parse(localStorage.getItem('user'));

    this.state = {
      snackBarOpen: false,
      snackBarStatus: "success",
      snackBarMessage: "",
      loggedInUser: user
    };
    
    this.showNotification = this.showNotification.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleActionSelect = this.handleActionSelect.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleUserEnablement = this.handleUserEnablement.bind(this);
    this.addUser = this.addUser.bind(this);
    this.viewUser = this.viewUser.bind(this);
    this.editUser = this.editUser.bind(this);
    this.renderTag = this.renderTag.bind(this);
    this.renderInput = this.renderInput.bind(this);
    this.handleTagChange = this.handleTagChange.bind(this);
    this.handleTagAdd = this.handleTagAdd.bind(this);
    this.addRolePrompt = this.addRolePrompt.bind(this);
    this.removePrompt = this.removePrompt.bind(this);

    this.userActionsList = [
      { label: "Reset Password", filterTest: (user, props) => { return !props.requestingPasswordReset && !props.readOnlyMode }, handler: this.handleResetPassword },
      { label: "Disable user", filterTest: (user, props) => { return user.enabled && !props.readOnlyMode }, handler: (user) => this.handleUserEnablement(user) },
      { label: "Enable user", filterTest: (user, props) => { return !user.enabled && !props.readOnlyMode }, handler: (user) => this.handleUserEnablement(user) },
      { label: "View user details", filterTest: (user, props) => { return true }, handler: (user) => this.viewUser(user) },
      { label: "Edit user details", filterTest: (user, props) => { return true && !props.readOnlyMode }, handler: (user) => this.editUser(user) }
    ];
  }

  componentWillMount() {
    const { dispatch, users, assignableRoles, jurisdictionScopes } = this.props;  
    let user = JSON.parse((localStorage.getItem('user') || {}));
    let jurisdiction = JSON.parse((localStorage.getItem('user.jurisdiction') || {}));

    if ((users === undefined) || (users.length === 0)) {
      dispatch(userActions.getAll(jurisdiction.id))
    }

    if (assignableRoles === undefined) {
      dispatch(userActions.getAssignableRoles());
    }

    if (jurisdictionScopes === undefined) {
      dispatch(userActions.checkJurisdictionScope(user.id));
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dispatch, alert } = this.props;

    if (alert.type) {
      this.showNotification(alert.type, alert.message);
      dispatch(alertActions.clear())
    }
  }


  addUser(event) {
    const { history } = this.props;

    event.stopPropagation();

    history.push('/user-admin/new-user');
  }

  viewUser(user) {
    const { history } = this.props;

    event.stopPropagation();

    history.push(`/user-admin/view-user/${user.id}`);
  }

  editUser(user) {
    const { history } = this.props;

    event.stopPropagation();

    history.push(`/user-admin/edit-user/${user.id}`);
  }

  removePrompt() {
    this.setState({
      prompt: null
    });
  }

  handleRefresh() {
    const { dispatch } = this.props;
    let jurisdiction = JSON.parse((localStorage.getItem('user.jurisdiction') || {}));

    dispatch(userActions.getAll(jurisdiction.id));
  }

  handleActionSelect(itemIndex, reference, user) {
    if (itemIndex !== undefined) {
      let actionList = this.userActionsList.filter(e => e.filterTest(user, this.props));
      if (actionList[itemIndex].handler) {
        actionList[itemIndex].handler(reference);
      }
    }
  }

  handleResetPassword(user) {
    const { dispatch } = this.props;
  
    if (user) {
      dispatch(userActions.passwordReset(user.email));
    }
  }

  handleUserEnablement(user) {
    const { dispatch } = this.props;

    if (user) {
      dispatch(userActions.switchEnabledStatus(user.id, !user.enabled));
    }
  }

  handleTagChange(remainingTags, tagsToRemove, indexOfRemoval, user) {
    const { dispatch } = this.props;

    const removeTags = tagsToRemove.map(tag => camelCase(tag));
  
    const removeIds = removeTags.map(tag => {
      const index = findIndex(user.roles, (o) => o.userRole == tag);
      if (index !== -1) return user.roles[index].roleId;
      return null;
    }).filter(o => o != null);

    removeIds.forEach(roleId => {
      dispatch(userActions.deleteUserRole(roleId));
    });
   
  }

  handleTagAdd(userRole, user) {
    const { dispatch } = this.props;

    if (user) {
      dispatch(userActions.addNewUserRole(user.id, userRole.userRole, userRole.roleParameters))
    }

    this.removePrompt();
  }

  addRolePrompt(e, user) {
    const { assignableRoles } = this.props;

    this.setState({
      prompt: (
        <RoleSelector
          title="Select role to add"
          assignableRoles={assignableRoles}
          currentRoles={user.roles}
          onCancel={() => this.removePrompt()}
          onConfirm={(userRole) => this.handleTagAdd(userRole, user)}
        />
      )
    })
  }

  showNotification(status, message) {
    if (!this.state.snackBarOpen) {
      this.setState({ 
        snackBarOpen: true,
        snackBarStatus: status,
        snackBarMessage: message 
      });
      this.notificationTimer = setTimeout(
        function() {
          this.setState({
            snackBarOpen: false
          });
          this.notificationTimer = null;
        }.bind(this),
        7000
      );
    }
  }

  renderTag(props, isSelf) {
    let {tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other} = props

    return (<div key={key}>
      <span {...other} style={{marginBottom: "1px", marginTop: "1px"}}>
        {getTagDisplayValue(tag)}
        {!disabled && !isSelf &&
          <Tooltip title="Remove this role from user">
            <a className={classNameRemove}  onClick={(e) => onRemove(key)} />
          </Tooltip>
        }
      </span>
    </div>);
  }

  renderInput(props, isSelf, user, hasMoreRoles) {
    let {disabled, key, value, addTag, ...other} = props

    return (!disabled && !isSelf && hasMoreRoles &&
        <Tooltip key={key} title="Add a new role for user">
          <Button color="success" size="sm" round={true} onClick={(e) => this.addRolePrompt(e, user)} value={value} {...other} >Add Role</Button>
        </Tooltip>
    )
  }

  convertToHumanReadableLabel(label) {
    let tempLabel = label.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");
    return tempLabel.charAt(0).toUpperCase() + tempLabel.slice(1);
  }

  render() {
    const { classes, users, loading, assignableRoles, readOnlyMode } = this.props;
    const { prompt, snackBarStatus, snackBarOpen, snackBarMessage, loggedInUser } = this.state;

    if ((users === undefined) || (loading) || (assignableRoles === undefined)) return (
      <h2>Loading...</h2>
    );

    let alertNotice = null;
    if (alert.type) {
      alertNotice = <SnackbarContent message={alert.message} color={alert.type} />
    }
    
    return (
      <GridContainer>
        <GridItem xs={6}><h3>Available Users</h3></GridItem>
        <GridItem xs={6} className={classes.headline}>
        {readOnlyMode || <Tooltip title="Add A New User">
          <Button color="success" style={{ float: 'right', paddingRight: '20px' }} onClick={(e) => this.addUser(e)}>
            <Add className={classes.icon} />Add User
        </Button>
        </Tooltip>}
        <Tooltip title="Refresh user list">
          <Button color="info" style={{ float: 'right', marginRight: '10px' }} onClick={() => this.handleRefresh()}>
            <Refresh className={classes.icon} />Refresh
          </Button>
        </Tooltip>
        </GridItem>
        <GridItem xs={12}>
        {prompt}
        <Snackbar
          place="tr"
          color={snackBarStatus}
          message={snackBarMessage}
          open={snackBarOpen}
          closeNotification={() => this.setState({ snackBarOpen: false })}
          close
        />
         {!users.length && <h4>No users currently available.</h4>}
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Users />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Users</h4>
            </CardHeader>
            <CardBody>

              <ReactTable
                data={users}
                filterable
                defaultFilterMethod={(filter, row) => String(row[filter.id]).substring(0, filter.value.length).toLowerCase() === filter.value.toLowerCase()}
                columns={[
                  {
                    Header: "ID",
                    accessor: "id",
                    maxWidth: 100
                  },
                  { 
                    Header: "First name",
                    accessor: "firstName",
                    maxWidth: 200
                  },
                  {
                    Header: "Last name",
                    accessor: "lastName",
                    maxWidth: 200
                  },
                  {
                    Header: "Email address",
                    accessor: "email",
                    maxWidth: 300
                  },
                  { Header: "Roles",
                    id: "roles",
                    sortable: false,
                    accessor: ((user) => { return (
                      <TagsInput style={{ overflowX: 'wrap' }}
                        value={user.roles.map(e => this.convertToHumanReadableLabel(e.userRole)).sort()}
                        disabled={readOnlyMode}
                        tagProps={{ className: "react-tagsinput-tag info" }}
                        inputProps={{ placeholder: ''}}
                        renderTag={(props) => this.renderTag(props, user.id === loggedInUser.id)}
                        renderInput={(props) => this.renderInput(props,  user.id === loggedInUser.id, user, assignableRoles.length !== user.roles.length)}
                        onChange={(e, f, g) => this.handleTagChange(e, f, g, user)}
                      />
                      ) }),
                    maxWidth: 200,
                    filterMethod: ((filter, row) => {
                      let rowRoles = row.roles.props.value;
                      let filteredRows = rowRoles.filter((role) => {
                        return role.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1;
                      });
                      return filteredRows.length > 0 ? true : false;
                    })
                  },
                  {
                    Header: "Enabled",
                    id: "enabled",
                    headerStyle: { textAlign: 'left' },
                    filterable: true,
                    resizable: false,
                    sortable: false,
                    accessor: ((user) => {
                      return (
                        <div style={{textAlign: "center"}}>
                          <Tooltip title={`User is ${user.enabled ? 'enabled' : 'disabled'}`}>
                            {user.enabled ? <Done className={classes.ok}/> : <Clear className={classes.notOk} />}
                          </Tooltip>
                        </div>
                      )
                    }),
                    filterMethod: (filter, row) => {
                      if (filter.value === 'all') return true;
                      let userIndex = findIndex(users, (o) => o.id === row.id);
                      let user = users[userIndex];
                      if (filter.value === 'true') {
                        return user.enabled;
                      } else {
                        return !user.enabled;
                      }
                    },
                    Filter: ({ filter, onChange }) =>
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : "all"}
                    >
                      <option value="all">Show All</option>
                      <option value="true">Enabled</option>
                      <option value="false">Disabled</option>
                    </select>,
                    maxWidth: 100
                  },
                  {
                    Header: "Actions",
                    id: "actions",
                    maxWidth: 150,
                    sortable: false,
                    resizable: false,
                    filterable: false,
                    accessor: ((user) => {
                      return (
                        <CustomDropdown
                          buttonText="Select"
                          buttonProps={{
                            round: true,
                            fullWidth: true,
                            style: { marginBottom: "0" },
                            color: "primary",
                            size: "sm"
                          }}
                          dropdownHeader="User actions"
                          dropdownList={this.userActionsList.filter(e => e.filterTest(user, this.props)).map(e => e.label)}
                          onItemClick={(e, f) => this.handleActionSelect(e, f, user)}
                          referenceId={user}
                        />
                      );
                    })
                  }
                ]}
                minRows={1}
                defaultPageSize={100}
                showPaginationTop
                showPaginationBottom={true}
                className="-striped -highlight"
              />
              {alertNotice}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  const { alert, users } = state;

  let readOnlyMode = false;
  const user = JSON.parse(localStorage.getItem('user'));
  if (user.roles) {
    if (intersection(['superAdmin', 'corporateAdmin', 'userAdmin'], user.roles.map(o => o.role)).length === 0) readOnlyMode = true;
  }

  return {
    users: users.users,
    loading: users.userListLoading,
    assignableRoles: users.assignableRoles,
    jurisdictionScopes: users.jurisdictionScopes,
    requestingPasswordReset: users.requestingPasswordReset,
    readOnlyMode,
    alert
  };
}

export default connect(mapStateToProps)(withStyles(styles)(UserTables));

