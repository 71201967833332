/* eslint-disable */
import { areaConstants } from '../constants';
import { areaService } from '../services';
import { alertActions } from '.';

/*
 * area starts related actions
 */
export const areaActions = {
  getAll
};

function getAll(processingStatuses) {
  return dispatch => {
      dispatch(request());

      areaService.outline(processingStatuses)
          .then(
              areas => dispatch(success(areas)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
              }
          );
  };

  function request() { return { type: areaConstants.OUTLINE_REQUEST } }
  function success(areas) { return { type: areaConstants.OUTLINE_SUCCESS, areas } }
  function failure(error) { return { type: areaConstants.OUTLINE_FAILURE, error } }
}
