/* eslint-disable */

import { userConstants } from '../constants';
import { findIndex as _findIndex } from "lodash";

export function users(state = {}, action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        ...state,
        users: [],
        userListLoading: true
      };

    case userConstants.GETALL_SUCCESS:
      return {
        ...state,
        users: action.users,
        userListLoading: false
      };

    case userConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };

    ////////////////////////

    case userConstants.ADD_NEW_USER_REQUEST:
      return {
        ...state,
        newUser: null,
        requestingNewUser: true
      };

    case userConstants.ADD_NEW_USER_SUCCESS:
      let updatedUsers = [ ...state.users || [] ];
      updatedUsers.push(action.user);
      return {
        ...state,
        users: updatedUsers,
        newUser: action.user,
        requestingNewUser: false
      };

    case userConstants.ADD_NEW_USER_FAILURE:
      return {
        ...state,
        newUser: null,
        requestingNewUser: false,
        error: action.error
      };

    ////////////////////////

    case userConstants.UPDATE_USER_REQUEST:
      return {
        ...state,
        updatingUserId: action.user.id,
        updatingUser: true
      };

    case userConstants.UPDATE_USER_SUCCESS:
      updatedUsers = [ ...state.users || [] ];
      let updatedUserIndex = _findIndex(updatedUsers, (o) => { return o.id == action.user.id });

      if (updatedUserIndex !== -1) {
        updatedUsers[updatedUserIndex] = action.user;
      }

      return {
        ...state,
        users: updatedUsers,
        updatingUserId: null,
        updatingUser: false
      };

    case userConstants.UPDATE_USER_FAILURE:
      
      return {
        ...state,
        updatingUserId: null,
        updatingUser: false,
        error: action.error
      };

  ////////////////////////

    case userConstants.REGISTER_USER:
      updatedUsers = [ ...state.users || [] ];
      updatedUserIndex = _findIndex(updatedUsers, (o) => { return o.id == action.user.id });
      if (!action.user.roles) action.user.roles = [];
      if (updatedUserIndex !== -1) {
        updatedUsers[updatedUserIndex] = action.user;
      } else {
        updatedUsers.push(action.user);
      }

      return {
        ...state,
        users: updatedUsers
      };
    
  ////////////////////////

    case userConstants.PASSWORD_RESET_REQUEST:
      return {
        ...state,
        requestingPasswordReset: true
      };

    case userConstants.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        requestingPasswordReset: false
      };

    case userConstants.PASSWORD_RESET_FAILURE:
      return {
        ...state,
        requestingPasswordReset: false,
        error: action.error
      };

    ////////////////////////

    case userConstants.SWITCH_ENABLED_STATUS_REQUEST:
      return {
        ...state,
        requestingEnabledChange: true
      };

    case userConstants.SWITCH_ENABLED_STATUS_SUCCESS:
      updatedUsers = [ ...state.users || [] ];
      updatedUserIndex = _findIndex(updatedUsers, (o) => { return o.id == action.user.id });

      if (updatedUserIndex !== -1) {
        updatedUsers[updatedUserIndex].enabled = action.user.enabled;
      }

      return {
      ...state,
      users: updatedUsers,
      requestingEnabledChange: false
    };

    case userConstants.SWITCH_ENABLED_STATUS_FAILURE:
      return {
       ...state,
       requestingEnabledChange: false,
        error: action.error
      };

    ////////////////////////

    case userConstants.ADD_NEW_USER_ROLE_REQUEST:
      return {
        ...state,
        requestingUserRoleChange: true
      };

    case userConstants.ADD_NEW_USER_ROLE_SUCCESS:
      updatedUsers = [ ...state.users || [] ];
      updatedUserIndex = _findIndex(updatedUsers, (o) => { return o.id == action.userRole.userId });

      if (updatedUserIndex !== -1) {
        delete action.userRole.userId;
        updatedUsers[updatedUserIndex].roles.push(action.userRole);
      }

      return {
        ...state,
        users: updatedUsers,
        requestingUserRoleChange: false
      };

    case userConstants.ADD_NEW_USER_ROLE_FAILURE:
      return {
        ...state,
        requestingUserRoleChange: false,
        error: action.error
      };
  
    ////////////////////////

    case userConstants.UPDATE_USER_ROLE_REQUEST:
      return {
        ...state,
        requestingUserRoleChange: true
      };
  
    case userConstants.UPDATE_USER_ROLE_SUCCESS:
      updatedUsers = [ ...state.users || [] ];
      updatedUserIndex = _findIndex(updatedUsers, (o) => { return o.id == action.userRole.userId });
  
      if (updatedUserIndex !== -1) {
        let roleIndex = _findIndex(updatedUsers[updatedUserIndex].roles, (o) => { return o.roleId == action.userRole.roleId });
        if (roleIndex !== -1) {
          delete action.userRole.userId;
          updatedUsers[updatedUserIndex].roles[roleIndex] = action.userRole;
        }
      }
  
      return {
        ...state,
        users: updatedUsers,
        requestingUserRoleChange: false
      };
  
    case userConstants.UPDATE_USER_ROLE_FAILURE:
      return {
        ...state,
        requestingUserRoleChange: false,
        error: action.error
      };

    ////////////////////////

    case userConstants.DELETE_USER_ROLE_REQUEST:
      return {
        ...state,
        requestingUserRoleChange: true
      };

    case userConstants.DELETE_USER_ROLE_SUCCESS:
      updatedUsers = [ ...state.users || [] ];
      updatedUserIndex = _findIndex(updatedUsers, (o) => { return o.id === action.userRole.userId });

      if (updatedUserIndex !== -1) {
        let roleIndex = _findIndex(updatedUsers[updatedUserIndex].roles, (o) => { return o.roleId === action.userRole.roleId });
        if (roleIndex !== -1) updatedUsers[updatedUserIndex].roles.splice(roleIndex, 1);
      }

      return {
        ...state,
        users: updatedUsers,
        requestingUserRoleChange: false
      };

    case userConstants.DELETE_USER_ROLE_FAILURE:
      return {
        ...state,
        requestingUserRoleChange: false,
        error: action.error
      };

    ////////////////////////

    case userConstants.GET_USER_ROLE_REQUEST:
      return {
        ...state,
        requestingUserRole: true
      };

    case userConstants.GET_USER_ROLE_SUCCESS:
      updatedUsers = [ ...state.users || [] ];
      updatedUserIndex = _findIndex(updatedUsers, (o) => { return o.id == action.userRole.userId });

      if (updatedUserIndex !== -1) {
        let roleIndex = _findIndex(updatedUsers[updatedUserIndex].roles, (o) => { return o.roleId == action.userRole.roleId });        
        delete action.userRole.userId;
        if (roleIndex !== -1) {
          updatedUsers[updatedUserIndex].roles[roleIndex] = action.userRole;
        } else {
          updatedUsers[updatedUserIndex].roles.push(action.userRole);
        }
      }

      return {
        ...state,
        users: updatedUsers,
        requestingUserRole: false
      };

    case userConstants.GET_USER_ROLE_FAILURE:
      return {
        ...state,
        requestingUserRole: false,
        error: action.error
      };

    ////////////////////////

    case userConstants.GET_USER_ROLES_REQUEST:
      return {
        ...state,
        requestingUserRole: true
      };

    case userConstants.GET_USER_ROLES_SUCCESS:
      updatedUsers = [ ...state.users || [] ];
      updatedUserIndex = _findIndex(updatedUsers, (o) => { return o.id == action.userId });

      action.userRoles = action.userRoles.map(e => {
        delete e.userId;
        return e;
      });

      if (updatedUserIndex !== -1) {
        updatedUsers[updatedUserIndex].roles = action.userRoles;
      }

      return {
        ...state,
        users: updatedUsers,
        requestingUserRole: false
      };

    case userConstants.GET_USER_ROLES_FAILURE:
      return {
        ...state,
        requestingUserRole: false,
        error: action.error
      };

    ////////////////////////
    
    case userConstants.GET_ASSIGNABLE_ROLES_REQUEST:
      return {
        ...state,
        requestingAssignableRoles: true
      };

    case userConstants.GET_ASSIGNABLE_ROLES_SUCCESS:
      return {
        ...state,
        assignableRoles: action.assignableRoles,
        requestingAssignableRoles: false
      };

    case userConstants.GET_ASSIGNABLE_ROLES_FAILURE:
      return {
        ...state,
        requestingAssignableRoles: false,
        error: action.error
      };

    ////////////////////////
    
    case userConstants.CHECK_JURISDICTION_SCOPE_REQUEST:
      return {
        ...state,
        checkingJurisdictionScope: true
      };

    case userConstants.CHECK_JURISDICTION_SCOPE_SUCCESS:
      let jurisdictionScopes = {...(state["jurisdictionScopes"] || {}) };
      jurisdictionScopes[action.userId] = action.jurisdictionScope;

      return {
        ...state,
        jurisdictionScopes,
        checkingJurisdictionScope: false
      };

    case userConstants.CHECK_JURISDICTION_SCOPE_FAILURE:
      return {
        ...state,
        checkingJurisdictionScope: false,
        error: action.error
      };

    ////////////////////////
    
    case userConstants.CHECK_JURISDICTION_DEFAULTS_REQUEST:
      return {
        ...state,
        checkingJurisdictionDefaults: true
      };

    case userConstants.CHECK_JURISDICTION_DEFAULTS_SUCCESS:
      let jurisdictionDefaults = {...(state["jurisdictionDefaults"] || {}) };
      jurisdictionDefaults = action.jurisdictionDefaults;

      return {
        ...state,
        jurisdictionDefaults,
        checkingJurisdictionDefaults: false
      };

    case userConstants.CHECK_JURISDICTION_DEFAULTS_FAILURE:
      return {
        ...state,
        checkingJurisdictionDefaults: false,
        error: action.error
      };

    ////////////////////////

    case userConstants.LOGOUT:
      location.reload(true);

    default:
      return state;
  }
}