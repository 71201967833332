/* eslint-disable */

import { reportConstants } from '../constants';

export function reports(state = {}, action) {
  switch (action.type) {
    case reportConstants.AUDIT_LOG_REQUEST:
      return {
        ...state,
        auditReport: null,
        auditReportloading: true
      };
    case reportConstants.AUDIT_LOG_SUCCESS:
      return {
        ...state,
        auditReport: action.report,
        auditReportloading: false
      };
    case reportConstants.AUDIT_LOG_FAILURE:
      return {
        ...state,
        error: action.error,
        auditReportloading: false
      };
    case reportConstants.AUDIT_LOG_RESET:
      return {
        ...state,
        auditReport: null
      }
  
    case reportConstants.IMPACT_LOG_REQUEST:
      return {
        ...state,
        impactReport: null,
        impactReportloading: true
      };
    case reportConstants.IMPACT_LOG_SUCCESS:
      return {
        ...state,
        impactReport: action.report,
        impactReportloading: false
      };
    case reportConstants.IMPACT_LOG_FAILURE:
      return {
        ...state,
        error: action.error,
        impactReportloading: false
      };
    case reportConstants.IMPACT_LOG_RESET:
      return {
        ...state,
        impactReport: null
      }

    case reportConstants.TIME_FILTERS_REQUEST:
      return {
        ...state,
        timeFilters: null,
        timeFiltersLoading: true
      };

    case reportConstants.TIME_FILTERS_SUCCESS:
      return {
        ...state,
        timeFilters: action.filters,
        timeFiltersLoading: false
      };

    case reportConstants.TIME_FILTERS_FAILURE:
      return {
        ...state,
        error: action.error,
        timeFiltersLoading: false
      };

    default:
      return state;
  }
}