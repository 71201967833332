import React from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { intersection, sortBy, values } from "lodash";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material-ui icons
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import FormatListNumbered from "@material-ui/icons/FormatListNumbered";
import Style from "@material-ui/icons/Style";
import Refresh from "@material-ui/icons/Refresh";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Tooltip from "@material-ui/core/Tooltip";

import templateGroupsStyle from "assets/jss/spot-admin/views/templateGroupsStyle.jsx";

import { templateActions, alertActions } from "actions";



class TemplateGroupsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: null,
      snackBarOpen: false,
      snackBarStatus: "success",
      snackBarMessage: ""
    };

    this.showNotification = this.showNotification.bind(this);
    this.removePrompt = this.removePrompt.bind(this);
    
    this.addTemplateGroup = this.addTemplateGroup.bind(this);
    this.editTemplateGroup = this.editTemplateGroup.bind(this);
    this.deleteTemplateGroup = this.deleteTemplateGroup.bind(this);

    this.addTemplate = this.addTemplate.bind(this);
    this.editTemplate = this.editTemplate.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);

    this.viewTemplateVersions = this.viewTemplateVersions.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);

    this.promptDeleteTemplateGroup = this.promptDeleteTemplateGroup.bind(this);
    this.promptDeleteTemplate = this.promptDeleteTemplate.bind(this);
  }

  componentWillMount() {
    const { dispatch, templateGroups, templateContainers, templates, outlines } = this.props;
    let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

    if (Object.keys(templateGroups).length === 0) {
      dispatch(templateActions.getTemplateGroups(jurisdiction.id));
    }

    if (Object.keys(templateContainers).length === 0) {
      dispatch(templateActions.getTemplateContainers(jurisdiction.id));
    }

    if (Object.keys(templates).length === 0) {
      dispatch(templateActions.getTemplates(jurisdiction.id));
    }

    if (Object.keys(outlines).length === 0) {
      dispatch(templateActions.getOutlines(jurisdiction.id));
    }
  }

  componentWillUnmount() {
    if (this.notificationTimer) {
      clearTimeout(this.notificationTimer);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dispatch, alert } = this.props;

    if (alert.type) {
      this.showNotification(alert.type, alert.message);
      dispatch(alertActions.clear())
    }
  }

  removePrompt() {
    this.setState({
      prompt: null
    });
  }

  handleRefresh() {
    const { dispatch } = this.props;
    let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

    dispatch(templateActions.getTemplateGroups(jurisdiction.id));
    dispatch(templateActions.getTemplateContainers(jurisdiction.id));    
  }

  addTemplateGroup(event) {
    const { history } = this.props;

    event.stopPropagation();

    history.push('/template-admin/template-group/new-template-group');
  }

  addTemplate(event, templateGroupId) {
    const { history } = this.props;

    event.stopPropagation();

    history.push(`/template-admin/template/new-template?templateGroupId=${templateGroupId}`);
  }

  editTemplateGroup(event, templateGroupId) {
    const { history } = this.props;

    event.stopPropagation();

    history.push(`/template-admin/template-group/edit-template-group/${templateGroupId}`);
  }

  promptDeleteTemplateGroup(event, templateGroupId) {
    const { classes, templateContainers } = this.props;

    event.stopPropagation();

    // check for active containers
    let foundRelatedContainers = false;
    let templateContainerIds = Object.keys(templateContainers);
    for (let i = 0; i < templateContainerIds.length; i++) {
      if (templateContainers[templateContainerIds[i]].templategroup.id === templateGroupId) {
        foundRelatedContainers = true;
        break;
      }
    }

    if (foundRelatedContainers) {
      this.setState({
        prompt: (
          <SweetAlert
            error
            style={{ display: "block" }}
            title="Problem!"
            onConfirm={() => this.removePrompt()}
            confirmBtnCssClass={
              classes.button + " " + classes.success
            }
            confirmBtnText="okay"
          >
          A template group cannot be deleted whilst it continues to have any associated templates.  You must delete any associated templates before retrying.
          </SweetAlert>
        )
      });  
    } else {
      this.setState({
        prompt: (
          <SweetAlert
            warning
            style={{ display: "block" }}
            title="Warning!"
            onConfirm={() => this.deleteTemplateGroup(templateGroupId)}
            onCancel={() => this.removePrompt()}
            confirmBtnCssClass={
              classes.button + " " + classes.success
            }
            cancelBtnCssClass={
              classes.button + " " + classes.danger
            }
            confirmBtnText="Yes"
            cancelBtnText="No"
            showCancel
          >
          Deleting this template group cannot be undone! Do you want to continue?
          </SweetAlert>
        )
      });  
      
    }
  }

  deleteTemplateGroup(templateGroupId) {
    const { dispatch } = this.props;

    dispatch(templateActions.deleteTemplateGroup(templateGroupId));
    this.removePrompt();
  }

  viewTemplateVersions(event, templateContainerId) {
    const { history } = this.props;

    event.stopPropagation();

    history.push(`/template-admin/template-detail/${templateContainerId}`);
  }

  editTemplate(event, templateContainerId) {
    const { history } = this.props;

    event.stopPropagation();

    history.push(`/template-admin/template/edit-template/${templateContainerId}`);
  }

  promptDeleteTemplate(event, templateContainerId) {
    const { classes, templates } = this.props;

    event.stopPropagation();
    
    let templateIds = Object.keys(templates);
    let foundPublishedVersion = false;
    for (let i = 0; i < templateIds.length; i++) {
      let template = templates[templateIds[i]];
      if (template.templatecontainer.id === templateContainerId) {
        if (template.published) {
          foundPublishedVersion = true;
          break;
        }
      }
    }

    if (foundPublishedVersion) {
      this.setState({
        prompt: (
          <SweetAlert
            error
            style={{ display: "block" }}
            title="Problem!"
            onConfirm={() => this.removePrompt()}
            confirmBtnCssClass={
              classes.button + " " + classes.success
            }
            confirmBtnText="okay"
          >
          A template cannot be deleted whilst it continues to have any associated versions.  You must unpublish and delete any associated templates before retrying.
          </SweetAlert>
        )
      });  
    } else {
      this.setState({
        prompt: (
          <SweetAlert
            warning
            style={{ display: "block" }}
            title="Warning!"
            onConfirm={() => this.deleteTemplate(templateContainerId)}
            onCancel={() => this.removePrompt()}
            confirmBtnCssClass={
              classes.button + " " + classes.success
            }
            cancelBtnCssClass={
              classes.button + " " + classes.danger
            }
            confirmBtnText="Yes"
            cancelBtnText="No"
            showCancel
          >
          Deleting this template cannot be undone! Do you want to continue?
          </SweetAlert>
        )
      });  
    }
  }

  deleteTemplate(templateContainerId) {
    const { dispatch } = this.props;
    
    dispatch(templateActions.deleteTemplateContainer(templateContainerId));
    this.removePrompt();
  }

  showNotification(status, message) {
    if (!this.state.snackBarOpen) {
      this.setState({ 
        snackBarOpen: true,
        snackBarStatus: status,
        snackBarMessage: message 
      });
      this.notificationTimer = setTimeout(
        function() {
          this.setState({
            snackBarOpen: false
          });
          this.notificationTimer = null;
        }.bind(this),
        3000
      );
    }
  }

  renderTemplateGroupButtons(templateGroupId) {

    const { classes } = this.props;

    const buttons = [
      { task: "editTemplateGroup", color: "success", icon: Edit, title: "Edit Template Group" },
      { task: "promptDeleteTemplateGroup", color: "danger", icon: Delete, title: "Delete Template Group" }
    ];

    return (
      <div>
        {buttons.map((button, key) => {
          return (<Button color={button.color} key={key} style={{ marginRight: '10px'}} onClick={(e) => this[button.task](e, templateGroupId)}>
            <button.icon className={classes.icon} />{button.title}
          </Button>);
        })}
      </div>
    )
  }

  renderActionButtons(templateContainerId) {
    const { classes, readOnlyMode } = this.props;
    
    const actionButtons = [
      { task: "viewTemplateVersions", color: "info", icon: FormatListNumbered, displayIfReadOnlyMode: true, tooltip: "Create, View, Edit Template Versions" },
      { task: "editTemplate", color: "success", icon: Edit, tooltip: "Edit Template Details", displayIfReadOnlyMode: false },
      { task: "promptDeleteTemplate", color: "danger", icon: Delete, tooltip: "Delete This Template", displayIfReadOnlyMode: false },
    ];

    const activeButtons = actionButtons.filter(e => {
      if ((readOnlyMode) && (e.displayIfReadOnlyMode !== undefined) && (e.displayIfReadOnlyMode === false)) return false;
      return true;
    });
    
    return (
      <div>
        {activeButtons.map((button, key) => {
          return (
            <Tooltip key={key} title={button.tooltip}>
              <Button  color={button.color} className={classes.actionButton} onClick={(e) => this[button.task](e, templateContainerId)}>
                <button.icon className={classes.icon} />{''}
              </Button>
            </Tooltip>
          );
        })}
      </div>
    );
  }

  render() {
    const { classes, templateGroups, templateContainers, readOnlyMode, loading } = this.props;
    const { prompt, snackBarStatus, snackBarOpen, snackBarMessage } = this.state;

    if ((templateGroups === undefined) || (loading)) return (
      <h2>Loading...</h2>
    );

    let sortedTemplateGroups = sortBy(values(templateGroups), ['name']);
    let templateGroupIds = sortedTemplateGroups.map(e => e.id);

    let sortedTemplateContainers = sortBy(values(templateContainers), ['name']);
    let templateContainerIds = sortedTemplateContainers.map(e => e.id);

    return (
      <GridContainer>
      <GridItem xs={6}><h3>Available Template Groups</h3></GridItem>
      <GridItem xs={6}>
        {readOnlyMode || <Tooltip title="Add A New Template Group">
          <Button color="success" style={{ float: 'right', paddingRight: '20px' }} onClick={(e) => this.addTemplateGroup(e)}>
            <Add className={classes.icon} />Add Template Group
        </Button>
        </Tooltip>}
        <Tooltip title="Refresh template group list">
          <Button color="info" style={{ float: 'right', marginRight: '10px' }} onClick={() => this.handleRefresh()}>
            <Refresh className={classes.icon} />Refresh
          </Button>
        </Tooltip>
      </GridItem>
      <GridItem xs={12}>
        {prompt}
        <Snackbar
          place="tr"
          color={snackBarStatus}
          message={snackBarMessage}
          open={snackBarOpen}
          closeNotification={() => this.setState({ snackBarOpen: false })}
          close
        />
        {!templateGroupIds.length && <h4>No Template Groups currently available.</h4>}
        {templateGroupIds.map( (key) => {
          let templateGroup = templateGroups[key];
          let groupContainers = [];
          for (let i = 0; i < templateContainerIds.length; i++) {
            if (templateContainers[templateContainerIds[i]].templategroup.id === templateGroup.id) {
              groupContainers.push(templateContainers[templateContainerIds[i]]);
            }
          }
          return (
            <div key={key}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Style />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{templateGroup.name}</h4>
                <p className={classes.cardIconTitle}>{templateGroup.description["en-US"]}</p>
              </CardHeader>
              <CardBody>
              <GridContainer>
                <NavPills 
                  color="warning"
                  tabs={[{
                    tabButton: "Templates",
                    tabContent: (
                      <div>
                      {groupContainers.length === 0 &&
                      <GridItem xs={12}>
                        <h4>No templates currently assigned to template group.</h4>
                      </GridItem>}
                      {groupContainers.length > 0 &&
                      <GridItem xs={12}>
                        <Table style={{ width: '100%'}}
                hover
                striped
                tableHead={[
                  "Name",
                  "Description",
                  "Actions"
                ]}
                tableData={
                  groupContainers.map(templateContainer => {
                    return [
                      templateContainer.name,
                      templateContainer.description["en-US"],
                      this.renderActionButtons(templateContainer.id)
                    ]
                  })
                }
                customCellClasses={[
                  classes.columnName,
                  classes.columnDescription,
                  classes.right
                ]}
                customClassesForCells={[0, 1, 2]}
                customHeadCellClasses={[
                  classes.right
                ]}
                customHeadClassesForCells={[2]}
              />
              </GridItem>
                      }
                      {readOnlyMode || <GridItem xs={12} style={{ marginTop: '10px' }}>
                        <Tooltip title={`Add A New Template In ${templateGroup.name}`}>
                          <Button color="success" style={{  }} onClick={(e) => this.addTemplate(e, templateGroup.id)}>
                            <Add className={classes.icon} />Add Template
                          </Button>
                        </Tooltip>
                      </GridItem>}
                      </div>
                    )
                  },
                  {
                    tabButton: "Group Options",
                    tabContent: (
                      <GridItem xs={12}>
                        {readOnlyMode ? <h4>No template group options available for your role</h4> : this.renderTemplateGroupButtons(key)}
                      </GridItem>
                    )
                  }
                  ]} />
                  </GridContainer>
              </CardBody>
            </Card>
            &nbsp;
            </div>
          );
        })};
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  const { templates, alert } = state;
  
  let readOnlyMode = false;
  const user = JSON.parse(localStorage.getItem('user'));
  if (user.roles) {
    if (intersection(['superAdmin','corporateAdmin', 'templateAdmin', 'templateMaintainer'], user.roles.map(o => o.role)).length === 0) readOnlyMode = true;
  }

  if (!templates) {
    return {};
  }

  return {
    outlines: templates.outlines || {},
    templates: templates.templates || {},
    templateGroups: templates.templateGroups || {},
    templateContainers: templates.templateContainers || {},
    templateInstances: templates.templateInstances || {},
    loading: templates.loadingTemplateGroups || false,
    saving: templates.saving || false,
    deleting: templates.deleting || false,
    readOnlyMode,
    alert: alert || null
  };
}

export default connect(mapStateToProps)(withStyles(templateGroupsStyle)(TemplateGroupsList));