import Dashboard from "views/Dashboard/Dashboard.jsx";
import AreaStartsTable from "views/Tables/AreaStartsTable.jsx";
import UsersTable from "views/Tables/UsersTable";
import AuditLogsTable from "views/Tables/AuditLogTable";
import ImpactLogsTable from "views/Tables/ImpactLogTable";

import TemplateGroupsPage from "views/Tables/TemplateGroupsTable.jsx";
import MessagesPage from "views/Tables/MessagesTable.jsx";


// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import User from "@material-ui/icons/People";
import Map from "@material-ui/icons/Map";
import Message from "@material-ui/icons/Message";
import Room from "@material-ui/icons/Room";
import Description from "@material-ui/icons/Description";

const dashRoutes = [
  { 
    path: "/dashboard", 
    name: "Dashboard", 
    icon: DashboardIcon, 
    component: Dashboard, 
    admin: true, 
    roles: ['superAdmin'] 
  },
  { 
    path: "/area-starts", 
    name: "Area Starts", 
    icon: Room, 
    component: AreaStartsTable, 
    admin: true,
    roles: ['superAdmin']
  },
  { 
    path: "/user-admin", 
    name: "User Admin", 
    icon: User, 
    component: UsersTable, 
    admin: true,
    roles: ['superAdmin', 'corporateAdmin', 'userAdmin', 'userReader'] 
  },
  {
    path: "/template-admin",
    name: "Templates Admin",
    icon: Map,
    component: TemplateGroupsPage,
    admin: true,
    roles: ['superAdmin', 'corporateAdmin', 'templateAdmin', 'templateMaintainer', 'templateReader']
  },
  {
    path: "/message-admin",
    name: "Messages Admin",
    icon: Message,
    component: MessagesPage,
    admin: true,
    roles: ['superAdmin', 'corporateAdmin', 'messageAdmin', 'messageReader']
  },
  {
    path: "/audit-report",
    name: "Audit Reports",
    icon: Description,
    component: AuditLogsTable,
    admin: true,
    roles: ['superAdmin', 'corporateAdmin', 'auditReporter']
  },
  {
    path: "/impact-report",
    name: "Impact Reports",
    icon: Description,
    component: ImpactLogsTable,
    admin: true,
    roles: ['superAdmin', 'corporateAdmin', 'inventoryImpactReporter']
  },
  { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
];

export default dashRoutes;
