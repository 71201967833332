/* eslint-disable */
import * as moment from "moment-timezone";
import { orderBy } from "lodash";

function determineMonthsAsIntegers(months) {

  let monthIntegers = months.map((month) => {
    switch (month) {
      case 'January':
        return 0;
      case 'February':
        return 1;
      case 'March':
        return 2;
      case 'April':
        return 3;
      case 'May':
        return 4;
      case 'June':
        return 5;
      case 'July':
        return 6;
      case 'August':
        return 7;
      case 'September':
        return 8;
      case 'October':
        return 9;
      case 'November':
        return 10;
      case 'December':
        return 11;
      default:
        return;
    }
  });

  return monthIntegers;
}

function determineMonthStringFromInteger(month) {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ];

  return months[month];
}

export function convertTimeStructureToDate(timeStructure, timezone = null) {
  let monthRange = timeStructure.fields["MONTH_RANGE"];
  let dayRange = timeStructure.fields["DAY_RANGE"];
  let timeRange = timeStructure.fields["TIME_RANGE"];

  let year = monthRange.year;
  let months = monthRange.subtype == 'ALL_MONTHS' ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] : determineMonthsAsIntegers(monthRange.months).sort();
  let days =  dayRange.subtype == 'ALL_DAYS' ? [0] : dayRange.days.sort();
  let startTime = timeRange.startTime;
  let endTime = timeRange.endTime == 1439 ? 1440 : timeRange.endTime;

  let mStartDate = timezone ? moment.tz([year, months[0], 1], timezone)  :  moment.utc([year, months[0], 1]);
  let mEndDate = timezone ? moment.tz([year, months[months.length - 1], 1], timezone) : moment.utc([year, months[months.length - 1], 1]);

  if (days[0] == 0) {
    mEndDate = mEndDate.add(1, 'month').subtract(1, 'day');
  } else {
    mStartDate.date(days[0]);
    mEndDate.date(days[days.length -1]);
  }

  if (endTime === 1440) {
    mStartDate = mStartDate.add(startTime, 'minutes');
    mEndDate = mEndDate.add(1, 'day');
  } else {
    mStartDate = mStartDate.add(startTime, 'minutes');
    mEndDate = mEndDate.add(endTime, 'minutes');
  }

  return { start: mStartDate, end: mEndDate };
}

export function determinePeriodRange(restrictedPeriods, timezone) {
  let periodRanges = restrictedPeriods.values.map(period => {
    return convertTimeStructureToDate(period, timezone);
  });
  
  periodRanges = orderBy(periodRanges, ['start']);

  return { start: periodRanges[0].start, end: periodRanges[periodRanges.length - 1].end };
}

export function convertAppliesToRestrictedPeriodStructure(applies) {
  let timeStructures = applies.map(apply => {
    return { 
      fields: apply,
      type: 'Structure'
    }
  });

  return {
    values: timeStructures,
    type: 'Array'
  }
}

export function convertAppliesToPeriodRange(applies, timezone) {
  let restrictedPeriods = convertAppliesToRestrictedPeriodStructure(applies);
  return determinePeriodRange(restrictedPeriods, timezone);
}

export function convertTimeStructuresToApplies(timeStructures) {
  let applies = timeStructures.map(period => period.fields);

  return applies;
}
export function convertRestrictedPeriodStructureToApplies(restrictedPeriods) {
  let applies = convertTimeStructuresToApplies(restrictedPeriods.values);

  return applies;
}

function monthRangeStructure(months, year) {  
  return {
    type: "MonthRange",
    subtype: "SPECIFIC_MONTHS_AND_YEAR",
    months,
    year  
  }
}

function dayRangeStructure(days) {
 return {
    type: "DayRange",
    subtype: days[0] == 0 ? "ALL_DAYS" : "SPECIFIC_DAYS",
    weekDay: [],
    weekDayOfMonth:[],
    days: days[0] == 0 ? [] : days,
    specialInclusions: "",
    specialExclusions: ""
  }
}

function timeRangeStructure(start, end) {
  return {
    type: "TimeRange",
    subtype: "STANDARD_RANGE",
    startTime: start,
    endTime: end == 1440 ? 1439 : end
  }
}

export function convertFromUTCToTimezone(mDate, timezone) {
  return moment.tz([mDate.year(), mDate.month(), mDate.date(), mDate.hour(), mDate.minute()], timezone);
}

export function determineTimeStructuresForDateRange(mStartDate, mEndDate) {
  let duration = moment.duration(mEndDate.diff(mStartDate));
  
  let mStartOfDay = mStartDate.clone().hour(0).minute(0).second(0);
  let mStartOfNextDay = moment.duration(mStartDate.clone().hour(24).minute(0).second(0).diff(mStartDate));
  let mStartOfEndDay = moment.duration(mEndDate.diff(mEndDate.clone().hour(0).minute(0).second(0)));

  let minutesInDaysBetween = duration.asMinutes() - mStartOfNextDay.asMinutes() - mStartOfEndDay.asMinutes();
  
  let timeStructures = [];
  
  // same day
  if (minutesInDaysBetween < 0) { 
    timeStructures.push({
      type: "Structure",
      fields: {
        "MONTH_RANGE": monthRangeStructure([determineMonthStringFromInteger(mStartOfDay.month())], mStartOfDay.year()),
        "DAY_RANGE": dayRangeStructure([mStartOfDay.date()]),
        "TIME_RANGE": timeRangeStructure(moment.duration(mStartDate.diff(mStartOfDay)).asMinutes(), moment.duration(mEndDate.diff(mStartOfDay)).asMinutes())
      }
    });
  } else {
    if (moment.duration(mStartDate.diff(mStartOfDay)).asMinutes() != 0) {
      timeStructures.push({
        type: "Structure",
        fields: {
          "MONTH_RANGE": monthRangeStructure([determineMonthStringFromInteger(mStartOfDay.month())], mStartOfDay.year()),
          "DAY_RANGE": dayRangeStructure([mStartOfDay.date()]),
          "TIME_RANGE": timeRangeStructure(moment.duration(mStartDate.diff(mStartOfDay)).asMinutes(), 1439)
        },
        startDayNumber: 0
      });
    }
      let intervalDict = {};
      let interval = timeStructures.length == 0 ? mStartOfDay : mStartOfDay.clone().hour(24);
      let endMarker = mEndDate.clone().hour(0).minute(0).second(0).subtract(1, 'second');
      while (!interval.isAfter(endMarker)) {
        if (!intervalDict[interval.year()]) intervalDict[interval.year()] = {};
        if (!intervalDict[interval.year()][interval.month()]) intervalDict[interval.year()][interval.month()] = { days: [] };
        intervalDict[interval.year()][interval.month()].days.push(interval.date())
        interval = interval.add(1, 'day');
      }

      Object.keys(intervalDict).forEach(year => {
        let months = intervalDict[year];
        Object.keys(months).forEach(monthIndex => {
          let month = months[monthIndex];
          if ((month['days'][0] === 1) && (month['days'][month['days'].length - 1] == moment([Number(year), Number(monthIndex)]).add(1, 'month').subtract(1, 'day').date())) {
            month['allMonth'] = true;
          }
        });
      });
      
      Object.keys(intervalDict).forEach(year => {
        let months = intervalDict[year];
        let fullMonths = [];
        let partMonths = [];

        Object.keys(months).forEach(monthIndex => {
          let month = months[monthIndex];
          if (month['allMonth']) {
            fullMonths.push(monthIndex);
          } else {
            partMonths.push(monthIndex);
          }
        });

        partMonths.forEach(monthIndex => {
          let month = months[monthIndex];
          let startDayCalc = moment([Number(year), Number(monthIndex), month['days'][0]]);
          timeStructures.push({
            type: "Structure",
            fields: {
              "MONTH_RANGE": monthRangeStructure([determineMonthStringFromInteger(Number(monthIndex))], Number(year)),
              "DAY_RANGE": dayRangeStructure(month['days']),
              "TIME_RANGE": timeRangeStructure(0, 1439)
            },
            startDayNumber: moment.duration(startDayCalc.diff(mStartOfDay)).asMinutes()
          });
        });

        if (fullMonths.length > 0) {
          let startDayCalc = moment([Number(year), Number(fullMonths[0]), 1]);
          timeStructures.push({
            type: "Structure",
            fields: {
              "MONTH_RANGE": monthRangeStructure(fullMonths.map(e => determineMonthStringFromInteger(Number(e))), Number(year)),
              "DAY_RANGE": dayRangeStructure([0]),
              "TIME_RANGE": timeRangeStructure(0, 1439)
            },
            startDayNumber: moment.duration(startDayCalc.diff(mStartOfDay)).asMinutes()
          });
        }
      });

      if (moment.duration(mEndDate.diff(mStartOfEndDay)).asMinutes() != 0) {
        if (mStartOfEndDay.asMinutes() !== 0) {
          timeStructures.push({
            type: "Structure",
            fields: {
              "MONTH_RANGE": monthRangeStructure([determineMonthStringFromInteger(mEndDate.month())], mEndDate.year()),
              "DAY_RANGE": dayRangeStructure([mEndDate.date()]),
              "TIME_RANGE": timeRangeStructure(0, mStartOfEndDay.asMinutes())
            },
            startDayNumber: moment.duration(mEndDate.diff(mStartOfDay)).asMinutes()
          });  
        }
      }

      // need to order timeStructures by day and delete order control variable
      timeStructures = orderBy(timeStructures, ['startDayNumber']);
      timeStructures.map(period => {
        delete period.startDayNumber;
        return period;
      });

  }

  let restrictedPeriods = {
    values: timeStructures,
    type: "Array"
  };
  
  return restrictedPeriods
}