/* eslint-disable */
import { userConstants } from '../constants';
import { userService } from '../services';
import { alertActions } from '.';
import { history } from '../helpers';

/*
 * user-related actions
 */
export const userActions = {
    login,
    logout,
    getAll,
    checkForExistingEmail,
    addNewUser,
    updateUser,
    registerUser,
    passwordReset,
    switchEnabledStatus,
    addNewUserRole,
    updateUserRole,
    deleteUserRole,
    getUserRole,
    getAssignableRoles,
    checkJurisdictionScope,
    checkJurisdictionDefaults
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                    dispatch(checkJurisdictionDefaults(JSON.parse(localStorage.getItem("user.jurisdiction")).id));
                    history.push('/dashboard');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
  return dispatch => {
    userService.logout();
    dispatch({ type: userConstants.LOGOUT });
  }
}

function getAll(jurisdictionId) {
    return dispatch => {
        dispatch(request());

        userService.getAll(jurisdictionId)
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

function checkForExistingEmail(email) {
  return dispatch => {
    dispatch(request(email));

    userService.checkForExistingEmail(email)
      .then(
        status => dispatch(success(status)),
        error => dispatch(failure(error))
      );
  };

  function request(email) { return { type: userConstants.CHECK_EMAIL_REQUEST, email } }
  function success(status) { return { type: userConstants.CHECK_EMAIL_SUCCESS, status } }
  function failure(error) { return { type: userConstants.CHECK_EMAIL_FAILURE, error } } 
}

function addNewUser(user) {
  return dispatch => {
    dispatch(request(user));

    userService.addNewUser(user)
      .then(
        user => {
          dispatch(success(user));
          dispatch(alertActions.success("User has been successfully created."))
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error("There was an error creating the user. Try again."))
        }
      );
  };

  function request(user) { return { type: userConstants.ADD_NEW_USER_REQUEST, user } }
  function success(user) { return { type: userConstants.ADD_NEW_USER_SUCCESS, user } }
  function failure(error) { return { type: userConstants.ADD_NEW_USER_FAILURE, error } }
}

function updateUser(user) {
  return dispatch => {
    dispatch(request(user));

    userService.updateUser(user)
      .then(
        user => {
          dispatch(success(user));
          dispatch(alertActions.success("User has been successfully updated."))
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error("There was an error updating the user. Try again."))
        }
      );
  };

  function request(user) { return { type: userConstants.UPDATE_USER_REQUEST, user } }
  function success(user) { return { type: userConstants.UPDATE_USER_SUCCESS, user } }
  function failure(error) { return { type: userConstants.UPDATE_USER_FAILURE, error } }
}

function registerUser(user) {
  return dispatch => {
    dispatch({
      type: userConstants.REGISTER_USER,
      user
    });
  };
}

function passwordReset(email, forSelf = false) {
  return dispatch => {
    dispatch(request(email));

    userService.passwordReset(email)
      .then(
        info => {
          dispatch(success(info));
          dispatch(alertActions.success(`Password reset request has been successfully created for ${email}. ${forSelf ? 'You' : 'The user'} will be sent an email with further instructions.`));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(`Password reset request could not be completed.  Please check email address and try again.`))
        }
      );
  };

  function request(email) { return { type: userConstants.PASSWORD_RESET_REQUEST, email } }
  function success(email) { return { type: userConstants.PASSWORD_RESET_SUCCESS, email } }
  function failure(error) { return { type: userConstants.PASSWORD_RESET_FAILURE, error } }
}

function switchEnabledStatus(userId, enabledStatus) {
  return dispatch => {
    dispatch(request(userId));

    userService.switchEnabledStatus(userId, enabledStatus)
      .then(
        ({ user }) => {
          dispatch(success(user));
          dispatch(alertActions.success(`User ${user.email} has been successfully set with ${user.enabled ? 'en' : 'dis'}abled status.`));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(`Switching enabled status could not be completed.  Please try again.`))
        }
      );
  };

  function request(userId) { return { type: userConstants.SWITCH_ENABLED_STATUS_REQUEST, userId } }
  function success(user) { return { type: userConstants.SWITCH_ENABLED_STATUS_SUCCESS, user } }
  function failure(error) { return { type: userConstants.SWITCH_ENABLED_STATUS_FAILURE, error } }
}

function addNewUserRole(userId, userRole, roleParameters) {
  return dispatch => {
    dispatch(request({ userId, userRole }));

    userService.addNewUserRole(userId, userRole, roleParameters)
      .then(
        ({ userRole }) => {
          dispatch(success(userRole));
          dispatch(alertActions.success(`User role ${userRole.userRole} has been successfully added.`));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(`Adding the user role could not be completed at this time.  Please try again later.`))
        }
      );
  };

  function request(userRoleInfo) { return { type: userConstants.ADD_NEW_USER_ROLE_REQUEST, userRoleInfo } }
  function success(userRole) { return { type: userConstants.ADD_NEW_USER_ROLE_SUCCESS, userRole } }
  function failure(error) { return { type: userConstants.ADD_NEW_USER_ROLE_FAILURE, error } }
}

function updateUserRole(id, userRole, roleParameters) {
  return dispatch => {
    dispatch(request(id));

    userService.updateUserRole(id, userRole, roleParameters)
      .then(
        ({ userRole }) => {
          dispatch(success(userRole));
          dispatch(alertActions.success(`User role has been successfully updated.`));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(`Updating the user role could not be completed at this time.  Please try again later.`))
        }
      );
  };

  function request(id) { return { type: userConstants.UPDATE_USER_ROLE_REQUEST, id } }
  function success(userRole) { return { type: userConstants.UPDATE_USER_ROLE_SUCCESS, userRole } }
  function failure(error) { return { type: userConstants.UPDATE_USER_ROLE_FAILURE, error } }
}


function deleteUserRole(id) {
  return dispatch => {
    dispatch(request(id));

    userService.deleteUserRole(id)
      .then(
        ({ userRole }) => {
          dispatch(success(userRole));
          dispatch(alertActions.success(`User role has been successfully deleted.`));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(`Deleting the user role could not be completed at this time.  Please try again later.`))
        }
      );
  };

  function request(id) { return { type: userConstants.DELETE_USER_ROLE_REQUEST, id } }
  function success(userRole) { return { type: userConstants.DELETE_USER_ROLE_SUCCESS, userRole } }
  function failure(error) { return { type: userConstants.DELETE_USER_ROLE_FAILURE, error } }
}

function getUserRole(id) {
  return dispatch => {
    dispatch(request(id));

    userService.getUserRole(id)
      .then(
        ({ userRole }) => {
          dispatch(success(userRole));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(`Retrieving the user role could not be completed at this time.  Please try again later.`))
        }
      );
  };

  function request(id) { return { type: userConstants.GET_USER_ROLE_REQUEST, id } }
  function success(userRole) { return { type: userConstants.GET_USER_ROLE_SUCCESS, userRole } }
  function failure(error) { return { type: userConstants.GET_USER_ROLE_FAILURE, error } }
}

function getUserRoles(userId) {
  return dispatch => {
    dispatch(request(userId));

    userService.getUserRoles(userId)
      .then(
        ({ userRoles }) => {
          dispatch(success(userRoles));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(`Retrieving the user roles could not be completed at this time.  Please try again later.`))
        }
      );
  };

  function request(userId) { return { type: userConstants.GET_USER_ROLES_REQUEST, userId } }
  function success(userRoles) { return { type: userConstants.GET_USER_ROLES_SUCCESS, userId, userRoles } }
  function failure(error) { return { type: userConstants.GET_USER_ROLES_FAILURE, error } }
}

function getAssignableRoles() {
  return dispatch => {
    dispatch(request());

    userService.getAssignableRoles()
      .then(
        ({ assignableRoles }) => {
          dispatch(success(assignableRoles));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(`Retrieving assignable roles could not be completed at this time.  Please try again later.`))
        }
      );
  };

  function request() { return { type: userConstants.GET_ASSIGNABLE_ROLES_REQUEST } }
  function success(assignableRoles) { return { type: userConstants.GET_ASSIGNABLE_ROLES_SUCCESS, assignableRoles } }
  function failure(error) { return { type: userConstants.GET_ASSIGNABLE_ROLES_FAILURE, error } }
}

function checkJurisdictionScope(userId) {
  return dispatch => {
    dispatch(request(userId));

    userService.checkJurisdictionScope(userId)
      .then(
        ({ jurisdictionScope }) => {
          dispatch(success(jurisdictionScope));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(`Checking jurisdiction scope could not be completed at this time.  Please try again later.`))
        }
      );
  };

  function request(userId) { return { type: userConstants.CHECK_JURISDICTION_SCOPE_REQUEST, userId } }
  function success(jurisdictionScope) { return { type: userConstants.CHECK_JURISDICTION_SCOPE_SUCCESS, userId, jurisdictionScope } }
  function failure(error) { return { type: userConstants.CHECK_JURISDICTION_SCOPE_FAILURE, error } }
}

function checkJurisdictionDefaults(jurisdictionId) {
  return dispatch => {
    dispatch(request(jurisdictionId));

    userService.checkJurisdictionDefaults(jurisdictionId)
      .then(
        (jurisdictionDefaults) => {
          dispatch(success(jurisdictionDefaults));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(`Checking jurisdiction defaults could not be completed at this time.  Please try again later.`))
        }
      );
  };

  function request(jurisdictionId) { return { type: userConstants.CHECK_JURISDICTION_DEFAULTS_REQUEST, jurisdictionId } }
  function success(jurisdictionDefaults) { return { type: userConstants.CHECK_JURISDICTION_DEFAULTS_SUCCESS, jurisdictionDefaults } }
  function failure(error) { return { type: userConstants.CHECK_JURISDICTION_DEFAULTS_FAILURE, error } }
}