import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { differenceWith, isEqual, sortBy } from "lodash";

// custom components
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from "components/CustomButtons/Button.jsx";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
});


class RoleSelector extends React.Component {
  constructor(props) {
    super(props);

    let { assignableRoles, currentRoles } = props;

    const existingRoles = currentRoles.map(e => {
      return {
        userRole: e.userRole,
        roleParameters: e.roleParameters
      };
    })

    let availableRoles = sortBy(differenceWith(assignableRoles, existingRoles, isEqual), ['userRole']);

    this.state = {
      initialValuesLoaded: false,
      availableRoles
    }

    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleRoleSelected = this.handleRoleSelected.bind(this);
  }

  componentWillMount() {
    const { initialValuesLoaded, availableRoles } = this.state;

    if (initialValuesLoaded) return;

    let newState = {};
    if (availableRoles.length > 0) {
      newState["roleSelected"] = 0;
      newState["selectableRoles"] = availableRoles.map((e, index) => {
        let tempLabel = e.userRole.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");
        return { label: tempLabel.charAt(0).toUpperCase() + tempLabel.slice(1), value: index };
      });
    }

    if (Object.keys(newState).length > 0) {
      this.setState({
        ...newState,
        initialValuesLoaded: true
      });  
    }
  }

  handleCancel() {
    const { onCancel } = this.props;

    onCancel();
  }

  handleConfirm() {
    const { onConfirm } = this.props;
    const { roleSelected, availableRoles } = this.state;

    onConfirm(availableRoles[roleSelected]);
  }

  handleRoleSelected(e) {
    const { roleSelected } = this.state;
      
    const newRole = e.target.value;

    if (roleSelected !== newRole) {
      let newState = {};

      newState["roleSelected"] = newRole;
      this.setState(newState);
    }
  }
 
  render() {

    const { selectableRoles, roleSelected } = this.state;
    const { classes, title } = this.props;

    return (
      <Modal open={true} onClose={this.handleCancel}>
      <GridContainer style={getModalStyle()} className={classes.paper}>
        <GridItem xs={12}>
          <h3>{title}</h3>
        </GridItem>
        <form onSubmit={this.handleConfirm}>
          <GridItem xs={12}>
          <FormControl style={{minWidth: '300px', marginTop: '20px' }}>
          <InputLabel htmlFor="role">ROLE</InputLabel>
          <Select
            id="role"
            value={roleSelected}
            onChange={this.handleRoleSelected}
          >
            {selectableRoles.map( (item, key) => {
              return (<MenuItem value={item.value} key={key}>{item.label}</MenuItem>);
            })}
          </Select>
          </FormControl>
          </GridItem>
          <GridItem xs={12} style={{textAlign: 'center', marginTop: '30px'}}>
            <Button color="danger" onClick={(e) => this.handleCancel(e)} >Cancel</Button>
            <Button color="success" style={{marginLeft: '10px'}} onClick={(e) => this.handleConfirm(e)} >Confirm</Button>
          </GridItem>
      </form>
      </GridContainer>
      </Modal>
    );
  }
}


RoleSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  assignableRoles: PropTypes.array.isRequired,
  currentRoles: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired 
};

export default withStyles(styles)(RoleSelector);