/* eslint-disable */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Room from "@material-ui/icons/Room";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { areaActions } from "actions";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  headlineAction: {
    marginRight: "10px"
  },
  headline: {
    contentAlign: "right"
  }
};

Number.prototype.pad = function(size) {
  var sign = Math.sign(this) === -1 ? '-' : '';
  return sign + new Array(size).concat([Math.abs(this)]).join('0').slice(-size);
}


class ReactTables extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleRefresh = this.handleRefresh.bind(this);
  }

  componentWillMount() {
    const { dispatch, areas } = this.props;

    if (areas === undefined) {
      dispatch(areaActions.getAll())
    }
  }

  handleRefresh() {
    const { dispatch } = this.props;

    dispatch(areaActions.getAll());
  }

  render() {
    const { classes, areas, loading } = this.props;
    
    if (loading) return (
      <h2>Loading...</h2>
    );

    return (
      <GridContainer>
        <GridItem xs={10}/>
        <GridItem xs={2} className={classes.headline}><Button color="info" className={classes.headlineAction} onClick={() => this.handleRefresh()}>Refresh</Button></GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Room />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Area Starts</h4>
            </CardHeader>
            <CardBody>

              <ReactTable
                data={areas}
                filterable
                defaultFilterMethod={(filter, row) => String(row[filter.id]).substring(0, filter.value.length).toLowerCase() === filter.value.toLowerCase()}
                columns={[
                  {
                    Header: "ID",
                    accessor: "id",
                    minWidth: 120,
                    maxWidth: 120
                  },
                  { 
                    Header: "Street",
                    accessor: "route.shortName",
                  },
                  {
                    Header: "Locality",
                    accessor: "locality.shortName"
                  },
                  {
                    Header: "Collection Time",
                    id: "collectionTime",
                    accessor: area => {
                      let date = new Date(Date.parse(area.collectionTime));
                      return `${date.getFullYear()}-${(date.getMonth()+1).pad(2)}-${date.getDate().pad(2)} ${date.toLocaleTimeString('en-US')}`;
                    },
                    minWidth: 160,
                    maxWidth: 180
                  },
                  {
                    Header: "Collector Id",
                    accessor: "userId",
                    minWidth: 120,
                    maxWidth: 120
                  },
                  {
                    Header: "Status",
                    accessor: "status",
                    maxWidth: 160
                  },
                  {
                    Header: "Actions",
                    sortable: false,
                    headerStyle: { textAlign: 'left' },
                    Cell: row => (
                      <Button color="primary" size="sm" onClick={() => { window.open(`https://dit.spotparking.com.au/interpret?as=${row.row.id}`, '_blank'); }}>
                       EDIT
                    </Button>
                    )
                  }
                ]}
                defaultPageSize={100}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  const { areas } = state;

  return {
    areas: areas.areas,
    loading: areas.loading
  };
}

export default connect(mapStateToProps)(withStyles(styles)(ReactTables));

