import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// custom components
import Modal from '@material-ui/core/Modal';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 100,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  progress: {
    margin: theme.spacing.unit * 2
  }
});


class ConflictDisplay extends React.Component {
  
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    const { closeFunction } = this.props;

    if (closeFunction) closeFunction();
  };

  render() {
    const { classes, conflictDetails, open } = this.props;

    return (
      <Modal open={open} onClose={this.handleClose}>
      <GridContainer style={getModalStyle()} className={classes.paper}>
        <GridItem xs={12} style={{textAlign: 'center'}}>{conflictDetails}</GridItem>
      </GridContainer>
      </Modal>
    );
  }
}


ConflictDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
  conflictDetails: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  closeFunction: PropTypes.func.isRequired
};

export default withStyles(styles)(ConflictDisplay);