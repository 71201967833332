
let config = {
  apiUrl: "https://data-collection-api.spotparking.com.au"
}

switch (process.env.REACT_APP_API_OVERRIDE) {
  case 'demo':
    config.apiUrl = "https://data-collection-api-demo.spotparking.com.au";
    break;

  case 'demo-usa':
      config.apiUrl = "https://data-collection-api-demo-usa.spotparking.com.au";
      break;
  
  default:
    break;
}

export default config;