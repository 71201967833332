import React from "react";
import { connect } from "react-redux";
import { intersection, sortBy, values } from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material-ui icons
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import Message from "@material-ui/icons/Message";
import Publish from "@material-ui/icons/Publish";
import HighlightOff from "@material-ui/icons/HighlightOff";
import ViewHeadline from "@material-ui/icons/ViewHeadline";
import Refresh from "@material-ui/icons/Refresh";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Tooltip from "@material-ui/core/Tooltip";

import templateGroupsStyle from "assets/jss/spot-admin/views/templateGroupsStyle.jsx";

import { messageActions, alertActions } from "actions";


class MessagesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prompt: null,
      snackBarOpen: false,
      snackBarStatus: "success",
      snackBarMessage: ""
    };

    this.showNotification = this.showNotification.bind(this);
    this.removePrompt = this.removePrompt.bind(this);
    
    this.addMessage = this.addMessage.bind(this);
    this.editMessage = this.editMessage.bind(this);
    this.viewMessage = this.viewMessage.bind(this);
    this.deleteMessage = this.deleteMessage.bind(this);
    this.publishMessage = this.publishMessage.bind(this);
    this.unpublishMessage = this.unpublishMessage.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);

    this.promptDeleteMessage = this.promptDeleteMessage.bind(this);
    this.promptPublishMessage = this.promptPublishMessage.bind(this);
    this.promptUnpublishMessage = this.promptUnpublishMessage.bind(this);
  }

  componentWillMount() {
    const { dispatch, messages } = this.props;
    let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

    if (Object.keys(messages).length === 0) {
      dispatch(messageActions.getMessages(jurisdiction.id));
    } 
  }

  componentWillUnmount() {
    if (this.notificationTimer) {
      clearTimeout(this.notificationTimer);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dispatch, alert } = this.props;

    if (alert.type) {
      this.showNotification(alert.type, alert.message);
      dispatch(alertActions.clear())
    }
  }

  handleRefresh() {
    const { dispatch } = this.props;
    let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

    dispatch(messageActions.getMessages(jurisdiction.id));
  }

  removePrompt() {
    this.setState({
      prompt: null
    });
  }

  publishMessage(messageId) {
    const { messages, dispatch } = this.props;

    let message = messages[messageId];
    message.id = messageId;
    message.isActive = true;

    dispatch(messageActions.updateMessage(message, 'published'));
    this.removePrompt();
  }

  unpublishMessage(messageId) {
    const { messages, dispatch } = this.props;

    let message = messages[messageId];
    message.id = messageId;
    message.isActive = false;

    dispatch(messageActions.updateMessage(message, 'unpublished'));
    this.removePrompt();
  }


  addMessage(event) {
    const { history } = this.props;

    event.stopPropagation();

    history.push('/message-admin/message/new-message');
  }

  editMessage(event, messageId) {
    const { history } = this.props;

    event.stopPropagation();

    history.push(`/message-admin/message/edit-message/${messageId}`);
  }

  viewMessage(event, messageId) {
    const { history } = this.props;

    event.stopPropagation();

    history.push(`/message-admin/message/view-message/${messageId}`);
  }

  promptPublishMessage(event, messageId) {
    const { classes } = this.props;

    event.stopPropagation();

    this.setState({
      prompt: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Warning!"
          onConfirm={() => this.publishMessage(messageId)}
          onCancel={() => this.removePrompt()}
          confirmBtnCssClass={
            classes.button + " " + classes.success
          }
          cancelBtnCssClass={
            classes.button + " " + classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        >
        You are about to publish this message!  Are you sure?
        </SweetAlert>
      )
    });  
  }

  promptUnpublishMessage(event, messageId) {
    const { classes } = this.props;

    event.stopPropagation();

    this.setState({
      prompt: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Warning!"
          onConfirm={() => this.unpublishMessage(messageId)}
          onCancel={() => this.removePrompt()}
          confirmBtnCssClass={
            classes.button + " " + classes.success
          }
          cancelBtnCssClass={
            classes.button + " " + classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        >
        You are about to unpublish this message!  Are you sure?
        </SweetAlert>
      )
    });  
  }

  promptDeleteMessage(event, messageId) {
    const { classes } = this.props;

    event.stopPropagation();

    this.setState({
      prompt: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Warning!"
          onConfirm={() => this.deleteMessage(messageId)}
          onCancel={() => this.removePrompt()}
          confirmBtnCssClass={
            classes.button + " " + classes.success
          }
          cancelBtnCssClass={
            classes.button + " " + classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        >
        Deleting this message cannot be undone! Do you want to continue?
        </SweetAlert>
      )
    });  
  }

  deleteMessage(messageId) {
    const { dispatch } = this.props;

    dispatch(messageActions.deleteMessage(messageId));
    this.removePrompt();
  }

  showNotification(status, message) {
    if (!this.state.snackBarOpen) {
      this.setState({ 
        snackBarOpen: true,
        snackBarStatus: status,
        snackBarMessage: message 
      });
      this.notificationTimer = setTimeout(
        function() {
          this.setState({
            snackBarOpen: false
          });
          this.notificationTimer = null;
        }.bind(this),
        3000
      );
    }
  }

  renderMessageButtons(messageId) {

    const { classes } = this.props;

    const buttons = [
      { task: "editMessage", color: "success", icon: Edit, title: "Edit Message" },
      { task: "deleteMessage", color: "danger", icon: Delete, title: "Delete Message" }
    ];

    return (
      <div>
        {buttons.map((button, key) => {
          return (<Button color={button.color} key={key} style={{ marginRight: '10px'}} onClick={(e) => this[button.task](e, messageId)}>
            <button.icon className={classes.icon} />{button.title}
          </Button>);
        })}
      </div>
    )
  }

  renderActionButtons(messageId) {
    const { messages, readOnlyMode, classes } = this.props;
    
    let actionButtons = [
      { task: "viewMessage", color: "success", icon: ViewHeadline, displayIfReadOnlyMode: true, tooltip: "View This Message" },
      { task: "editMessage", color: "rose", icon: Edit, displayIfReadOnlyMode: false, tooltip: "Edit Message" },
      { task: "promptPublishMessage", color: "warning", icon: Publish, displayIfReadOnlyMode: false, displayIfActive: false, displayIfEverPublished: true, disableIfPublishing: true, tooltip: 'Publish This Message'},
      { task: "promptUnpublishMessage", color: "danger", icon: HighlightOff, displayIfReadOnlyMode: false, displayIfActive: true, displayIfEverPublished: true, disableIfPublishing: true, tooltip: 'Unpublish This Message'},
      { task: "promptDeleteMessage", color: "danger", icon: Delete, displayIfReadOnlyMode: false, displayIfActive: false, tooltip: "Delete This Message" }
    ];
    
    let message = messages[messageId];

    actionButtons = actionButtons.filter(e => {
      if ((readOnlyMode) && (e.displayIfReadOnlyMode !== undefined) && (e.displayIfReadOnlyMode === false)) return false;
      return true;
    });

    actionButtons = actionButtons.map(actionButton => {
      // if ((actionButton.displayIfReadOnlyMode !== undefined) && (actionButton.displayIfReadOnlyMode === false)) {
      //   return undefined;
      // }
      if ((actionButton.displayIfActive !== undefined) && (actionButton.displayIfActive === false )) {
        if (message.isActive) return undefined;
      }
      if ((actionButton.displayIfActive !== undefined) && (actionButton.displayIfActive === true )) {
        if (!message.isActive) return undefined;
      }
      return actionButton
    }).filter(e => e !== undefined);

    return (
      <div>
        {actionButtons.map((button, key) => {
          return (
            <Tooltip title={button.tooltip}>
              <Button key={key} color={button.color} className={classes.actionButton} onClick={(e) => this[button.task](e, messageId)}>
                <button.icon className={classes.icon} />{''}
              </Button>
            </Tooltip>
          );
        })}
      </div>
    );
  }

  render() {
    const { classes, messages, readOnlyMode, loading } = this.props;
    const { prompt, snackBarStatus, snackBarOpen, snackBarMessage } = this.state;

    if ((messages === undefined) || (loading)) return (
      <h2>Loading...</h2>
    );

    let messageIds = [];
    if (messages) {
      let existingMessages = Object.keys(messages).map(messageId => {
        return { id: messageId, ...messages[messageId]};
      });

      let sortedMessages = sortBy(values(existingMessages), ['heading']);
      messageIds = sortedMessages.map(e => e.id);
    }

    return (
      <GridContainer>
      <GridItem xs={6}><h3>Available Messages</h3></GridItem>
      <GridItem xs={6}>
        {readOnlyMode || <Tooltip title="Add A New Message">
          <Button color="success" style={{ float: 'right', paddingRight: '20px' }} onClick={(e) => this.addMessage(e)}>
            <Add className={classes.icon} />Add Message
        </Button>
        </Tooltip>}
        <Tooltip title="Refresh message list">
          <Button color="info" style={{ float: 'right', marginRight: '10px' }} onClick={() => this.handleRefresh()}>
            <Refresh className={classes.icon} />Refresh
          </Button>
        </Tooltip>
      </GridItem>
      <GridItem xs={12}>
        {prompt}
        <Snackbar
          place="tr"
          color={snackBarStatus}
          message={snackBarMessage}
          open={snackBarOpen}
          closeNotification={() => this.setState({ snackBarOpen: false })}
          close
        />
        {!messageIds.length && <h4>No messages currently available.</h4>}
        {messageIds.length > 0 && (
          <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Message />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Messages</h4>
              </CardHeader>
              <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <Table style={{ width: '100%'}}
                    hover
                    striped
                    tableHead={[
                    "Heading",
                    "Type",
                    "Status",
                    "Actions"
                ]}
                tableData={
                  messageIds.map(messageId => {
                    let message = messages[messageId];
                    return [
                      message.heading,
                      message.type.replace(/[_]/g, ' '),
                      message.isActive ? 'Active' : 'Not Active',
                      this.renderActionButtons(messageId)
                    ]
                  })
                }
                customCellClasses={[
                  classes.columnDescription,
                  classes.right
                ]}
                customClassesForCells={[0, 3]}
                customHeadCellClasses={[
                  classes.right
                ]}
                customHeadClassesForCells={[3]}
              />
              </GridItem>
      
            </GridContainer>
              </CardBody>
            </Card>
        )}
        </GridItem>
        </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  const { messages, alert } = state;

  let readOnlyMode = false;
  const user = JSON.parse(localStorage.getItem('user'));
  if (user.roles) {
    if (intersection(['superAdmin', 'corporateAdmin', 'messageAdmin'], user.roles.map(o => o.role)).length === 0) readOnlyMode = true;
  }

  if (!messages) {
    return {};
  }

  return {
    messages: messages.messages || {},
    loading: messages.loading || false,
    saving: messages.saving || false,
    deleting: messages.deleting || false,
    readOnlyMode,
    alert: alert || null
  };
}

export default connect(mapStateToProps)(withStyles(templateGroupsStyle)(MessagesList));