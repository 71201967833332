import * as moment from "moment-timezone";

export function timeStampStringInLocale(locale, momentDate) {

  let formatString = "ddd DD/MM/YYYY h:mm a";
  switch (locale) {
    case 'en-US':
      formatString = "ddd MM/DD/YYYY h:mm a";
      break;
    default:
      break;
  }

  return momentDate.format(formatString);
}

export function currentTimestampInTimezone(requestedTimezone) {
  let timeZone = requestedTimezone || 'America/New_York';
  return moment.tz(new Date(), timeZone);
}