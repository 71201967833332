/* eslint-disable */

import React from "react";
import { Field, reduxForm, SubmissionError, reset } from 'redux-form';
import { connect } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import Datetime from "react-datetime";
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';

import * as moment from "moment";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material ui icons
import Style from "@material-ui/icons/Style";
import Create from "@material-ui/icons/Create";
import Cancel from "@material-ui/icons/Cancel";
import Clear from "@material-ui/icons/Clear";
import Warning from "@material-ui/icons/Warning";
import Revert from "@material-ui/icons/GetApp";
import Done from "@material-ui/icons/Done";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";

import { determinePeriodRange, determineTimeStructuresForDateRange, convertRestrictedPeriodStructureToApplies } from "utils/TimeStructures.jsx";
import { convertAppliesToRestrictedPeriodStructure, convertAppliesToPeriodRange, convertFromUTCToTimezone } from "utils/TimeStructures.jsx";


import CustomTextField from "components/redux-form/CustomTextField/CustomTextField.jsx";

// validators
import { stringOfLengthBetween } from "helpers/validation";

// style for this view
import editTemplateGroupFormsStyle from "assets/jss/spot-admin/views/newTemplateGroupStyle.jsx";

import { templateService } from "services";
import { templateActions, userActions, alertActions } from "actions";


const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Name is a required field";
  } else {
    if (!stringOfLengthBetween(values.name, 4, 50)) {
      errors.name = "Name must be between 4 and 50 characters";
    }
  }

  if (!values.description) {
    errors.description = "Description is a required field";
  } else {
    if (!stringOfLengthBetween(values.description, 1, 200)) {
      errors.description = "Description must has a maximum of 200 characters";
    }
  }

  return errors;
}


class EditTemplateInstancePage extends React.Component {

  constructor(props) {
    super(props);

    const { match, templateInstances } = props;

    let editTemplateInstanceId = match.params.id;

    this.state = {
      alert: null,
      snackBarOpen: false,
      snackBarStatus: "success",
      snackBarMessage: "",
      editTemplateInstanceId
    };

    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.finished = this.finished.bind(this);
    this.removeAlert = this.removeAlert.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.handleDateTimeRangeChange = this.handleDateTimeRangeChange.bind(this);
  }

  componentDidMount() {
    const { dispatch, templateInstances, checkingJurisdictionDefaults, jurisdictionDefaults } = this.props;

    let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

    if ((!templateInstances) || (Object.keys(templateInstances).length == 0)) {
      dispatch(templateActions.getTemplateInstances(jurisdiction.id))
    }

    if ((!checkingJurisdictionDefaults) && (Object.keys(jurisdictionDefaults).length == 0)) {
      dispatch(userActions.checkJurisdictionDefaults(jurisdiction.id));
    }
  }

  componentWillUnmount() {
    if (this.notificationTimer) {
      clearTimeout(this.notificationTimer);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { templateInstances, match, checkingJurisdictionDefaults, jurisdictionDefaults } = nextProps;

    let editTemplateInstanceId = match.params.id;

    if (templateInstances) {
      let templateInstance = templateInstances[editTemplateInstanceId];
      if (!templateInstance) {
        return {};
      }

      let timeZone = 'America/New_York';
      if ((!checkingJurisdictionDefaults) && (jurisdictionDefaults['TIMEZONE'])) timeZone = jurisdictionDefaults['TIMEZONE'];
      let dateRange = convertAppliesToPeriodRange(templateInstance.applies, timeZone);

      return {
        editTemplateInstanceId, 
        formInitialValues: {
          name: templateInstance.name,
          description: templateInstance.description["en-US"],
          dateTimeRange: [dateRange.start.toDate(), dateRange.end.toDate()],
          publicDescription: (templateInstance.messageAttributes || {}).publicDescription || "",
          messageHeader: (templateInstance.messageAttributes || {}).messageHeader || "",
          messageDetail: (templateInstance.messageAttributes || {}).messageDetail || ""
        }
      };
    }
    
    return {};
  }

  showNotification(status, message) {
    if (!this.state.snackBarOpen) {
      this.setState({ 
        snackBarOpen: true,
        snackBarStatus: status,
        snackBarMessage: message 
      });
      this.notificationTimer = setTimeout(
        function() {
          this.setState({
            snackBarOpen: false
          });
          this.notificationTimer = null;
        }.bind(this),
        3000
      );
    }
  }

  finished() {
    const { history  } = this.props;

    this.setState({
      alert: null
    });
    history.goBack();
  }

  removeAlert() {
    this.setState({
      alert: null
    });
  }

  showSuccess() {
    const { classes } = this.props;

    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success!"
          onConfirm={() => this.finished()}
          confirmBtnCssClass={
            classes.button + " " + classes.success
          }
          confirmBtnText="Ok"
        >
        The template instance has been updated successfully
        </SweetAlert>
      )
    })
  }

  submit( values ) {

    const { editTemplateInstanceId, dateTimeRange } = this.state;
    const { dispatch, templateInstances } = this.props;

    let user = JSON.parse(localStorage.getItem('user'));
    const previousInstance = templateInstances[editTemplateInstanceId];

    let startDate = moment(dateTimeRange[0]);
    let endDate = moment(dateTimeRange[1]);
    let restrictedPeriods = determineTimeStructuresForDateRange(startDate, endDate);
    let applies = convertRestrictedPeriodStructureToApplies(restrictedPeriods);
    
    let messageAttributes = {};
    if (values.publicDescription) messageAttributes['publicDescription'] = values.publicDescription;
    if (values.messageHeader) messageAttributes['messageHeader'] = values.messageHeader;
    if (values.messageDetail) messageAttributes['messageDetail'] = values.messageDetail;

    let templateInstance = {
      id: editTemplateInstanceId,
      name: values.name,
      description: { "en-US": values.description },
      applies,
      template: previousInstance.template.id,
      published: false,
      lastPublished: null,
      modifiedBy: user.id,
      messageAttributes
    };

    return new Promise((resolve, reject) => {
      templateService.updateTemplateInstance(templateInstance)
      .then(updatedTemplateInstance => {
        dispatch(templateActions.registerTemplateInstance(updatedTemplateInstance));
        resolve(updatedTemplateInstance);
        this.showNotification("success", "Template instance has been successfully updated!");
        this.showSuccess();
      })
      .catch(error => {
        console.error("ERROR updating template instance ", error);
        this.showNotification("danger", "There was a problem updating the template instance!");
        reject(new SubmissionError({ _error: "There was a problem updating the template instance."} ) );
      });
    });

  }

  cancel(pristine) {
    const { classes, history } = this.props;

    if (!pristine) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block" }}
            title="Warning!"
            onConfirm={() => this.finished()}
            onCancel={() => this.removeAlert()}
            confirmBtnCssClass={
              classes.button + " " + classes.success
            }
            cancelBtnCssClass={
              classes.button + " " + classes.danger
            }
            confirmBtnText="Yes"
            cancelBtnText="No"
            showCancel
          >
          Cancelling will discard any changes made.  Are you sure?
          </SweetAlert>
        )
      });  
    } else {
      history.goBack();
    }
  }

  handleDateTimeRangeChange(value) {
    this.setState({ dateTimeRange: value });
  }

  render() {
    const { alert, snackBarOpen, snackBarMessage, snackBarStatus, editTemplateInstanceId, dateTimeRange, formInitialValues } = this.state;
    const { readOnlyMode } = this.props;
    return (
    <div>
      {alert}
      <Snackbar
        place="tr"
        color={snackBarStatus}
        icon={Style}
        message={snackBarMessage}
        open={snackBarOpen}
        closeNotification={() => this.setState({ snackBarOpen: false })}
        close
      />
      <EditTemplateInstanceForm
        readOnlyMode={readOnlyMode}
        editTemplateInstanceId={editTemplateInstanceId}
        formInitialValues={formInitialValues} 
        onSubmit={this.submit} 
        onCancel={this.cancel} 
        dateTimeRange={dateTimeRange}
        onDateTimeRangeChange={this.handleDateTimeRangeChange}
        />
    </div>
    );
  }
}

function mapStateToProps(state) {
  const { templates, users } = state;

  if (!templates) {
    return {};
  }

  return {
    templateInstances: templates.templateInstances || {},
    loading: templates.loading || false,
    saving: templates.saving || false,
    jurisdictionDefaults: users.jurisdictionDefaults || {},
    checkingJurisdictionDefaults: users.checkingJurisdictionDefaults || false
  };
} 

export default connect(mapStateToProps)(withStyles(editTemplateGroupFormsStyle)(EditTemplateInstancePage));


class EditTemplateInstanceForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      initialValuesLoaded: false
    };

    this.handleDateTimeRangeChange = this.handleDateTimeRangeChange.bind(this);
    this.resetFields = this.resetFields.bind(this);
  }

  componentDidUpdate() {
    const { initialize, formInitialValues, onDateTimeRangeChange } = this.props;
    const { initialValuesLoaded } = this.state;

    if ((!initialValuesLoaded) && (formInitialValues)) {

      this.setState({
        dateTimeRange: formInitialValues.dateTimeRange,
        initialValuesLoaded: true
      });

      initialize({ 
        name: formInitialValues.name, 
        description: formInitialValues.description,
        publicDescription: formInitialValues.publicDescription,
        messageHeader: formInitialValues.messageHeader,
        messageDetail: formInitialValues.messageDetail
      });

      onDateTimeRangeChange(formInitialValues.dateTimeRange);
    }
  }

  handleDateTimeRangeChange(newDateTimeRange) {
    const { dispatch, change, onDateTimeRangeChange } = this.props;

    let finalFrom = newDateTimeRange[0];
    let finalTo = newDateTimeRange[1];
    if (finalFrom.getTime() >= finalTo.getTime()) {
      finalTo.setTime(finalFrom.getTime())
      finalTo.setHours(finalTo.getHours() + 1)
    }

    onDateTimeRangeChange([finalFrom, finalTo]);
    dispatch(change("dateTimeRange", [finalFrom, finalTo]));
  }

  renderDateWarnings() {
    const { dateTimeRange } = this.state;

    if (!dateTimeRange) return null;
    if ((!dateTimeRange[0]) || (!dateTimeRange[1])) return null;

    let startDate = dateTimeRange[0];
    let endDate = dateTimeRange[1];
    let currentDate = new Date();

    if (endDate < currentDate) {
      return (
        <div>
          <br/>
          <SnackbarContent
            message={"The Ending date occurs in the past!"}
            color="danger"
            icon={Warning}
          />          
        </div>
      );
    }

    if (startDate < currentDate) {
      return (
        <div>
          <br/>
          <SnackbarContent
            message={"The Starting date occurs in the past!"}
           color="warning"
            icon={Warning}
         />

        </div>
      );      
    }

    return null;
  }

  resetFields() {
    const { reset, onDateTimeRangeChange, formInitialValues } = this.props;
  
    let newState = {
      dateTimeRange: formInitialValues
    };

    this.setState(newState);
    onDateTimeRangeChange(formInitialValues.dateTimeRange);
    reset();
  }

  render() {
    const { classes, handleSubmit, pristine, submitting, readOnlyMode,
      submitFailed, submitSucceeded, onCancel, dateTimeRange } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={9}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="rose">
                <Style />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{`${readOnlyMode ? 'View' : 'Edit'} Template Instance`}</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit} autoComplete="off"> 
              <GridContainer>
                <GridItem xs={12}>
                  <Field 
                    name="name" 
                    component={CustomTextField} 
                    label="NAME *"
                    props={{ fullWidth: true }}
                    disabled={readOnlyMode}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field 
                    name="description" 
                    component={CustomTextField}
                    label="DESCRIPTION *"
                    props={{ fullWidth: true }}
                    disabled={readOnlyMode} 
                  />
                  </GridItem>
                  <GridItem xs={12} style={{marginTop: '10px'}}>
                  Valid Time Range:&nbsp;&nbsp; 
                  <Field
                    name="dateTimeRange"

                    component={DateTimeRangePicker}
                    props={{
                      onChange: this.handleDateTimeRangeChange,
                      value: dateTimeRange,
                      clearIcon: null,
                      disableClock: true,
                      disabled: readOnlyMode
                    }}
                    value={dateTimeRange}
                    />      
              </GridItem>
              <GridItem xs={12}>
                {this.renderDateWarnings()}
              </GridItem>
              {/* <GridItem xs={12}>
                  <Field 
                    name="publicDescription" 
                    component={CustomTextField}
                    label="PUBLIC DESCRIPTION (OVERRIDE)"
                    props={{ fullWidth: true }} 
                  />
                  </GridItem> */}
                  <GridItem xs={12}>
                  <Field 
                    name="messageHeader" 
                    component={CustomTextField}
                    label="MESSAGE HEADER"
                    props={{ fullWidth: true }} 
                    disabled={readOnlyMode} 
                  />
                  </GridItem>
                  <GridItem xs={12}>
                  <Field 
                    name="messageDetail" 
                    component={CustomTextField}
                    label="MESSAGE DETAIL - ENHANCED"
                    props={{ fullWidth: true, multiline: true, rows: 1, rowsMax: 4 }} 
                    disabled={readOnlyMode} 
                  />
                  </GridItem>
              {readOnlyMode || <GridItem xs={12} style={{ marginTop: '20px' }}>
                <div className={classes.formCategory}>
                  <small>*</small> Required fields
                </div>
              </GridItem>}

              </GridContainer>
              {readOnlyMode || <Button
                  color="success"
                  type="submit"
                  className={classes.registerButton}
                  disabled={pristine || submitting}
                ><Create className={classes.icon} />
                  Update
              </Button>}
              {readOnlyMode || <Button
                  color="danger"
                  className={classes.registerButton}
                  style={{ marginRight: '10px'}}
                  disabled={submitting}
                  onClick={() => onCancel(pristine)}
                ><Cancel className={classes.icon} />
                  Cancel
              </Button>}
              {readOnlyMode || <Button
                  color="primary"
                  className={classes.registerButton}
                  style={{ marginRight: '25px'}}
                  disabled={pristine || submitting}
                  onClick={this.resetFields}
                ><Revert className={classes.icon} />
                  Revert
              </Button>}
              {!readOnlyMode || <Button
                  color="success"
                  className={classes.registerButton}
                  disabled={submitting}
                  onClick={() => onCancel(pristine)}
                ><Done className={classes.icon} />
                  Ok
              </Button>}

              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

EditTemplateInstanceForm = reduxForm({
  form: 'editTemplateInstance',
  validate
})(withStyles(editTemplateGroupFormsStyle)(EditTemplateInstanceForm));