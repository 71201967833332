import { areaConstants } from '../constants';

export function areas(state = {}, action) {
  switch (action.type) {
    case areaConstants.OUTLINE_REQUEST:
      return {
        loading: true
      };
    case areaConstants.OUTLINE_SUCCESS:
      return {
        areas: action.areas,
        loading: false
      };
    case areaConstants.OUTLINE_FAILURE:
      return {
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
}