/* eslint-disable */

import React from "react";
import { Field, reduxForm, SubmissionError, reset } from 'redux-form';
import { connect } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import * as moment from "moment";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material ui icons
import Style from "@material-ui/icons/Style";
import Create from "@material-ui/icons/Create";
import Cancel from "@material-ui/icons/Cancel";
import Clear from "@material-ui/icons/Clear";
import Warning from "@material-ui/icons/Warning";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";

import { determineTimeStructuresForDateRange, convertRestrictedPeriodStructureToApplies } from "utils/TimeStructures.jsx";


import CustomTextField from "components/redux-form/CustomTextField/CustomTextField.jsx";

// validators
import { stringOfLengthBetween } from "helpers/validation";

// style for this view
import newTemplateGroupFormsStyle from "assets/jss/spot-admin/views/newTemplateGroupStyle.jsx";

import { templateService } from "services";
import { templateActions } from "actions";


const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Name is a required field";
  } else {
    if (!stringOfLengthBetween(values.name, 4, 50)) {
      errors.name = "Name must be between 4 and 50 characters";
    }
  }

  if (!values.description) {
    errors.description = "Description is a required field";
  } else {
    if (!stringOfLengthBetween(values.description, 1, 200)) {
      errors.description = "Description must has a maximum of 200 characters";
    }
  }

  return errors;
}


class NewTemplateInstancePage extends React.Component {

  constructor(props) {
    super(props);

    const { match } = this.props;

    let templateId = match.params.templateId;

    let defaultFromDate = new Date()
    defaultFromDate.setHours(0, 0, 0, 0)
    defaultFromDate.setDate(defaultFromDate.getDate() + 1)

    let defaultToDate = new Date(defaultFromDate)
    defaultToDate.setDate(defaultFromDate.getDate() + 1)

    this.state = {
      alert: null,
      snackBarOpen: false,
      snackBarStatus: "success",
      snackBarMessage: "",
      templateId,
      dateTimeRange: [defaultFromDate, defaultToDate]
    };

    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.continue = this.continue.bind(this);
    this.finished = this.finished.bind(this);
    this.removeAlert = this.removeAlert.bind(this);
    this.confirmContinue = this.confirmContinue.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.handleDateTimeRangeChange = this.handleDateTimeRangeChange.bind(this);
  }

  componentWillUnmount() {
    if (this.notificationTimer) {
      clearTimeout(this.notificationTimer);
    }
  }

  showNotification(status, message) {
    if (!this.state.snackBarOpen) {
      this.setState({ 
        snackBarOpen: true,
        snackBarStatus: status,
        snackBarMessage: message 
      });
      this.notificationTimer = setTimeout(
        function() {
          this.setState({
            snackBarOpen: false
          });
          this.notificationTimer = null;
        }.bind(this),
        3000
      );
    }
  }

  confirmContinue() {
    const { classes } = this.props;
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success!"
          onConfirm={() => this.continue()}
          onCancel={() => this.finished()}
          confirmBtnCssClass={
            classes.button + " " + classes.success
          }
          cancelBtnCssClass={
            classes.button + " " + classes.danger
          }
          confirmBtnText="Add another"
          cancelBtnText="Finished"
          showCancel
        >
        Would you like to add another template instance?
        </SweetAlert>
      )
    });
  }

  continue() {
    const { dispatch } = this.props;

    dispatch(reset('newTemplateInstance'));
    this.setState({
      alert: null
    });
  }

  finished() {
    const { history  } = this.props;

    this.setState({
      alert: null
    });
    history.goBack();
  }

  removeAlert() {
    this.setState({
      alert: null
    });
  }


  submit( values ) {

    const { templateId, dateTimeRange } = this.state;
    const { dispatch } = this.props;

    let user = JSON.parse(localStorage.getItem('user'));

    let startDate = moment(dateTimeRange[0]);
    let endDate = moment(dateTimeRange[1]);

    let restrictedPeriods = determineTimeStructuresForDateRange(startDate, endDate);
    let applies = convertRestrictedPeriodStructureToApplies(restrictedPeriods);
    
    let messageAttributes = {};
    if (values.publicDescription) messageAttributes['publicDescription'] = values.publicDescription;
    if (values.messageHeader) messageAttributes['messageHeader'] = values.messageHeader;
    if (values.messageDetail) messageAttributes['messageDetail'] = values.messageDetail;

    let templateInstance = {
      name: values.name,
      description: { "en-US": values.description },
      applies,
      template: templateId,
      published: false,
      lastPublished: null,
      modifiedBy: user.id,
      messageAttributes
    };

    return new Promise((resolve, reject) => {
      templateService.addNewTemplateInstance(templateInstance)
      .then(newTemplateInstance => {
        dispatch(templateActions.registerTemplateInstance(newTemplateInstance));
        resolve(newTemplateInstance);
        this.showNotification("success", "Template instance has been successfully created!");
        this.confirmContinue();
      })
      .catch(error => {
        console.error("ERROR creating template instance ", error);
        this.showNotification("danger", "There was a problem creating the template instance!");
        reject(new SubmissionError({ _error: "There was a problem creating the template instance."} ) );
      });
    });

  }

  cancel(pristine) {
    const { classes, history } = this.props;

    if (!pristine) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block" }}
            title="Warning!"
            onConfirm={() => this.finished()}
            onCancel={() => this.removeAlert()}
            confirmBtnCssClass={
              classes.button + " " + classes.success
            }
            cancelBtnCssClass={
              classes.button + " " + classes.danger
            }
            confirmBtnText="Yes"
            cancelBtnText="No"
            showCancel
          >
          Cancelling will discard any changes made.  Are you sure?
          </SweetAlert>
        )
      });  
    } else {
      history.goBack();
    }
  }

  handleDateTimeRangeChange(value) {
    this.setState({ dateTimeRange: value });
  }

  render() {
    const { alert, snackBarOpen, snackBarMessage, snackBarStatus, dateTimeRange } = this.state;

    return (
    <div>
      {alert}
      <Snackbar
        place="tr"
        color={snackBarStatus}
        icon={Style}
        message={snackBarMessage}
        open={snackBarOpen}
        closeNotification={() => this.setState({ snackBarOpen: false })}
        close
      />
      <NewTemplateInstanceForm 
        onSubmit={this.submit} 
        onCancel={this.cancel} 
        dateTimeRange={dateTimeRange}
        onDateTimeRangeChange={this.handleDateTimeRangeChange}
        />
    </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
} 

export default connect(mapStateToProps)(withStyles(newTemplateGroupFormsStyle)(NewTemplateInstancePage));


class NewTemplateInstanceForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      initialValuesLoaded: false
    };

    this.handleDateTimeRangeChange = this.handleDateTimeRangeChange.bind(this);
    this.resetFields = this.resetFields.bind(this);
  }

  componentDidUpdate() {
    const { dateTimeRange, initialize } = this.props;
    const { initialValuesLoaded } = this.state;

    if ((!initialValuesLoaded) && (dateTimeRange)) {
      this.setState({
        dateTimeRange,
        initialValuesLoaded: true
      });

      initialize({ 
        name: "", 
        description: "",
        dateTimeRange
      });
    }
  }

  handleDateTimeRangeChange(newDateTimeRange) {
    const { dispatch, change, onDateTimeRangeChange } = this.props;

    let finalFrom = newDateTimeRange[0];
    let finalTo = newDateTimeRange[1];
    if (finalFrom.getTime() >= finalTo.getTime()) {
      finalTo.setTime(finalFrom.getTime())
      finalTo.setHours(finalTo.getHours() + 1)
    }

    onDateTimeRangeChange([finalFrom, finalTo]);
    dispatch(change("dateTimeRange", [finalFrom, finalTo]));
  }

  renderDateWarnings() {
    const { dateTimeRange } = this.state;

    if (!dateTimeRange) return null;
    if ((!dateTimeRange[0]) || (!dateTimeRange[1])) return null;

    let startDate = dateTimeRange[0];
    let endDate = dateTimeRange[1];
    let currentDate = new Date();

    if (endDate < currentDate) {
      return (
        <SnackbarContent
          message={"The Ending date occurs in the past!"}
          color="danger"
          icon={Warning}
        />
      );
    }

    if (startDate < currentDate) {
      return (
        <SnackbarContent
          message={"The Starting date occurs in the past!"}
          color="warning"
          icon={Warning}
        />
      );      
    }

    return null;
  }

  resetFields() {
    const { reset, onDateTimeRangeChange } = this.props;
  
    let defaultFromDate = new Date()
    defaultFromDate.setHours(0, 0, 0, 0)

    let defaultToDate = new Date(defaultFromDate)
    defaultToDate.setDate(defaultFromDate.getDate() + 1)

    let newState = {
      dateTimeRange: [defaultFromDate, defaultToDate]
    };

    this.setState(newState);
    onDateTimeRangeChange(newState.dateTimeRange);
    reset();
  }

  render() {
    const { classes, handleSubmit, pristine, submitting,
      submitFailed, submitSucceeded, onCancel, timeRanges, dateTimeRange } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={9}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="rose">
                <Style />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Add a new Template Instance</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit} autoComplete="off">
              <GridContainer>
                <GridItem xs={12}>
                  <Field 
                    name="name" 
                    component={CustomTextField} 
                    label="NAME *"
                    props={{ fullWidth: true }} 
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field 
                    name="description" 
                    component={CustomTextField}
                    label="DESCRIPTION *"
                    props={{ fullWidth: true }} 
                  />
                  </GridItem>
                  <GridItem xs={12} style={{marginTop: '10px'}}>
                  Valid Time Range:&nbsp;&nbsp; 
                  <Field
                    name="dateTimeRange"

                    component={DateTimeRangePicker}
                    props={{
                      onChange: this.handleDateTimeRangeChange,
                      value: dateTimeRange,
                      clearIcon: null,
                      disableClock: true
                    }}
                    value={dateTimeRange}
                  />
                </GridItem>

              <GridItem xs={12}>
                {this.renderDateWarnings()}
              </GridItem>
              {/* <GridItem xs={12}>
                  <Field 
                    name="publicDescription" 
                    component={CustomTextField}
                    label="PUBLIC DESCRIPTION (OVERRIDE)"
                    props={{ fullWidth: true }} 
                  />
                  </GridItem> */}
                  <GridItem xs={12}>
                  <Field 
                    name="messageHeader" 
                    component={CustomTextField}
                    label="MESSAGE HEADER"
                    props={{ fullWidth: true }} 
                  />
                  </GridItem>
                  <GridItem xs={12}>
                  <Field 
                    name="messageDetail" 
                    component={CustomTextField}
                    label="MESSAGE DETAIL"
                    props={{ fullWidth: true, multiline: true, rows: 1, rowsMax: 4 }}
                  />
                  </GridItem>
                  <GridItem xs={12} style={{ marginTop: '20px' }}>
                <div className={classes.formCategory}>
                  <small>*</small> Required fields
              </div>
              </GridItem>
              </GridContainer>
                <Button
                  color="success"
                  type="submit"
                  className={classes.registerButton}
                  disabled={submitting}
                >
                  <Create className={classes.icon}/>
                  Create
              </Button>
              <Button
                  color="danger"
                  className={classes.registerButton}
                  style={{ marginRight: '10px'}}
                  disabled={submitting}
                  onClick={() => onCancel(pristine)}
                ><Cancel className={classes.icon} />
                  Cancel
              </Button>
              <Button
                  color="primary"
                  className={classes.registerButton}
                  style={{ marginRight: '25px'}}
                  disabled={pristine || submitting}
                  onClick={this.resetFields}
                ><Clear className={classes.icon}/>
                  Clear
              </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

NewTemplateInstanceForm = reduxForm({
  form: 'newTemplateInstance',
  validate
})(withStyles(newTemplateGroupFormsStyle)(NewTemplateInstanceForm));