import React from "react";
import PropTypes from "prop-types";

import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import DateTimePicker from 'react-datetime-picker';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// custom components
import CustomisableSelect from "views/Forms/controls/CustomisableSelect.jsx";

import timeFilterSelectorStyle from "assets/jss/spot-admin/views/newTemplateGroupStyle.jsx";


class TimeFilterSelector extends React.Component {

  constructor(props) {
    super(props);
    let value = props.value;
    
    let defaultFromDate = new Date();
    defaultFromDate.setHours(0, 0, 0, 0);

    let defaultToDate = new Date(defaultFromDate)
    defaultToDate.setDate(defaultFromDate.getDate() + 1)

    this.state = { 
      timeFilterSelected: value || 98,
      dateTimeRange: [defaultFromDate, defaultToDate]
    };

    this.handleTimeFilterChange = this.handleTimeFilterChange.bind(this);
    this.handleDateTimeRangeChange = this.handleDateTimeRangeChange.bind(this);
  }

  handleTimeFilterChange(selected) {
    const { timeFilterSelected } = this.state;
    const { onTimeFilterChange } = this.props;

    if (timeFilterSelected !== selected) {
      if ((selected >= 10) && (selected <= 12)) {
        this.handleTimeRangeTypeChange(selected);
      } else {
        this.setState({
          timeFilterSelected: selected
        });
  
        if (onTimeFilterChange) {
          onTimeFilterChange({
            filterMode: selected,
            fromDate: null,
            toDate: null
          });
        }  
      }
    }
  }

  handleTimeRangeTypeChange(newSelection) {
    const { onTimeFilterChange } = this.props;

    let defaultFromDate = new Date()
    defaultFromDate.setHours(0, 0, 0, 0)

    let defaultToDate = new Date(defaultFromDate)
    defaultToDate.setDate(defaultFromDate.getDate() + 1)

    let newDateRange = [null, null];

    switch (newSelection) {
      case 10:
        newDateRange[0] = defaultFromDate
        newDateRange[1] = null
        break;
      case 11:
        newDateRange[0] = null;
        newDateRange[1] = defaultToDate
        break;
      case 12:
        newDateRange[0] = defaultFromDate
        newDateRange[1] = defaultToDate
        break;
      default:
        break;
    }
  
    this.setState({
      timeFilterSelected: newSelection,
      dateTimeRange: newDateRange
    });

    onTimeFilterChange({
      filterMode: newSelection,
      fromDate: newDateRange[0],
      toDate: newDateRange[1]
    })
  }

  handleDateTimeRangeChange(dateTimeRange) {
    const { timeFilterSelected } = this.state
    const { onTimeFilterChange } = this.props

    let newDateRange = [null, null]

    switch (timeFilterSelected) {
      case 10:
        newDateRange[0] = dateTimeRange
        newDateRange[1] = null
        break;
      case 11:
        newDateRange[0] = null
        newDateRange[1] = dateTimeRange
        break;
      case 12:
        let finalFrom = dateTimeRange[0];
        let finalTo = dateTimeRange[1];
        if (finalFrom.getTime() >= finalTo.getTime()) {
          finalTo.setTime(finalFrom.getTime())
          finalTo.setHours(finalTo.getHours() + 1)
        }
        newDateRange = [finalFrom, finalTo]
        break;
      default:
        newDateRange = [null, null]
        break;
    }

    this.setState({
      dateTimeRange: newDateRange
    })

    onTimeFilterChange({
      filterMode: timeFilterSelected,
      fromDate: newDateRange[0],
      toDate: newDateRange[1]
    })
  }

  convertToReadableText(text) {
    return text.replace(/\-/g, ' ').toUpperCase();
  }
  
  render() {
    const { controlId, filters } = this.props;
    const { timeFilterSelected, dateTimeRange } = this.state;
    
    const timeFilters = filters.map(e => {
      return {
        label: this.convertToReadableText(e.label),
        value: e.value
      };
    });


    return (
      <div>
        <CustomisableSelect
          controlId={controlId} 
          label="Time Filter Mode"
          items={timeFilters}
          value={timeFilterSelected}
          handleChange={this.handleTimeFilterChange}
        />
        <br/>
        {(timeFilterSelected === 10 || timeFilterSelected === 11) && <div><h6>Select {timeFilterSelected === 10 ? 'From' : 'To'} Date</h6>
        <DateTimePicker 
          value={ timeFilterSelected === 10 ? dateTimeRange[0] : dateTimeRange[1]}
          onChange={this.handleDateTimeRangeChange}
          clearIcon={null}
        /><br/><br/></div>}
        {timeFilterSelected === 12 && <div><h6>Select Date Range</h6>
        <DateTimeRangePicker 
          value={dateTimeRange}
          onChange={this.handleDateTimeRangeChange}
          clearIcon={null}
          disableClock={true}
        /><br/><br/></div>}
      </div>
    );  
  }
}

TimeFilterSelector.propTypes = {
  controlId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
  value: PropTypes.number.isRequired,
  onTimeFilterChange: PropTypes.func
};

export default withStyles(timeFilterSelectorStyle)(TimeFilterSelector);
