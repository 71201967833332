/* eslint-disable */

import React from "react";
import { Field, reduxForm, SubmissionError, reset } from 'redux-form';
import { connect } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material ui icons
import Assignment from "@material-ui/icons/Assignment";
import Create from "@material-ui/icons/Create";
import Cancel from "@material-ui/icons/Cancel";
import Revert from "@material-ui/icons/GetApp";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";

// custom components
import CustomisableSelect from "./controls/CustomisableSelect.jsx";


import CustomTextField from "components/redux-form/CustomTextField/CustomTextField.jsx";

// validators
import { stringOfLengthBetween } from "helpers/validation";

// style for this view
import editTemplateContainerFormsStyle from "assets/jss/spot-admin/views/newTemplateGroupStyle.jsx";

import { templateService } from "services";
import { templateActions, alertActions } from "actions";

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Name is a required field";
  } else {
    if (!stringOfLengthBetween(values.name, 4, 50)) {
      errors.name = "Name must be between 4 and 50 characters";
    }
  }

  if (!values.description) {
    errors.description = "Description is a required field";
  } else {
    if (!stringOfLengthBetween(values.description, 1, 200)) {
      errors.description = "Description must has a maximum of 200 characters";
    }
  }

  return errors;
}


class EditTemplateContainerPage extends React.Component {

  constructor(props) {
    super(props);
    const { match, templateContainers } = props;

    let editTemplateContainerId = match.params.id;

    let initialState = {
      alert: null,
      snackBarOpen: false,
      snackBarStatus: "success",
      snackBarMessage: "",
      editTemplateContainerId
    };
  
    this.state = initialState;

    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.finished = this.finished.bind(this);
    this.removeAlert = this.removeAlert.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.showSuccess = this.showSuccess.bind(this);
  }

  componentDidMount() {
    const { dispatch, templateContainers } = this.props;
 
    let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

    if (Object.keys(templateContainers).length == 0) {
      dispatch(templateActions.getTemplateContainers(jurisdiction.id))
    }

  }

  componentWillUnmount() {
    if (this.notificationTimer) {
      clearTimeout(this.notificationTimer);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { templateContainers, match } = nextProps;

    let editTemplateContainerId = match.params.id;

    if (templateContainers) {
      let templateContainer = templateContainers[editTemplateContainerId];
      if (!templateContainer) {
        return {};
      }

      return {
        editTemplateContainerId, 
        formInitialValues: {
          name: templateContainer.name,
          description: templateContainer.description["en-US"],
          templateGroup: templateContainer.templategroup
        }
      };
    }
    
    return {};
  }

  showNotification(status, message) {
    if (!this.state.snackBarOpen) {
      this.setState({ 
        snackBarOpen: true,
        snackBarStatus: status,
        snackBarMessage: message 
      });
      this.notificationTimer = setTimeout(
        function() {
          this.setState({
            snackBarOpen: false
          });
          this.notificationTimer = null;
        }.bind(this),
        3000
      );
    }
  }

  finished() {
    const { history  } = this.props;

    this.setState({
      alert: null
    });
    history.goBack();
  }

  removeAlert() {
    this.setState({
      alert: null
    });
  }

  showSuccess() {
    const { classes } = this.props;

    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success!"
          onConfirm={() => this.finished()}
          confirmBtnCssClass={
            classes.button + " " + classes.success
          }
          confirmBtnText="Ok"
        >
        The template has been updated successfully
        </SweetAlert>
      )
    })
  }

  submit( values ) {

    const { dispatch, templateContainers } = this.props;
    const { editTemplateContainerId } = this.state;

    let user = JSON.parse(localStorage.getItem('user'));
    let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

    const previousContainer = templateContainers[editTemplateContainerId];

    let templateContainer = {
      id: editTemplateContainerId,
      name: values.name,
      description: { "en-US": values.description },
      modifiedBy: user.id,
      jurisdiction: jurisdiction.id,
      templategroup: previousContainer.templategroup.id
    };

    return new Promise((resolve, reject) => {      
      templateService.updateTemplateContainer(templateContainer)
      .then(updatedTemplateContainer => {
        dispatch(templateActions.registerTemplateContainer(updatedTemplateContainer));
        resolve(updatedTemplateContainer);
        this.showNotification("success", "Template has been successfully updated!");
        this.showSuccess();
      })
      .catch(error => {
        console.error("ERROR updating template container ", error);
        this.showNotification("danger", "There was a problem updating the template!");
        reject(new SubmissionError({ _error: "There was a problem updating the template."} ) );
      });
    });

  }

  cancel(pristine) {
    const { classes, history } = this.props;

    if (!pristine) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block" }}
            title="Warning!"
            onConfirm={() => this.finished()}
            onCancel={() => this.removeAlert()}
            confirmBtnCssClass={
              classes.button + " " + classes.success
            }
            cancelBtnCssClass={
              classes.button + " " + classes.danger
            }
            confirmBtnText="Yes"
            cancelBtnText="No"
            showCancel
          >
          Cancelling will discard any changes made.  Are you sure?
          </SweetAlert>
        )
      });  
    } else {
      history.goBack();
    }
  }

  render() {
    const { alert, snackBarOpen, snackBarMessage, snackBarStatus, editTemplateContainerId, formInitialValues } = this.state;

    return (
    <div>
      {alert}
      <Snackbar
        place="tr"
        color={snackBarStatus}
        icon={Assignment}
        message={snackBarMessage}
        open={snackBarOpen}
        closeNotification={() => this.setState({ snackBarOpen: false })}
        close
      />
      <EditTemplateContainerForm editTemplateContainerId={editTemplateContainerId} formInitialValues={formInitialValues} onSubmit={this.submit} onCancel={this.cancel}/>
    </div>
    );
  }
}

function mapStateToProps(state) {
  const { templates } = state;

  if (!templates) {
    return {};
  }

  return {
    templateContainers: templates.templateContainers || {},
    loading: templates.loading || false,
    saving: templates.saving || false,
  };
} 

export default connect(mapStateToProps)(withStyles(editTemplateContainerFormsStyle)(EditTemplateContainerPage));



class EditTemplateContainerForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      initialValuesLoaded: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { initialValuesLoaded } = this.state;
    const { initialize, formInitialValues } = this.props;

    if ((!initialValuesLoaded) && (formInitialValues)) {
      initialize({ name: formInitialValues.name, description: formInitialValues.description });
      this.setState({initialValuesLoaded: true});
    }
  }

  render() {
    const { classes, handleSubmit, pristine, submitting,
      submitFailed, submitSucceeded, reset, onCancel, formInitialValues, editTemplateContainerId } = this.props;
    const { initialValuesLoaded } = this.state;

    if (!formInitialValues) {
      return (
        <GridContainer>
          <GridItem xs={12}>
            <h4>The requested Template for editing could not be found.</h4>
          </GridItem>
        </GridContainer>        
      )
    }
    
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={9}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Edit existing Template</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit} autoComplete="off">
              <GridContainer>
                <GridItem xs={12}>
                  <p>Configured Template Group: {formInitialValues.templateGroup.name}</p>
                </GridItem>
                <GridItem xs={12}>
                  <Field 
                    name="name" 
                    component={CustomTextField} 
                    label="NAME *"
                    props={{ fullWidth: true }} 
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field 
                    name="description" 
                    component={CustomTextField}
                    label="DESCRIPTION *"
                    props={{ fullWidth: true }} 
                  />
                  </GridItem>
              <GridItem xs={12}>
                <div className={classes.formCategory}>
                  <small>*</small> Required fields
              </div>
              </GridItem>
              </GridContainer>
                <Button
                  color="success"
                  type="submit"
                  className={classes.registerButton}
                  disabled={pristine || submitting}
                ><Create className={classes.icon} />
                  Update
              </Button>
              <Button
                  color="danger"
                  className={classes.registerButton}
                  style={{ marginRight: '10px'}}
                  disabled={submitting}
                  onClick={() => onCancel(pristine)}
                ><Cancel className={classes.icon} />
                  Cancel
              </Button>
              <Button
                  color="primary"
                  className={classes.registerButton}
                  style={{ marginRight: '25px'}}
                  disabled={pristine || submitting}
                  onClick={reset}
                ><Revert className={classes.icon} />
                  Revert
              </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

EditTemplateContainerForm = reduxForm({
  form: 'updateTemplateContainer',
  validate
})(withStyles(editTemplateContainerFormsStyle)(EditTemplateContainerForm));