
import React from "react";

import NewTemplateGroupPage from "views/Forms/NewTemplateGroup.jsx";
import NewTemplateContainerPage from "views/Forms/NewTemplateContainer.jsx";
import NewTemplateInstancePage from "views/Forms/NewTemplateInstance.jsx";
import NewMessagePage from "views/Forms/NewMessage.jsx";
import EditTemplateGroupPage from "views/Forms/EditTemplateGroup.jsx";
import EditTemplateContainerPage from "views/Forms/EditTemplateContainer.jsx";
import EditTemplatePage from "views/Forms/EditTemplate.jsx";
import EditTemplateInstancePage from "views/Forms/EditTemplateInstance.jsx";
import EditMessagePage from "views/Forms/EditMessage.jsx";
import TemplateTablePage from "views/Tables/TemplatesTable.jsx";
import TemplateAffectedPage from "views/Maps/FullScreenMap.jsx";
import NewTemplateVersionConfigurationPage from "views/Forms/NewTemplateVersionConfiguration.jsx";
import EditTemplateVersionConfigurationPage from "views/Forms/EditTemplateVersionConfiguration.jsx";
import NewUserPage from "views/Forms/NewUser.jsx";
import EditUserPage from "views/Forms/EditUser.jsx";


const nonDashRoutes = [
  { path: "/template-admin/template-group/new-template-group", component: NewTemplateGroupPage, name: "Template Group Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'templateAdmin', 'templateMaintainer'] },
  { path: "/template-admin/template/new-template", component: NewTemplateContainerPage, name: "Template Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'templateAdmin', 'templateMaintainer'] },
  { path: "/template-admin/template-group/edit-template-group/:id", component: EditTemplateGroupPage, name: "Template Group Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'templateAdmin', 'templateMaintainer'] },
  { path: "/template-admin/template-version/edit-template-version/:id", component: EditTemplatePage, name: "Template Version Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'templateAdmin', 'templateMaintainer'] },
  { path: "/template-admin/template-version/view-template-version/:id", render: (props) =>  { return(<EditTemplatePage {...props} readOnlyMode={true} />); }, name: "Template Version Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'templateAdmin', 'templateMaintainer', 'templateReader'] },
  { path: "/template-admin/template/edit-template/:id", component: EditTemplateContainerPage, name: "Template Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'templateAdmin', 'templateMaintainer'] },
  { path: "/template-admin/template-detail/:id", component: TemplateTablePage, name: "Template Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'templateAdmin', 'templateMaintainer', 'templateReader'] },
  { path: "/template-admin/template-affected/:id", component: TemplateAffectedPage, name: "Template Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'templateAdmin', 'templateMaintainer'] },
  { path: "/template-admin/view-template-affected/:id", render: (props) =>  { return(<TemplateAffectedPage {...props} readOnlyMode={true} />); }, name: "Template Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'templateAdmin', 'templateMaintainer', 'templateReader'] },
  { path: "/template-admin/template-instance/new-template-instance/:templateId", component: NewTemplateInstancePage, name: "Template Instance Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'templateAdmin', 'templateMaintainer'] },
  { path: "/template-admin/template-instance/edit-template-instance/:id", component: EditTemplateInstancePage, name: "Template Instance Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'templateAdmin', 'templateMaintainer'] },
  { path: "/template-admin/template-instance/view-template-instance/:id", render: (props) =>  { return(<EditTemplateInstancePage {...props} readOnlyMode={true} />); }, name: "Template Instance Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'templateAdmin', 'templateMaintainer', 'templateReader'] },
  { path: "/template-admin/template-configuration/new-template-configuration/:id", component: NewTemplateVersionConfigurationPage, name: "Template Instance Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'templateAdmin', 'templateMaintainer'] },
  { path: "/template-admin/template-configuration/edit-template-configuration/:id/:configId", component: EditTemplateVersionConfigurationPage, name: "Template Instance Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'templateAdmin', 'templateMaintainer'] },
  { path: "/template-admin/template-configuration/view-template-configuration/:id/:configId", render: (props) => { return(<EditTemplateVersionConfigurationPage {...props} readOnlyMode={true} />); }, name: "Template Instance Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'templateAdmin', 'templateMaintainer', 'templateReader'] },
  { path: "/message-admin/message/new-message", component: NewMessagePage, name: "Message Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'messageAdmin'] },
  { path: "/message-admin/message/edit-message/:id", component: EditMessagePage, name: "Message Admin", admin: true, roles: ['superAdmin', 'corporateAdmin',  'messageAdmin'] },
  { path: "/message-admin/message/view-message/:id", render: (props) => { return(<EditMessagePage {...props} readOnlyMode={true} />); }, name: "Message Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'messageAdmin', 'messageReader'] },
  { path: "/user-admin/new-user", component: NewUserPage, name: "User Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'userAdmin'] },
  { path: "/user-admin/edit-user/:id", component: EditUserPage, name: "User Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'userAdmin'] },
  { path: "/user-admin/view-user/:id", render: (props) =>  { return(<EditUserPage {...props} readOnlyMode={true} />); }, name: "User Admin", admin: true, roles: ['superAdmin', 'corporateAdmin', 'userAdmin', 'userReader'] }
];

export default nonDashRoutes;