import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';

let middleware = [thunkMiddleware];

if (process.env.NODE_ENV !== 'production') {
  middleware = [ ...middleware, createLogger()];
}

//const loggerMiddleware = createLogger();

export const store = createStore(
    rootReducer,
    applyMiddleware(...middleware)
);