/* eslint-disable */

import config from '../config';
import { authHeader } from '../helpers';

export const userService = {
    login,
    logout,
    getAll,
    checkForExistingEmail,
    passwordReset,
    addNewUser,
    updateUser,
    switchEnabledStatus,
    addNewUserRole,
    updateUserRole,
    deleteUserRole,
    getUserRole,
    getUserRoles,
    getAssignableRoles,
    checkJurisdictionScope,
    checkJurisdictionDefaults
};

const fetch = window.fetch;

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: username, password })
    };

    return fetch(`${config.apiUrl}/1.0/api-keys`, requestOptions)
        .then(handleAuthResponse)
        .then(user => {

          let { token, ownerId, email, firstName, lastName, roles } = user.apiKey;
          let credentials = {};

            // login successful if there's a jwt token in the response
            if (token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                credentials = {
                  id: ownerId,
                  username: email,
                  token,
                  firstName,
                  lastName,
                  roles
                };

                localStorage.setItem('user', JSON.stringify(credentials));

                let jurisdictionId = "*";

                for (let i = 0; i < roles.length; i++) {
                  if (roles[i].role === 'jurisdictionScope') {
                    jurisdictionId = roles[i].parameters;
                    break;
                  }
                }

                localStorage.setItem('user.jurisdiction', JSON.stringify({ id: jurisdictionId }));
            }

            return credentials;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('user.jurisdiction');
}

function getAll(jurisdictionId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let path = `${config.apiUrl}/1.1/users/usersinfo`;
    if (jurisdictionId) {
      path = `${path}?jurisdictionId=${jurisdictionId}` 
    }

    return fetch(path, requestOptions).then(handleStandardResponse);
}

function checkForExistingEmail(email) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  let path = `${config.apiUrl}/1.1/users/email-check/${email}`;
  if (jurisdiction) {
    path = `${path}?jurisdictionId=${jurisdiction.id}`;
  }

  return fetch(path, requestOptions).then(handleStandardResponse);
}

function addNewUser(user) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ user: user, jurisdictionId: jurisdiction.id })
  };

  return fetch(`${config.apiUrl}/1.1/users/accounts`, requestOptions)
    .then(handleStandardResponse)
    .then(user => {
      return user;
    });
}

function updateUser(user) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ user: user, jurisdictionId: jurisdiction.id })
  };

  return fetch(`${config.apiUrl}/1.1/users/accounts/${user.id}`, requestOptions)
    .then(handleStandardResponse)
    .then(user => {
      return user;
    });
}

function passwordReset(email) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email })
  };

  return fetch(`${config.apiUrl}/1.1/users/password-reset`, requestOptions)
    .then(handlePasswordResetResponse)
    .then(infos => {
      if (infos.length === 1) {
        return infos[0];
      } 
      return infos; 
    });
}

// no check for authentication needed for password reset
function handlePasswordResetResponse(response) {
  return response.json().then(data => {
      if (!response.ok) {
          if (response.status === 422) {
            return Promise.reject("Email address cannot be found")
          }
  
          const error = (data && data.error) || response.statusText;
          return Promise.reject(error);
      }

      return data.infos;
  });
}

function switchEnabledStatus(userId, enabledStatus) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ jurisdictionId: jurisdiction.id })
  };

  return fetch(`${config.apiUrl}/1.1/users/set-enabled-status/${userId}/${enabledStatus}`, requestOptions)
    .then(handleStandardResponse)
    .then(status => {
      return status; 
    });
}


function getUserRole(id) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return fetch(`${config.apiUrl}/1.1/user-roles/admin?jurisdictionId=${jurisdiction.id}`, requestOptions)
    .then(handleStandardResponse)
    .then(status => {
      return status; 
    });
}

function getUserRoles(userId) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return fetch(`${config.apiUrl}/1.1/user-roles/query/user/${userId}?jurisdictionId=${jurisdiction.id}`, requestOptions)
    .then(handleStandardResponse)
    .then(status => {
      return status; 
    });
}


function addNewUserRole(userId, userRole, roleParameters) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ 
      userId,
      userRole,
      roleParameters,
      jurisdictionId: jurisdiction.id })
  };

  return fetch(`${config.apiUrl}/1.1/user-roles/admin`, requestOptions)
    .then(handleStandardResponse)
    .then(status => {
      return status; 
    });
}

function updateUserRole(id, userRole, roleParameters) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ 
      userRole,
      roleParameters,
      jurisdictionId: jurisdiction.id })
  };

  return fetch(`${config.apiUrl}/1.1/user-roles/admin/${id}`, requestOptions)
    .then(handleStandardResponse)
    .then(status => {
      return status; 
    });
}

function deleteUserRole(id) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return fetch(`${config.apiUrl}/1.1/user-roles/admin/${id}?jurisdictionId=${jurisdiction.id}`, requestOptions)
    .then(handleStandardResponse)
    .then(status => {
      return status; 
    });
}

function getAssignableRoles() {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return fetch(`${config.apiUrl}/1.1/user-roles/admin-assignable-roles?jurisdictionId=${jurisdiction.id}`, requestOptions)
    .then(handleStandardResponse)
    .then(response => {
      return response; 
    });
}

function checkJurisdictionScope(userId) {
  let jurisdiction = JSON.parse(localStorage.getItem('user.jurisdiction'));

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return fetch(`${config.apiUrl}/1.1/user-roles/query/jurisdictionScope/${userId}?jurisdictionId=${jurisdiction.id}`, requestOptions)
    .then(handleStandardResponse)
    .then(response => {
      return response; 
    });
}

function checkJurisdictionDefaults(jurisdictionId) {

  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() }
  };

  return fetch(`${config.apiUrl}/1.4/query/jurisdictionDefaults/${jurisdictionId}?fields=TIMEZONE,BASE_CURRENCY,DEFAULT_LOCALE,DEFAULT_LOCATION`, requestOptions)
    .then(handleStandardResponse)
    .then(response => {
      return response; 
    });
}


function handleAuthResponse(response) {
    return response.json().then(data => {
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            if (response.status === 422) {
              return Promise.reject('Invalid credentials provided - please check and try again')
            }

            const error = (data && data.error) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function handleStandardResponse(response) {
  return response.json().then(data => {
      if (!response.ok) {
          if (response.status === 401) {
              // auto logout if 401 response returned from api
              logout();
              location.reload(true);
          }

          const error = (data && data.error) || response.statusText;
          return Promise.reject(error);
      }

      return data;
  });
}