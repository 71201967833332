/* eslint-disable */
import { templateConstants } from '../constants';
import { templateService } from '../services';
import { alertActions } from '.';

/*
 * template related actions
 */
export const templateActions = {
  getOutlines,
  getBayGroupsForLot,
  getLotAreaReferencesFor,
  getSignConfigs,
  clearLotAreaReference,
  clearLotAreaReferences,
  getTemplateGroups,
  getTemplateGroup,
  getTemplateContainers,
  getTemplateContainer,
  getTemplates,
  getTemplate,
  getTemplateInstances,
  getTemplateInstance,
  addNewTemplateGroup,
  addNewTemplateContainer,
  addNewTemplate,
  copyTemplateFromExisting,
  copyTemplateToAnotherTemplateContainer,
  addNewTemplateInstance,
  updateTemplateGroup,
  updateTemplateContainer,
  updateTemplate,
  updateTemplateInstance,
  deleteTemplateGroup,
  deleteTemplateContainer,
  deleteTemplate,
  deleteTemplateInstance,
  registerTemplateGroup,
  registerTemplateContainer,
  registerTemplate,
  registerTemplateInstance,
  publishTemplateVersion,
  unpublishTemplate,
  publishTemplateInstance,
  unpublishTemplateInstance,
  determineTemplateInstanceConflicts,
  determineTemplateConflicts,
  resetTemplateConflicts
}

function getOutlines(jurisdictionId) {
  return dispatch => {
      dispatch(request());

      templateService.outlines(jurisdictionId)
          .then(
              outlines => dispatch(success(outlines)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error("There was a problem retrieving outline information."));
              }
          );
  };

  function request() { return { type: templateConstants.OUTLINES_REQUEST } }
  function success(outlines) { return { type: templateConstants.OUTLINES_SUCCESS, jurisdictionId, outlines } }
  function failure(error) { return { type: templateConstants.OUTLINES_FAILURE, error } }
}

let bayGroupsLoadingRef = {}
function getBayGroupsForLot(jurisdictionId, lotAreaReferenceId) {
  if ((bayGroupsLoadingRef[jurisdictionId]) && (bayGroupsLoadingRef[jurisdictionId][lotAreaReferenceId])) return dispatch => { dispatch(ignore) };

  return dispatch => {
      dispatch(request());
      templateService.bayGroups(jurisdictionId, lotAreaReferenceId)
          .then(
              bayGroups => dispatch(success(bayGroups)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error("There was a problem retrieving baygroup information for lot."));
              }
          );
  };

  function request() { 
    if (!bayGroupsLoadingRef[jurisdictionId]) bayGroupsLoadingRef[jurisdictionId] = {};
    bayGroupsLoadingRef[jurisdictionId][lotAreaReferenceId] = true;
    return { type: templateConstants.BAYGROUPS_REQUEST, lotAreaReferenceId } 
  }
  function success(bayGroups) { bayGroupsLoadingRef[jurisdictionId][lotAreaReferenceId] = false; return { type: templateConstants.BAYGROUPS_SUCCESS, lotAreaReferenceId, bayGroups } }
  function failure(error) {  bayGroupsLoadingRef[jurisdictionId][lotAreaReferenceId] = false; return { type: templateConstants.BAYGROUPS_FAILURE, error, lotAreaReferenceId } }
  function ignore() { return { type: templateConstants.TEMPLATE_IGNORE_REQUEST } }
}

function getLotAreaReferencesFor(jurisdictionId, bayGroupIds, pamReferenceIds) {
  return dispatch => {
      dispatch(request());

      templateService.lotAreaReferenceIdsFor(jurisdictionId, bayGroupIds, pamReferenceIds)
          .then(
              references => dispatch(success(references)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error("There was a problem retrieving lot referencing information."));
              }
          );
  };

  function request() { return { type: templateConstants.LOTREFERENCES_REQUEST } }
  function success(references) { return { type: templateConstants.LOTREFERENCES_SUCCESS, references } }
  function failure(error) { return { type: templateConstants.LOTREFERENCES_FAILURE, error } }
}

function getSignConfigs(jurisdictionId) {
  return dispatch => {
      dispatch(request());

      templateService.signConfigs(jurisdictionId)
          .then(
              signConfigs => dispatch(success(signConfigs)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error("There was a problem retrieving signConfig information."));
              }
          );
  };

  function request() { return { type: templateConstants.SIGNCONFIGS_REQUEST } }
  function success(signConfigs) { return { type: templateConstants.SIGNCONFIGS_SUCCESS, signConfigs } }
  function failure(error) { return { type: templateConstants.SIGNCONFIGS_FAILURE, error } }
}


function clearLotAreaReference(lotAreaReferenceId) {
  return dispatch => {
    dispatch({
      type: templateConstants.CLEAR_LOTREFERENCE,
      lotAreaReferenceId
    });
  };
}

function clearLotAreaReferences(lotAreaReferenceIds) {
  return dispatch => {
    dispatch({
      type: templateConstants.CLEAR_LOTREFERENCES,
      lotAreaReferenceIds
    });
  };
}

function getTemplateGroups(jurisdictionId) {
  return dispatch => {
      dispatch(request());

      templateService.getTemplateGroups(jurisdictionId)
          .then(
              templateGroups => dispatch(success(templateGroups)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error("There was a problem retrieving template group information."));
              }
          );
  };

  function request() { return { type: templateConstants.TEMPLATE_GROUPS_REQUEST } }
  function success(templateGroups) { return { type: templateConstants.TEMPLATE_GROUPS_SUCCESS, templateGroups } }
  function failure(error) { return { type: templateConstants.TEMPLATE_GROUPS_FAILURE, error } }
}

function getTemplateGroup(templateGroupId) {
  return dispatch => {
      dispatch(request());

      templateService.getTemplateGroup(templateGroupId)
          .then(
              templateGroup => dispatch(success(templateGroup)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error("There was a problem retrieving template group information."));;
              }
          );
  };

  function request() { return { type: templateConstants.TEMPLATE_GROUP_REQUEST } }
  function success(templateGroup) { return { type: templateConstants.TEMPLATE_GROUP_SUCCESS, templateGroup } }
  function failure(error) { return { type: templateConstants.TEMPLATE_GROUP_FAILURE, error } }
}

function addNewTemplateGroup(templateGroup) {
  return dispatch => {
    dispatch(request(templateGroup));

    templateService.addNewTemplateGroup(templateGroup)
      .then(
        templateGroup => dispatch(success(templateGroup)),
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error("There was a problem creating a new template group."));
        }
      );
  };

  function request(templateGroup) { return { type: templateConstants.ADD_NEW_TEMPLATE_GROUP_REQUEST, templateGroup } }
  function success(templateGroup) { return { type: templateConstants.ADD_NEW_TEMPLATE_GROUP_SUCCESS, templateGroup } }
  function failure(error) { return { type: templateConstants.ADD_NEW_TEMPLATE_GROUP_FAILURE, error } }
}

function updateTemplateGroup(templateGroup) {
  return dispatch => {
    dispatch(request(templateGroup));

    templateService.updateTemplateGroup(templateGroup)
      .then(
        templateGroup => {
          dispatch(success(templateGroup));
          dispatch(alertActions.success("Template group has been successfully updated."));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error("There was a problem updating the template group."));
        }
      );
  };

  function request(templateGroup) { return { type: templateConstants.UPDATE_TEMPLATE_GROUP_REQUEST, templateGroup } }
  function success(templateGroup) { return { type: templateConstants.UPDATE_TEMPLATE_GROUP_SUCCESS, templateGroup } }
  function failure(error) { return { type: templateConstants.UPDATE_TEMPLATE_GROUP_FAILURE, error } }
}

function deleteTemplateGroup(templateGroupId) {
  return dispatch => {
    dispatch(request(templateGroupId));

    templateService.deleteTemplateGroup(templateGroupId)
      .then(
        status => {
          dispatch(success(status))
          dispatch(alertActions.success("Template group has been successfully deleted."))
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error("There was a problem deleting the template group."));
        }
      );
  };

  function request(templateGroupId) { return { type: templateConstants.DELETE_TEMPLATE_GROUP_REQUEST, templateGroupId } }
  function success(status) { return { type: templateConstants.DELETE_TEMPLATE_GROUP_SUCCESS, templateGroupId, status } }
  function failure(error) { return { type: templateConstants.DELETE_TEMPLATE_GROUP_FAILURE, error } }
}

function registerTemplateGroup(templateGroup) {
  return dispatch => {
    dispatch({
      type: templateConstants.REGISTER_TEMPLATE_GROUP,
      templateGroup
    });
  };
}

function getTemplateContainers(jurisdictionId) {
  return dispatch => {
      dispatch(request());

      templateService.getTemplateContainers(jurisdictionId)
          .then(
              templateContainers => dispatch(success(templateContainers)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error("There was a problem retrieving template information."));
              }
          );
  };

  function request() { return { type: templateConstants.TEMPLATE_CONTAINERS_REQUEST } }
  function success(templateContainers) { return { type: templateConstants.TEMPLATE_CONTAINERS_SUCCESS, templateContainers } }
  function failure(error) { return { type: templateConstants.TEMPLATE_CONTAINERS_FAILURE, error } }
}

function getTemplateContainer(templateContainerId) {
  return dispatch => {
      dispatch(request());

      templateService.getTemplateContainer(templateContainerId)
          .then(
              templateContainer => dispatch(success(templateContainer)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error("There was a problem retrieving template information."));
              }
          );
  };

  function request() { return { type: templateConstants.TEMPLATE_CONTAINER_REQUEST } }
  function success(templateContainer) { return { type: templateConstants.TEMPLATE_CONTAINER_SUCCESS, templateContainer } }
  function failure(error) { return { type: templateConstants.TEMPLATE_CONTAINER_FAILURE, error } }
}

function addNewTemplateContainer(templateContainer) {
  return dispatch => {
    dispatch(request(templateContainer));

    templateService.addNewTemplateContainer(templateContainer)
      .then(
        templateContainer => dispatch(success(templateContainer)),
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error("There was a problem creating the new template."));
        }
      );
  };

  function request(templateContainer) { return { type: templateConstants.ADD_NEW_TEMPLATE_CONTAINER_REQUEST, templateContainer } }
  function success(templateContainer) { return { type: templateConstants.ADD_NEW_TEMPLATE_CONTAINER_SUCCESS, templateContainer } }
  function failure(error) { return { type: templateConstants.ADD_NEW_TEMPLATE_CONTAINER_FAILURE, error } }
}

function updateTemplateContainer(templateContainer) {
  return dispatch => {
    dispatch(request(templateContainer));

    templateService.updateTemplateContainer(templateContainer)
      .then(
        templateContainer => dispatch(success(templateContainer)),
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error("There was a problem updating the new template."));
        }
      );
  };

  function request(templateContainer) { return { type: templateConstants.UPDATE_TEMPLATE_CONTAINER_REQUEST, templateContainer } }
  function success(templateContainer) { return { type: templateConstants.UPDATE_TEMPLATE_CONTAINER_SUCCESS, templateContainer } }
  function failure(error) { return { type: templateConstants.UPDATE_TEMPLATE_CONTAINER_FAILURE, error } }
}

function deleteTemplateContainer(templateContainerId) {
  return dispatch => {
    dispatch(request(templateContainerId));

    templateService.deleteTemplateContainer(templateContainerId)
      .then(
        status => {
          dispatch(success(status))
          dispatch(alertActions.success("Template has been successfully deleted."))
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error("There was a problem deleting the template."));
        }
      );
  };

  function request(templateContainerId) { return { type: templateConstants.DELETE_TEMPLATE_CONTAINER_REQUEST, templateContainerId } }
  function success(status) { return { type: templateConstants.DELETE_TEMPLATE_CONTAINER_SUCCESS, templateContainerId, status } }
  function failure(error) { return { type: templateConstants.DELETE_TEMPLATE_CONTAINER_FAILURE, error } }
}

function registerTemplateContainer(templateContainer) {
  return dispatch => {
    dispatch({
      type: templateConstants.REGISTER_TEMPLATE_CONTAINER,
      templateContainer
    });
  };
}

let templateLoading = false;
function getTemplates(jurisdictionId) {
  if (templateLoading) return dispatch => {
    dispatch(ignore());
  };

  return dispatch => {
      dispatch(request());
      templateService.getTemplates(jurisdictionId)
          .then(
              templates => dispatch(success(templates)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error("There was a problem retrieving template version information."));
              }
          );
  };

  function request() { templateLoading = true; return { type: templateConstants.TEMPLATES_REQUEST } }
  function success(templates) { templateLoading = false; return { type: templateConstants.TEMPLATES_SUCCESS, templates } }
  function failure(error) { templateLoading = false; return { type: templateConstants.TEMPLATES_FAILURE, error } }
  function ignore() { return { type: templateConstants.TEMPLATE_IGNORE_REQUEST } }
}

function getTemplate(templateId) {
  return dispatch => {
      dispatch(request());

      templateService.getTemplate(templateId)
          .then(
              template => dispatch(success(template)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error("There was a problem retrieving template version information."));
              }
          );
  };

  function request() { return { type: templateConstants.TEMPLATE_REQUEST } }
  function success(templates) { return { type: templateConstants.TEMPLATE_SUCCESS, template } }
  function failure(error) { return { type: templateConstants.TEMPLATE_FAILURE, error } }
}

function registerTemplate(template) {
  return dispatch => {
    dispatch({
      type: templateConstants.REGISTER_TEMPLATE,
      template
    });
  };
}

function addNewTemplate(template) {
  return dispatch => {
    dispatch(request(template));

    templateService.addNewTemplate(template)
      .then(
        template => {
          dispatch(success(template));
          dispatch(alertActions.success("A new template has been created successfully!"));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error("There was a problem adding a new template version."));
        }
      );
  };

  function request(template) { return { type: templateConstants.ADD_NEW_TEMPLATE_REQUEST, template } }
  function success(template) { return { type: templateConstants.ADD_NEW_TEMPLATE_SUCCESS, template } }
  function failure(error) { return { type: templateConstants.ADD_NEW_TEMPLATE_FAILURE, error } }
}

function copyTemplateToAnotherTemplateContainer(template) {
  return dispatch => {
    dispatch(request(template));

    templateService.addNewTemplate(template)
      .then(
        template => {
          dispatch(success(template));
          dispatch(alertActions.success("Template version has been successfully copied!"));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error("There was a problem adding a new template version."));
        }
      );
  };

  function request(template) { return { type: templateConstants.ADD_NEW_TEMPLATE_REQUEST, template } }
  function success(template) { return { type: templateConstants.ADD_NEW_TEMPLATE_SUCCESS, template } }
  function failure(error) { return { type: templateConstants.ADD_NEW_TEMPLATE_FAILURE, error } }
}

function copyTemplateFromExisting(templateId) {
  return dispatch => {
    dispatch(request(templateId));

    templateService.copyTemplateFromExisting(templateId)
      .then(
        template => {
          dispatch(success(template))
          dispatch(alertActions.success("Template version has been successfully copied!"));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error("There was a problem copying the template."));
        }
      );
  };

  function request(templateId) { return { type: templateConstants.COPYFROM_TEMPLATE_REQUEST, templateId } }
  function success(template) { return { type: templateConstants.COPYFROM_TEMPLATE_SUCCESS, template } }
  function failure(error) { return { type: templateConstants.COPYFROM_TEMPLATE_FAILURE, error } }
}

function updateTemplate(template) {
  return dispatch => {
    dispatch(request(template));

    templateService.updateTemplate(template)
      .then(
        template => dispatch(success(template)),
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error("There was a problem updating the template."));
        }
      );
  };

  function request(template) { return { type: templateConstants.UPDATE_TEMPLATE_REQUEST, template } }
  function success(template) { return { type: templateConstants.UPDATE_TEMPLATE_SUCCESS, template } }
  function failure(error) { return { type: templateConstants.UPDATE_TEMPLATE_FAILURE, error } }
}

function deleteTemplate(templateId) {
  return dispatch => {
    dispatch(request(templateId));

    templateService.deleteTemplate(templateId)
      .then(
        status => {
          dispatch(success(status));
          dispatch(alertActions.success("Template version has been successfully deleted!"));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error("There was a problem deleting the template version."));
        }
      );
  };

  function request(templateId) { return { type: templateConstants.DELETE_TEMPLATE_REQUEST, templateId } }
  function success(status) { return { type: templateConstants.DELETE_TEMPLATE_SUCCESS, templateId, status } }
  function failure(error) { return { type: templateConstants.DELETE_TEMPLATE_FAILURE, error } }
}

function getTemplateInstances(jurisdictionId) {
  return dispatch => {
      dispatch(request());

      templateService.getTemplateInstances(jurisdictionId)
          .then(
              templateInstances => dispatch(success(templateInstances)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error("There was a problem retrieving template instance information."));
              }
          );
  };

  function request() { return { type: templateConstants.TEMPLATE_INSTANCES_REQUEST } }
  function success(templateInstances) { return { type: templateConstants.TEMPLATE_INSTANCES_SUCCESS, templateInstances } }
  function failure(error) { return { type: templateConstants.TEMPLATE_INSTANCES_FAILURE, error } }
}

function getTemplateInstance(templateInstanceId) {
  return dispatch => {
      dispatch(request());

      templateService.getTemplateInstance(templateInstanceId)
          .then(
              templateInstance => dispatch(success(templateInstance)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error("There was a problem retrieving template instance information."));
              }
          );
  };

  function request() { return { type: templateConstants.TEMPLATE_INSTANCE_REQUEST } }
  function success(templateInstance) { return { type: templateConstants.TEMPLATE_INSTANCE_SUCCESS, templateInstance } }
  function failure(error) { return { type: templateConstants.TEMPLATE_INSTANCE_FAILURE, error } }
}

function registerTemplateInstance(templateInstance) {
  return dispatch => {
    dispatch({
      type: templateConstants.REGISTER_TEMPLATE_INSTANCE,
      templateInstance
    });
  };
}


function addNewTemplateInstance(templateInstance) {
  return dispatch => {
    dispatch(request(templateInstance));

    templateService.addNewTemplateInstance(templateInstance)
      .then(
        templateInstance => dispatch(success(templateInstance)),
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error("There was a problem creating a new template instance."));
        }
      );
  };

  function request(templateInstance) { return { type: templateConstants.ADD_NEW_TEMPLATE_INSTANCE_REQUEST, templateInstance } }
  function success(templateInstance) { return { type: templateConstants.ADD_NEW_TEMPLATE_INSTANCE_SUCCESS, templateInstance } }
  function failure(error) { return { type: templateConstants.ADD_NEW_TEMPLATE_INSTANCE_FAILURE, error } }
}

function updateTemplateInstance(templateInstance) {
  return dispatch => {
    dispatch(request(templateInstance));

    templateService.updateTemplateInstance(templateInstance)
      .then(
        templateInstance => dispatch(success(templateInstance)),
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error("There was a problem updating the template instance information."));
        }
      );
  };

  function request(templateInstance) { return { type: templateConstants.UPDATE_TEMPLATE_INSTANCE_REQUEST, templateInstance } }
  function success(templateInstance) { return { type: templateConstants.UPDATE_TEMPLATE_INSTANCE_SUCCESS, templateInstance } }
  function failure(error) { return { type: templateConstants.UPDATE_TEMPLATE_INSTANCE_FAILURE, error } }
}

function deleteTemplateInstance(templateInstanceId) {
  return dispatch => {
    dispatch(request(templateInstanceId));

    templateService.deleteTemplateInstance(templateInstanceId)
      .then(
        status => dispatch(success(status)),
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error("There was a problem deleting the template instance information."));
        }
      );
  };

  function request(templateInstanceId) { return { type: templateConstants.DELETE_TEMPLATE_INSTANCE_REQUEST, templateInstanceId } }
  function success(status) { return { type: templateConstants.DELETE_TEMPLATE_INSTANCE_SUCCESS, templateInstanceId, status } }
  function failure(error) { return { type: templateConstants.DELETE_TEMPLATE_INSTANCE_FAILURE, error } }
}

function publishTemplateVersion(templateContainerId, version) {
  return dispatch => {
    dispatch(request(templateContainerId, version));

    templateService.publishTemplateVersion(templateContainerId, version)
      .then(
        template => {
          dispatch(success(template, templateContainerId)),
          dispatch(alertActions.success(`Template version ${version} has been successfully published!`))
        },
        error => {
          dispatch(failure(error, templateContainerId));
          dispatch(alertActions.error("There was a problem publishing the template version."));
        }
      );
  };

  function request(templateContainerId, version) { return { type: templateConstants.PUBLISH_TEMPLATE_VERSION_REQUEST, templateContainerId, version } }
  function success(template, templateContainerId) { return { type: templateConstants.PUBLISH_TEMPLATE_VERSION_SUCCESS, template, templateContainerId } }
  function failure(error, templateContainerId) { return { type: templateConstants.PUBLISH_TEMPLATE_VERSION_FAILURE, error, templateContainerId } }
}

function unpublishTemplate(templateContainerId) {
  return dispatch => {
    dispatch(request(templateContainerId));

    templateService.unpublishTemplate(templateContainerId)
      .then(
        templateContainerId => {
          dispatch(success(templateContainerId)),
          dispatch(alertActions.success(`Template has been successfully unpublished!`))
        },
        error => {
          dispatch(failure(error, templateContainerId));
          dispatch(alertActions.error("There was a problem unpublishing the template."));
        }
      );
  };

  function request(templateContainerId) { return { type: templateConstants.UNPUBLISH_TEMPLATE_REQUEST, templateContainerId } }
  function success(templateContainerId) { return { type: templateConstants.UNPUBLISH_TEMPLATE_SUCCESS, templateContainerId } }
  function failure(error, templateContainerId) { return { type: templateConstants.UNPUBLISH_TEMPLATE_FAILURE, error, templateContainerId } }
}

function publishTemplateInstance(templateContainerId, templateInstanceId) {
  return dispatch => {
    dispatch(request(templateContainerId));

    templateService.publishTemplateInstance(templateInstanceId)
      .then(
        templateInstance => {
          dispatch(success(templateInstance, templateContainerId)),
          dispatch(alertActions.success(`Template instance has been successfully published!`))
        },
        error => {
          dispatch(failure(error, templateContainerId));
          dispatch(alertActions.error("There was a problem publishing the template instance."));
        }
      );
  };

  function request(templateContainerId) { return { type: templateConstants.PUBLISH_TEMPLATE_INSTANCE_REQUEST, templateContainerId } }
  function success(templateInstance, templateContainerId) { return { type: templateConstants.PUBLISH_TEMPLATE_INSTANCE_SUCCESS, templateInstance, templateContainerId } }
  function failure(error, templateContainerId) { return { type: templateConstants.PUBLISH_TEMPLATE_INSTANCE_FAILURE, error, templateContainerId } }
}

function unpublishTemplateInstance(templateContainerId, templateInstanceId) {
  return dispatch => {
    dispatch(request(templateContainerId));

    templateService.unpublishTemplateInstance(templateInstanceId)
      .then(
        templateInstance => {
          dispatch(success(templateInstance, templateContainerId)),
          dispatch(alertActions.success(`Template instance has been successfully unpublished!`))
        },
        error => {
          dispatch(failure(error, templateContainerId));
          dispatch(alertActions.error("There was a problem unpublishing the template instance."));
        }
      );
  };

  function request(templateContainerId) { return { type: templateConstants.UNPUBLISH_TEMPLATE_INSTANCE_REQUEST, templateContainerId } }
  function success(templateInstance, templateContainerId) { return { type: templateConstants.UNPUBLISH_TEMPLATE_INSTANCE_SUCCESS, templateInstance, templateContainerId } }
  function failure(error, templateContainerId) { return { type: templateConstants.UNPUBLISH_TEMPLATE_INSTANCE_FAILURE, error, templateContainerId } }
}

function determineTemplateInstanceConflicts(templateInstanceId) {
  return dispatch => {
      dispatch(request());

      templateService.determineTemplateInstanceConflicts(templateInstanceId)
          .then(
              conflicts => dispatch(success(conflicts)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error("There was a problem determining template instance conflict information."));
              }
          );
  };

  function request() { return { type: templateConstants.CONFLICT_DETECTION_TEMPLATE_INSTANCE_REQUEST, templateInstanceId } }
  function success(conflicts) { return { type: templateConstants.CONFLICT_DETECTION_TEMPLATE_INSTANCE_SUCCESS, templateInstanceId, conflicts } }
  function failure(error) { return { type: templateConstants.CONFLICT_DETECTION_TEMPLATE_INSTANCE_FAILURE, error, templateInstanceId } }
}

function determineTemplateConflicts(templateId) {
  return dispatch => {
      dispatch(request());

      templateService.determineTemplateConflicts(templateId)
          .then(
              conflicts => dispatch(success(conflicts)),
              error => {
                dispatch(failure(error));
                dispatch(alertActions.error("There was a problem determining template conflict information."));
              }
          );
  };

  function request() { return { type: templateConstants.CONFLICT_DETECTION_TEMPLATE_REQUEST, templateId } }
  function success(conflicts) { return { type: templateConstants.CONFLICT_DETECTION_TEMPLATE_SUCCESS, templateId, conflicts } }
  function failure(error) { return { type: templateConstants.CONFLICT_DETECTION_TEMPLATE_FAILURE, error, templateId } }
}

function resetTemplateConflicts(templateInstanceIds) {
  return dispatch => {
    dispatch(reset(templateInstanceIds));
  }

  function reset(templateInstanceIds) { return { type: templateConstants.CONFLICT_DETECTION_RESET, templateInstanceIds } }
}