import React from "react";
import PropTypes from "prop-types";

import { Router, Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import { history } from 'helpers';

import indexRoutes from "routes/index.jsx";
import { alertActions } from "actions";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute.jsx";

import "assets/scss/material-dashboard-pro-react.css?v=1.2.0";

/*
 * base application component
 */
class App extends React.Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;

    history.listen(() => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          {indexRoutes.map((prop, key) => {
            if (prop.admin) {
              return <PrivateRoute path={prop.path} component={prop.component} key={key} />;
            }
            return <Route path={prop.path} component={prop.component} key={key} />;
          })}
        </Switch>
      </Router>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func
};

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };