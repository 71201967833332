import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { uniq, values } from "lodash";

// custom components
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from "components/CustomButtons/Button.jsx";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
});


class TemplateSelector extends React.Component {
  constructor(props) {
    let { templateGroups, templateContainers } = props;

    super(props);

    let templateContainerIds = Object.keys(templateContainers);
    let templateGroupIdsWithContainers = uniq(templateContainerIds.map(templateContainerId => {
      return templateContainers[templateContainerId].templategroup.id;
    }));

    let selectableTemplateGroups = templateGroupIdsWithContainers.map(templateGroupId => {
      let templateGroup = templateGroups[templateGroupId];
      return { label: templateGroup.name, value: templateGroupId };
    });

    this.state = {
      initialValuesLoaded: false,
      templateGroupSelected: null,
      templateContainerSelected: null,
      selectableTemplateGroups: selectableTemplateGroups,
      selectableTemplateContainers: []      
    }

    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleTemplateGroupSelected = this.handleTemplateGroupSelected.bind(this);
    this.handleTemplateContainerSelected = this.handleTemplateContainerSelected.bind(this);
  }

  componentDidMount() {
    const { initialValuesLoaded, selectableTemplateGroups } = this.state;
    const { formInitialValues, templateGroups, templateContainers } = this.props;

    if (initialValuesLoaded) return;

    let templateGroupIds = Object.keys(templateGroups);
    let templateContainerIds = Object.keys(templateContainers);

    if ((templateGroupIds.length === 0) || (templateContainerIds.length === 0)) return;

    let defaultTemplateContainerId = templateContainerIds[0];
    let defaultTemplateGroupId = templateContainers[defaultTemplateContainerId].templategroup.id;
    
    let newState = {};
    if (Object.keys(formInitialValues).length > 0) {
      newState["templateGroupSelected"] = formInitialValues.templateGroupIdSelected;
      newState["templateContainerSelected"] = formInitialValues.templateContainerIdSelected;
    } else {
      newState["templateGroupSelected"] = defaultTemplateGroupId;
      newState["templateContainerSelected"] = defaultTemplateContainerId
    }

    if (selectableTemplateGroups.length === 0) {
      let templateGroupIdsWithContainers = uniq(templateContainerIds.map(templateContainerId => {
        return templateContainers[templateContainerId].templategroup.id;
      }));
  
      newState["selectableTemplateGroups"] = templateGroupIdsWithContainers.map(templateGroupId => {
        let templateGroup = templateGroups[templateGroupId];
        return { label: templateGroup.name, value: templateGroupId };
      });
    }

    let templateContainersAsArray = values(templateContainers);
    newState["selectableTemplateContainers"] = templateContainersAsArray.filter(e => e.templategroup.id === newState["templateGroupSelected"]).map(templateContainer => {
      return { label: templateContainer.name, value: templateContainer.id };
    });

    this.setState({
      ...newState,
      initialValuesLoaded: true
    });
  }

  handleCancel() {
    const { onCancel } = this.props;

    onCancel();
  }

  handleConfirm() {
    const { onConfirm, templateId } = this.props;
    const { templateContainerSelected } = this.state;

    onConfirm({sourceTemplateId: templateId, targetTemplateContainerId: templateContainerSelected });
  }

  handleTemplateGroupSelected(e) {
    const { templateGroupSelected } = this.state;
    const { templateContainers } = this.props;
      
    const newTemplateGroup = e.target.value;

    if (templateGroupSelected !== newTemplateGroup) {
      let newState = {};

      newState["templateGroupSelected"] = newTemplateGroup;

      let templateContainersAsArray = values(templateContainers);
      newState["selectableTemplateContainers"] = templateContainersAsArray.filter(e => e.templategroup.id === newTemplateGroup).map(templateContainer => {
        return { label: templateContainer.name, value: templateContainer.id };
      });
 
      newState["templateContainerSelected"] = newState["selectableTemplateContainers"][0].value;

      this.setState(newState);
    }
  }

  handleTemplateContainerSelected(e) {
    const { templateContainerSelected } = this.state;

    const newTemplateContainer = e.target.value;

    if (templateContainerSelected !== newTemplateContainer) {
      this.setState({
        templateContainerSelected: newTemplateContainer
      });
    }
  }
 
  render() {
    const { selectableTemplateGroups, selectableTemplateContainers, templateGroupSelected, templateContainerSelected } = this.state;
    const { classes, title } = this.props;

    return (
      <Modal open={true} onClose={this.handleCancel}>
      <GridContainer style={getModalStyle()} className={classes.paper}>
        <GridItem xs={12}>
          <h3>{title}</h3>
        </GridItem>
        <form onSubmit={this.handleConfirm}>
          <GridItem xs={12}>
          <FormControl style={{minWidth: '300px', marginTop: '20px' }}>
          <InputLabel htmlFor="templateGroup">TEMPLATE GROUP</InputLabel>
          <Select
            id="templateGroup"
            value={templateGroupSelected}
            onChange={this.handleTemplateGroupSelected}
          >
            {selectableTemplateGroups.map( (item, key) => {
              return (<MenuItem value={item.value} key={key}>{item.label}</MenuItem>);
            })}
          </Select>
          </FormControl>
          </GridItem>
          <GridItem xs={12}>
          <FormControl style={{minWidth: '300px', marginTop: '20px' }}>
          <InputLabel htmlFor="templateContainer">TEMPLATE</InputLabel>
          <Select
            id="templateContainer"
            value={templateContainerSelected}
            onChange={this.handleTemplateContainerSelected}
          >
            {selectableTemplateContainers.map( (item, key) => {
              return (<MenuItem value={item.value} key={key}>{item.label}</MenuItem>);
            })}
          </Select>
          </FormControl>
          </GridItem>
          <GridItem xs={12} style={{textAlign: 'center', marginTop: '30px'}}>
            <Button color="danger" onClick={(e) => this.handleCancel(e)} >Cancel</Button>
            <Button color="success" style={{marginLeft: '10px'}} onClick={(e) => this.handleConfirm(e)} >Confirm</Button>
          </GridItem>
      </form>
      </GridContainer>
      </Modal>
    );
  }
}


TemplateSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  templateGroups: PropTypes.object.isRequired,
  templateContainers: PropTypes.object.isRequired,
  formInitialValues: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired 
};

export default withStyles(styles)(TemplateSelector);