/* eslint-disable */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import { userActions } from 'actions';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;

    if (localStorage.getItem("user")) dispatch(userActions.logout());

    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      username: '',
      password: '',
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  handleChange(e, name) {
    const { value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password } = this.state;
    const { dispatch } = this.props;

    if (username && password) {
      dispatch(userActions.login(username, password));
    }
  }

  handleReset(e) {
    e.preventDefault();

    const { history } = this.props;

    history.push("/resetPassword");
  }

  render() {

    const { classes, loggingIn, alert } = this.props;
    const { username, password, submitted } = this.state;

    let alertNotice = null;
    if (alert.type) {
      alertNotice = <SnackbarContent message={alert.message} color={alert.type} />
    }

    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <form name="form" onSubmit={this.handleSubmit}>
                <Card login className={classes[this.state.cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="primary"
                  >
                    <h4 className={classes.cardTitle}>Log in</h4>

                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText="Email"
                      id="username"
                      formControlProps={{
                        fullWidth: true
                      }}
                      value = {username}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ), 
                        onChange: (e) => this.handleChange(e, "username")
                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      value = {password}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockOutline
                              className={classes.inputAdornmentIcon}
                            />
                          </InputAdornment>
                        ),
                        onChange: (e) => this.handleChange(e, "password"),
                        type: "password"
                      }}
                    />
                    {alertNotice}
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    <Button color="primary" simple size="lg" disabled={loggingIn} block onClick={this.handleSubmit}>
                      Login
                    </Button>
                    <Button color="danger" simple size="lg" block onClick={this.handleReset}>
                      Reset Password
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {

  const { alert } = state;
  const { loggingIn } = state.authentication;
  return {
    loggingIn,
    alert
  };
}

export default connect(mapStateToProps)(withStyles(loginPageStyle)(LoginPage));
