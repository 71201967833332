import { v8n } from "./v8n";
import { userService } from "services";

const emailRexEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// eslint-disable-next-line
const telephoneRexEx = /^\+?\d{2}|\0(?:\-?|\ ?)(?:\([2-9]\d{2}\)\ ?|[2-9]\d{2}(?:\-?|\ ?))[2-9]\d{2}[- ]?\d{4}$/;

export const email = value => new v8n()
  .string()
  .pattern(emailRexEx)
  .test(value) ? undefined : 'Invalid email address';

export const equal = (value1, value2) => new v8n()
  .exact(value1)
  .test(value2);

export const stringOfLengthBetween = (value, minLength, maxLength) => new v8n()
  .string()
  .length(minLength, maxLength)
  .test(value);

export const telephone = value => (value ? (new v8n()
  .string()
  .pattern(telephoneRexEx)
  .test(value) ? undefined : 'Invalid telephone number') : undefined);

export const checkExistingEmail = value => userService.checkForExistingEmail(value);