/* eslint-disable */
import { messageConstants } from '../constants';
import { stat } from 'fs';
import { difference, uniq } from "lodash";
import { isObject } from 'lodash';

export function messages(state = {}, action) {

  switch (action.type) {

    case messageConstants.MESSAGES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case messageConstants.MESSAGES_SUCCESS:
      let messages = {...(state["messages"] || {})};
      Object.keys(action.messages).forEach(messageId => {
        messages[messageId] = action.messages[messageId];
      });
      
      return {
        ...state,
        messages,
        loading: false
      };
    case messageConstants.MESSAGES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    /////////////////////////////

    case messageConstants.MESSAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case messageConstants.MESSAGE_SUCCESS:
      messages = {...(state["messages"] || {})};
      messages[action.message[0].id] = action.message[0];
      
      return {
        ...state,
        messages,
        loading: false
      };

    case messageConstants.MESSAGE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    /////////////////////////////

    case messageConstants.ADD_NEW_MESSAGE_REQUEST:
      return {
        ...state,
        saving: true
      };
    case messageConstants.ADD_NEW_MESSAGE_SUCCESS:
      messages = {...(state["messages"] || {})};
      message[action.message.id] = action.message;
      
      return {
        ...state,
        messages,
        saving: false
      };
    case messageConstants.ADD_NEW_MESSAGE_FAILURE:
      return {
        ...state,
        error: action.error,
        saving: false
      };

    /////////////////////////////

    case messageConstants.UPDATE_MESSAGE_REQUEST:
      return {
        ...state,
        saving: true
      };
    case messageConstants.UPDATE_MESSAGE_SUCCESS:
      messages = {...(state["messages"] || {})};
      messages[action.message.id] = action.message;
      
      return {
        ...state,
        messages,
        saving: false
      };
    case messageConstants.UPDATE_MESSAGE_FAILURE:
      return {
        ...state,
        error: action.error,
        saving: false
      };

    /////////////////////////////

    case messageConstants.DELETE_MESSAGE_REQUEST:
      return {
        ...state,
        deleting: true
      };
    case messageConstants.DELETE_MESSAGE_SUCCESS:
      messages = {...(state["messages"] || {})};
      delete messages[action.messageId];
      
      return {
        ...state,
        messages,
        deleting: false
      };
    case messageConstants.DELETE_MESSAGE_FAILURE:
      return {
        ...state,
        error: action.error,
        deleting: false
      };

    /////////////////////////////

    case messageConstants.REGISTER_MESSAGE:
      messages = {...(state["messages"] || {})};
      messages[action.message.id] = action.message;
      
      return {
        ...state,
        messages,
        saving: false
      };

    /////////////////////////////  
    
    default:
      return state;
  }
}