/* eslint-disable */

import React from "react";
import { Field, reduxForm, SubmissionError, reset } from 'redux-form';
import { connect } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material ui icons
import Style from "@material-ui/icons/Style";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import Create from "@material-ui/icons/Create";
import Cancel from "@material-ui/icons/Cancel";
import Clear from "@material-ui/icons/Clear";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import CustomTextField from "components/redux-form/CustomTextField/CustomTextField.jsx";

// validators
import { email, equal, stringOfLengthBetween, telephone, checkExistingEmail } from "helpers/validation";

// style for this view
import validationFormsStyle from "assets/jss/spot-admin/views/newTemplateGroupStyle.jsx";

import { userService } from "services";
import { userActions } from "actions";

const validate = (values) => {
  const errors = {};

  if (values.newuserpassword && values.newuserconfirmpassword) {
    if (!equal(values.newuserpassword, values.newuserconfirmpassword)) {
      errors.newuserconfirmpassword = "Passwords must match"
    }
  }

  if (!values.newuserpassword) {
    errors.newuserpassword = "Password is a required field";
  } else {
    if (!stringOfLengthBetween(values.newuserpassword, 8, 20)) {
      errors.newuserpassword = "Password must be between 8 and 20 characters";
    }
  }

  if (!values.firstname) {
    errors.firstname = "First name is a required field";
  }

  if (!values.lastname) {
    errors.lastname = "Last name is a required field";
  }

  if (!values.phonenumber) {
    errors.phonenumber = "Phone number is a required field";
  }

  return errors;
}

const asyncValidate = (values) => {
  return new Promise((resolve, reject) => {
    checkExistingEmail(values.newuseremail)
    .then(result => {
      if (!result.available) {
        reject({ newuseremail: 'This email address has already been registered.' });
      } else {
        resolve();
      }
     

    })
    .catch(error => {
      reject({ newuseremail: 'There was a problem determining whether this email already exists or not. Please try again.'});
    });
  });
}

class NewUserPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      alert: null,
      snackBarOpen: false,
      snackBarStatus: "success",
      snackBarMessage: ""
    };

    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.continue = this.continue.bind(this);
    this.finished = this.finished.bind(this);
    this.removeAlert = this.removeAlert.bind(this);
    this.confirmContinue = this.confirmContinue.bind(this);
    this.showNotification = this.showNotification.bind(this);
  }


  componentWillMount() {
    const { dispatch, assignableRoles, jurisdictionScopes } = this.props;  
    let user = JSON.parse((localStorage.getItem('user') || {}));
 
    if (assignableRoles === undefined) {
      dispatch(userActions.getAssignableRoles());
    }

    if (jurisdictionScopes === undefined) {
      dispatch(userActions.checkJurisdictionScope(user.id));
    }
  }

  componentWillUnmount() {
    if (this.notificationTimer) {
      clearTimeout(this.notificationTimer);
    }
  }

  showNotification(status, message) {
    if (!this.state.snackBarOpen) {
      this.setState({ 
        snackBarOpen: true,
        snackBarStatus: status,
        snackBarMessage: message 
      });
      this.notificationTimer = setTimeout(
        function() {
          this.setState({
            snackBarOpen: false
          });
          this.notificationTimer = null;
        }.bind(this),
        3000
      );
    }
  }

  confirmContinue() {
    const { classes } = this.props;
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block" }}
          title="Success!"
          onConfirm={() => this.continue()}
          onCancel={() => this.finished()}
          confirmBtnCssClass={
            classes.button + " " + classes.success
          }
          cancelBtnCssClass={
            classes.button + " " + classes.danger
          }
          confirmBtnText="Add another"
          cancelBtnText="Finished"
          showCancel
        >
        Would you like to add another user?
        </SweetAlert>
      )
    });
  }

  continue() {
    const { dispatch } = this.props;

    dispatch(reset('newUser'));
    this.setState({
      alert: null
    });
  }

  finished() {
    const { history  } = this.props;

    this.setState({
      alert: null
    });
    history.goBack();
  }

  removeAlert() {
    this.setState({
      alert: null
    });
  }

  submit(values) {
    const { dispatch } = this.props;
    
    let user = {
      firstName: values.firstname,
      lastName: values.lastname,
      phone: values.phonenumber,
      email: values.newuseremail,
      password: values.newuserpassword
    };
    
    return new Promise((resolve, reject) => {
      userService.addNewUser(user)
      .then(response => {
        dispatch(userActions.registerUser(response.user));
        resolve(response.user);
        this.showNotification("success", "User has been successfully created!");
        this.confirmContinue();
      })
      .catch(error => {
        console.log("ERROR creating user ", error);
        this.showNotification("danger", "There was a problem creating the user!");
        reject(new SubmissionError({ _error: "There was a problem creating the user."} ) );
      });
    });

  }

  cancel(pristine) {
    const { classes, history } = this.props;

    if (!pristine) {
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block" }}
            title="Warning!"
            onConfirm={() => this.finished()}
            onCancel={() => this.removeAlert()}
            confirmBtnCssClass={
              classes.button + " " + classes.success
            }
            cancelBtnCssClass={
              classes.button + " " + classes.danger
            }
            confirmBtnText="Yes"
            cancelBtnText="No"
            showCancel
          >
          Cancelling will discard any changes made.  Are you sure?
          </SweetAlert>
        )
      });  
    } else {
      history.goBack();
    }
  }


  render() {
    const { alert, snackBarOpen, snackBarMessage, snackBarStatus } = this.state;

    return (
      <div>
        {alert}
        <Snackbar
          place="tr"
          color={snackBarStatus}
          icon={Style}
          message={snackBarMessage}
          open={snackBarOpen}
          closeNotification={() => this.setState({ snackBarOpen: false })}
          close
        />
        <NewUserForm onSubmit={this.submit} onCancel={this.cancel} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { alert, users } = state;

  return {
    assignableRoles: users.assignableRoles,
    requestingAssignableRoles: users.requestingAssignableRoles,
    jurisdictionScopes: users.jurisdictionScopes,
    alert
  };
} 

export default connect(mapStateToProps)(withStyles(validationFormsStyle)(NewUserPage));


class NewUserForm extends React.Component {

  constructor(props) {
    super(props);

    this.resetFields = this.resetFields.bind(this);
  }

  resetFields() {
    const { reset } = this.props;
  
    reset();
  }

  render() {
    const { classes, handleSubmit, pristine, submitting,
      submitFailed, submitSucceeded, onCancel, reset } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentInd />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>New User Creation Form</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit} autoComplete="off">
              <GridContainer>
                <GridItem xs={9}>
                  <Field 
                    name="newuseremail" 
                    component={CustomTextField} 
                    label="Email Address *"
                    props={{ fullWidth: true, inputProps: { type: "email" } }} 
                    validate={email}
                    autoComplete="email"
                  />
                </GridItem>
                <GridItem xs={6}>
                  <Field 
                    name="newuserpassword" 
                    component={CustomTextField} 
                    label="Password *"
                    props={{ fullWidth: true, inputProps: { type: "password" } }} 
                    autoComplete="new-password"
                  />
                </GridItem>
                <GridItem xs={6}>
                  <Field 
                    name="newuserconfirmpassword" 
                    component={CustomTextField} 
                    label="Confirm Password *"
                    props={{ fullWidth: true, inputProps: { type: "password" } }} 
                    autoComplete="new-password"
                  />
                </GridItem>
                <GridItem xs={6}>
                  <Field 
                    name="firstname" 
                    component={CustomTextField} 
                    label="First Name *"
                    props={{ fullWidth: true }} 
                    autoComplete="given-name"
                  />
                </GridItem>
                <GridItem xs={6}>
                  <Field 
                    name="lastname" 
                    component={CustomTextField}
                    label="Last Name *"
                    props={{ fullWidth: true }} 
                    autoComplete="family-name"
                  />
                  </GridItem>
                  <GridItem xs={6}>
                  <Field 
                    name="phonenumber" 
                    component={CustomTextField}
                    label="Phone Number *" 
                    props={{ fullWidth: true }} 
                    validate={telephone}
                    autoComplete="tel-national"
                  />
                  </GridItem>
                  <GridItem xs={12}>
                <div className={classes.formCategory}>
                  <small>*</small> Required fields
              </div>
              </GridItem>
              </GridContainer>
                <Button
                  color="success"
                  type="submit"
                  className={classes.registerButton}
                  disabled={submitting}
                >
                <Create className={classes.icon} />
                Create
              </Button>
              <Button
                  color="danger"
                  className={classes.registerButton}
                  style={{ marginRight: '10px'}}
                  disabled={submitting}
                  onClick={() => onCancel(pristine)}
                ><Cancel className={classes.icon} />
                  Cancel
              </Button>
              <Button
                  color="primary"
                  className={classes.registerButton}
                  style={{ marginRight: '25px'}}
                  disabled={pristine || submitting}
                  onClick={this.resetFields}
                ><Clear className={classes.icon}/>
                  Clear
              </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

NewUserForm = reduxForm({
  form: 'newUser',
  validate,
  asyncValidate,
  asyncBlurFields: ['newuseremail']
})(withStyles(validationFormsStyle)(NewUserForm));