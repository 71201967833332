export const userConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  CHECK_EMAIL_REQUEST: 'CHECK_EMAIL_REQUEST',
  CHECK_EMAIL_SUCCESS: 'CHECK_EMAIL_SUCCESS',
  CHECK_EMAIL_FAILURE: 'CHECK_EMAIL_FAILURE',

  ADD_NEW_USER_REQUEST: 'ADD_NEW_USER_REQUEST',
  ADD_NEW_USER_SUCCESS: 'ADD_NEW_USER_SUCCESS',
  ADD_NEW_USER_FAILURE: 'ADD_NEW_USER_FAILURE',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

  REGISTER_USER: 'REGISTER_USER',

  PASSWORD_RESET_REQUEST: 'PASSWORD_RESET_REQUEST',
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_FAILURE: 'PASSWORD_RESET_FAILURE',

  SWITCH_ENABLED_STATUS_REQUEST: 'SWITCH_ENABLED_STATUS_REQUEST',
  SWITCH_ENABLED_STATUS_SUCCESS: 'SWITCH_ENABLED_STATUS_SUCCESS',
  SWITCH_ENABLED_STATUS_FAILURE: 'SWITCH_ENABLED_STATUS_FAILURE',

  GET_USER_ROLE_REQUEST: 'GET_USER_ROLE_REQUEST',
  GET_USER_ROLE_SUCCESS: 'GET_USER_ROLE_SUCCESS',
  GET_USER_ROLE_FAILURE: 'GET_USER_ROLE_FAILURE',

  GET_USER_ROLES_REQUEST: 'GET_USER_ROLES_REQUEST',
  GET_USER_ROLES_SUCCESS: 'GET_USER_ROLES_SUCCESS',
  GET_USER_ROLES_FAILURE: 'GET_USER_ROLES_FAILURE',

  ADD_NEW_USER_ROLE_REQUEST: 'ADD_NEW_USER_ROLE_REQUEST',
  ADD_NEW_USER_ROLE_SUCCESS: 'ADD_NEW_USER_ROLE_SUCCESS',
  ADD_NEW_USER_ROLE_FAILURE: 'ADD_NEW_USER_ROLE_FAILURE',

  UPDATE_USER_ROLE_REQUEST: 'UPDATE_USER_ROLE_REQUEST',
  UPDATE_USER_ROLE_SUCCESS: 'UPDATE_USER_ROLE_SUCCESS',
  UPDATE_USER_ROLE_FAILURE: 'UPDATE_USER_ROLE_FAILURE',

  DELETE_USER_ROLE_REQUEST: 'DELETE_USER_ROLE_REQUEST',
  DELETE_USER_ROLE_SUCCESS: 'DELETE_USER_ROLE_SUCCESS',
  DELETE_USER_ROLE_FAILURE: 'DELETE_USER_ROLE_FAILURE',

  GET_ASSIGNABLE_ROLES_REQUEST: 'GET_ASSIGNABLE_ROLES_REQUEST',
  GET_ASSIGNABLE_ROLES_SUCCESS: 'GET_ASSIGNABLE_ROLES_SUCCESS',
  GET_ASSIGNABLE_ROLES_FAILURE: 'GET_ASSIGNABLE_ROLES_FAILURE',

  CHECK_JURISDICTION_SCOPE_REQUEST: 'CHECK_JURISDICTION_SCOPE_REQUEST',
  CHECK_JURISDICTION_SCOPE_SUCCESS: 'CHECK_JURISDICTION_SCOPE_SUCCESS',
  CHECK_JURISDICTION_SCOPE_FAILURE: 'CHECK_JURISDICTION_SCOPE_FAILURE',

  CHECK_JURISDICTION_DEFAULTS_REQUEST: 'CHECK_JURISDICTION_DEFAULTS_REQUEST',
  CHECK_JURISDICTION_DEFAULTS_SUCCESS: 'CHECK_JURISDICTION_DEFAULTS_SUCCESS',
  CHECK_JURISDICTION_DEFAULTS_FAILURE: 'CHECK_JURISDICTION_DEFAULTS_FAILURE'
};